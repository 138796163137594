import {Form, Input, Layout, Spin} from 'antd';

import { useNavigate, useParams } from 'react-router-dom';
import CommonForm from '../../components/CommonForm/CommonForm';
import { ErrorContext } from '../../providers/ErrorProvider';
import ProjectSegmentsService from '../../services/Project/ProjectSegmentsService';
import React from 'react';
import { ROUTES } from '../../constants/routes-path';

const NewSegment = () => {
	const [loading, setLoading] = React.useState(false);
	const { setError } = React.useContext(ErrorContext);
	const { TextArea } = Input;
	const { Content } = Layout;
	const navigate = useNavigate();
	const { projectId } = useParams();
	const segmentsService = new ProjectSegmentsService(projectId);

	const backToSegment = () => navigate(ROUTES.PROJECT_SEGMENTS(projectId));

	const onSubmit = async (values) => {
		setLoading(true);

		try {
			await segmentsService.create(values);
			setLoading(false);
			backToSegment();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CommonForm
					title="Create new segment"
					primaryButton="Create segment"
					onSubmit={onSubmit}
					onCancel={backToSegment}
					secondClass="new-project"
					titleClass="new-segment"
					disabled={loading}
				>
					<Form.Item
						className = 'field-name'
						name="name"
						label="Segment name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input disabled={loading} />
					</Form.Item>
					<Form.Item
						style={{marginBottom:0}}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 100 }]}
					>
						<TextArea className="common-textarea new-segment" rows={3} disabled={loading} />
					</Form.Item>
					{loading && <Spin style={{display: 'flex', justifyContent: 'center'}}/>}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default NewSegment;
