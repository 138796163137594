import './TranscriptDetails.less';
import { Divider, Form, Input, Layout, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import { PaperClipOutlined } from '@ant-design/icons';
import ProjectSegmentsService from '../../services/Project/ProjectSegmentsService';
import ProjectsService from '../../services/ProjectsService';
import ProjectTranscriptsService from '../../services/Project/ProjectTranscriptsService';
import ProjectTypesService from '../../services/Project/ProjectTypesService';
import { ROUTES } from '../../constants/routes-path';

const TranscriptDetails = () => {
	const [loading, setLoading] = useState(false);
	const [projectName, setProjectName] = useState('');
	const [projectTypes, setProjectTypes] = useState([]);
	const [segments, setSegments] = useState([]);
	const [selectedTypeOptions, setSelectedTypeOptions] = useState({});
	const [transcriptName, setTranscriptName] = useState('');
	const { setError } = useContext(ErrorContext);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { projectId, id } = useParams();
	const projectsService = new ProjectsService();
	const segmentsService = new ProjectSegmentsService(projectId);
	const transcriptsService = new ProjectTranscriptsService(
		projectId,
		setError
	);
	const typesService = new ProjectTypesService(projectId, setError);
	const { Content } = Layout;
	const { Option } = Select;
	const routes = [
		{ path: '/projects', breadcrumbName: 'Projects' },
		{ path: `/${projectId}`, breadcrumbName: projectName },
		{ path: '/transcripts', breadcrumbName: 'Transcripts' },
		{ path: '', breadcrumbName: transcriptName },
	];

	const backToProjectTranscript = () =>
		navigate(ROUTES.PROJECT_TRANSCRIPT_SELECTED(projectId, id), {replace: true});

	const updateTranscript = async (values) => {
		setLoading(true);

		try {
			const types = Object.keys(selectedTypeOptions).map((typeId) => {
				return {
					id: typeId,
					option: {
						id: selectedTypeOptions[typeId],
					},
				};
			});

			await transcriptsService.update(
				id,
				values.transcriptName,
				{
					id: values.segment,
				},
				types
			);
			setLoading(false);
			backToProjectTranscript();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const handleSelectedTypeOption = (typeId, typeOptionId) => {
		setSelectedTypeOptions({
			...selectedTypeOptions,
			[typeId]: typeOptionId,
		});
	};

	const getProjectName = async (id) => {
		setLoading(true);

		try {
			const result = await projectsService.get(id);

			setProjectName(result.name);
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const getSegments = async () => {
		setLoading(true);

		try {
			const result = await segmentsService.getAllWithoutPagination();

			if (result !== undefined) {
				setSegments(result.data);
			}
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const getTranscript = async (id, projectTypes) => {
		setLoading(true);

		try {
			const result = await transcriptsService.get(+id);

			setTranscriptName(result.name);
			form.setFieldsValue({
				transcriptName: result.name,
				segment: result.segment?.id,
			});

			const selectedProjectTypes = {};

			projectTypes.map((projectType) =>
				result.types?.map((type) => {
					if (projectType.key === type.id) {
						form.setFieldsValue({
							[`${projectType.name}`]: type.option?.id,
						});

						selectedProjectTypes[type.id] = type.option?.id;
					}
				})
			);

			setSelectedTypeOptions(selectedProjectTypes);
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const getTypes = async () => {
		setLoading(true);

		try {
			const result = await typesService.getAllWithoutPagination();

			setProjectTypes(result.data);
			await getTranscript(+id, result.data);
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const getInitialValues = async () => {
		await getTypes();
		await getProjectName(projectId);
		await getSegments();
	};

	useEffect(() => {
		getInitialValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Transcript details"
					primaryButton="Save Transcript"
					onCancel={backToProjectTranscript}
					onSubmit={updateTranscript}
					form={form}
					disabled={loading}
				>
					<label
						className="gutter-row"
						style={{ fontWeight: 'bold' }}
					>
						Segments
					</label>
					<span style={{ fontWeight: 'bold', color: 'red' }}>
						{' '}
						*{' '}
					</span>
					<Form.Item
						className="edit-segment"
						name="segment"
						rules={[{ required: true }]}
					>
						<Select
							className="segment-select"
							allowClear
							style={{ width: '100%' }}
							placeholder="Loyalist"
						>
							{segments.map((segment, index) => (
								<Option key={index} value={segment.key}>
									{segment.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<label
						className="gutter-row"
						style={{ fontWeight: 'bold' }}
					>
						Transcript Name
					</label>
					<span style={{ fontWeight: 'bold', color: 'red' }}>
						{' '}
						*{' '}
					</span>
					<Form.Item
						className="transcript-name"
						name="transcriptName"
						rules={[{ required: true }, { min: 2 }, { max: 255 }]}
						style={{ marginBottom: '45px' }}
					>
						<Input />
					</Form.Item>
					<label
						className="gutter-row"
						style={{ fontWeight: 'bold', marginTop: '10px' }}
					>
						Types
					</label>
					<Divider
						className="divider-types"
						plain
						style={{ marginTop: '5px' }}
					/>
					<div className="upload-transcripts-types">
						{projectTypes.map((projectType, index) => (
							<React.Fragment key={index}>
								<div id="label-types">{projectType.name}</div>
								<Form.Item
									className="edit-age-types"
									name={projectType.name}
								>
									<Select
										className="types-select"
										allowClear
										value={null}
										style={{ width: '100%' }}
										placeholder="Select"
										onChange={(typeOptionId) =>
											handleSelectedTypeOption(
												projectType.key,
												typeOptionId
											)
										}
									>
										{projectType.options.map(
											(item, index) => (
												<Option
													key={index}
													value={item.id}
												>
													{item.name}
												</Option>
											)
										)}
									</Select>
								</Form.Item>
							</React.Fragment>
						))}
					</div>
					<Divider className="divider-file" plain />
					{transcriptName ? (
						<>
							{' '}
							<PaperClipOutlined /> {transcriptName}{' '}
						</>
					) : (
						''
					)}
					{loading && (
						<Spin
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						/>
					)}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default TranscriptDetails;
