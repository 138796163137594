import './RecentProjects.less';
import { List, Progress, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';

const RecentProjects = ({ projects, projectCount }) => {
	const { Text } = Typography;

	const dateFormat = 'MM/DD/YYYY';

	return (
		<div className="recent-projects">
			<div className="recent-header">
				<Text style={{ color: '#424B5A' }}>RECENT PROJECTS</Text>
				<Link to={'/projects'}> {`My projects (${projectCount})`} </Link>
			</div>
			<div className="recent-list">
				<div className="list-container">
					<List
						dataSource={projects}
						renderItem={project => (
							<List.Item>
								<Space direction="vertical" size={[1, 1]}>
									<Link to={`/projects/${project.id}`} className="project-name">{project.name}</Link>
									<Text className="project-client">{project.client.name}</Text>
									<Text style={{ lineHeight: '18px' }}>
										<span style={{ fontWeight: 'bold' }}>{moment(project.updatedAt).format(dateFormat)}</span>
										{
											project.updatedBy && (
												<>
													{' by '}<span style={{ fontWeight: 'bold' }}>{project.updatedBy}</span>
												</>
											)
										}
									</Text>
								</Space>
								<Progress
									width={77}
									type="circle"
									percent={project.completed * 100}
									format={percent => `${Math.round(percent)}/100`}
								/>
							</List.Item>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default RecentProjects;
