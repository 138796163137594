import React from 'react';
import styles from './Tag.module.less';

interface TagProps{
	color: string
	backgroundColor: string
	borderColor: string,
	style?: React.CSSProperties
}

const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({children, color, backgroundColor, borderColor, style}) => {
	return <span className={styles.tagContainer} style={{color, backgroundColor, borderColor, ...style }}>{children}</span>;
};

export default Tag;
