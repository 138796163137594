import { Filters, Sorter } from '../interfaces/Api';
import axios from 'axios';
import BaseService from './BaseService';
import Configuration from './ServiceConfig';
import { Partner } from '../interfaces/Partner';

interface Response {
	data: Partner[];
	totalCount: number;
}

class PartnersService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	constructor() {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}${'global-partners/'}`;
		this.token = localStorage.getItem('token');
	}

	private parseDataTable(results: any[]): Partner[] {
		return results.map(object => {
			return {
				key: object.id,
				name: object.name,
				status: object.status,
			};
		});
	}

	async getAll(filters: Filters, sorter: Sorter, limit: number = 10, offset: number = 0): Promise<Response | void> {
		let params = {};

		if (filters && filters.name) {
			params = { ...params, ...filters };
		}

		if (sorter && sorter.sort) {
			params = { ...params, ...sorter };
		}

		params = { ...params, limit, offset };

		return axios
			.get(this.path, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async get(id: number) {
		return axios
			.get(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async create(item: Partner) {
		return axios
			.post(this.path, item, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async delete(id: number) {
		return axios
			.delete(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async update(id: number, partner: Partner) {
		return axios
			.patch(`${this.path}${id}`, partner, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default PartnersService;
