import './ProjectMap.less';
import { Alert, Button, Col, Form, List, Modal, Row, Select, Slider, Spin, Tag, Tooltip, TreeSelect } from 'antd';
import { ArrowsAltOutlined, ExportOutlined, FieldTimeOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useContext} from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import ColorPalettePicker from '../../components/ColorPalettePicker/ColorPalettePicker';
import { ConstructType } from '../../interfaces/Construct';
import Map from '../../components/Map/Map';
import moment from 'moment';
import UseProjectMapController from './useProjectMapController';
import { UserContext } from '../../providers/UserProvider';

const dateFormat = 'MM/DD/YYYY';

const ProjectMap: React.FC = () => {

	const { user } = useContext(UserContext);
	const {
		addMerge,
		addNodePosition,
		constructsTypes,
		changeMapColors,
		filteredMap,
		filters,
		form,
		getMap,
		graph,
		handleCloseCollapseHistoryModal,
		handleCloseMapColorsModal,
		handleLoadMap,
		handleSave,
		isCollapseHistoryModalOpen,
		isMapColorsModalOpen,
		loading,
		loadingColor,
		mapColors,
		maxThreshold,
		mergedNodes,
		mergeHistory,
		onChangeComplete,
		onSaveNewMapColors,
		onSelectColor,
		onTresholdChange,
		openMergeHistory,
		savedNodesPositions,
		selectedConstructType,
		segments,
		setFilters,
		setGraph,
		setMergedNodes,
		threshold,
		types,
		unmerge,
	} = UseProjectMapController();

	return (
		<>
			<div className='project-map'>
				<div className='filters-container'>
					<Form layout='vertical' form={form}>
						<Row gutter={24} align='bottom'>
							<Col span={4}>
								<Form.Item label='Construct Types' name='selectedConstructTypes'>
									<Select
										mode='multiple'
										allowClear
										placeholder='All'
										onChange={values => setFilters({ ...filters, constructType: values as unknown as ConstructType[] })}
										loading={loading}
										disabled={loading}
										options={constructsTypes.map((construct) => ({ label: construct.value, value: construct.id }))}
									/>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item label='Segments' name='selectedSegments'>
									<Select
										mode='multiple'
										allowClear
										placeholder='All'
										onChange={values => setFilters({ ...filters, segmentId: values as string[] })}
										loading={loading}
										disabled={loading}
										options={segments.map((segment) => ({ label: segment.name, value: segment.key }))}
									/>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item label='Types' name='Types'>
									<TreeSelect
										onChange={values => setFilters({ ...filters, typeOptionId: values as number[] })}
										treeCheckable
										showCheckedStrategy={TreeSelect.SHOW_CHILD}
										placeholder='All'
										loading={loading}
										treeData={types.map(type => ({
											title: type.name,
											name: 'parent' + type.key,
											key: 'parent' + type.key,
											value: 'parent' + type.key,
											children: type.options.map(option => ({
												title: option.name,
												value: option.id,
												key: option.id,
											})),
										}))}
									/>
								</Form.Item>
							</Col>
							<Col span={3}>
								<Form.Item label='Threshold' name='Threshold'>
									<Slider
										value={threshold}
										min={1}
										max={maxThreshold}
										disabled={maxThreshold === 1}
										onAfterChange={onTresholdChange}
									/>
								</Form.Item>
							</Col>
							<Col span={1}>
								<Form.Item label='' name='ThresholdStatus'>
									<span>{`${threshold}/${maxThreshold}`}</span>
								</Form.Item>
							</Col>
							<Col span={4}>
								{
									(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
										<Form.Item>
											<Button type='link' block onClick={changeMapColors}>Map colors</Button>
										</Form.Item>
									)
								}
							</Col>
							<Col span={4}>
								<Form.Item>
									<Button type='primary' block onClick={() => { graph && graph.downloadImage(); }}>
										<ExportOutlined /> Export this view
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
				{
					(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
						<div className='filters-container white-background'>
							{mergedNodes.length > 0 && <Alert
								message="The position of a node resulting from a merge will be determined randomly when the save button is pressed, regardless of any changes made to its location."
								type="warning"
								showIcon
								style={{ marginTop: '10px', marginBottom: '10px' }}
							/>}
							<Form layout='vertical'>
								<Row gutter={16} align='bottom'>
									<Col span={18}>
										<Tooltip placement='top' title='Show merge history'>
											<FieldTimeOutlined style={{ color: '#1991EB', fontSize: 20 }} onClick={openMergeHistory} />
										</Tooltip>
									</Col>
									<Col span={3}>
										<Form.Item>
											<Button type='link' block onClick={async () => { setMergedNodes([]); await getMap(); }} disabled={!mergedNodes.length}>
											Cancel
											</Button>
										</Form.Item>
									</Col>
									<Col span={3}>
										<Form.Item>
											<Button type='primary' block onClick={handleSave} disabled={!mergedNodes.length && !savedNodesPositions.length}>
												<SaveOutlined /> Save map
											</Button>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					)
				}
				<div className='map-container'>
					{filteredMap && !loading && (
						<Map
							map={filteredMap}
							setExternalGraph={setGraph}
							allowMergeCreation={
								user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST
							}
							useForceLayout={filteredMap.nodes.every(node => node.x === undefined && node.y === undefined)}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore: Disables type checking for the following line
							addMerge={addMerge}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore: Disables type checking for the following line
							addNodePosition={addNodePosition}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore: Disables type checking for the following line
							onLoadMap={handleLoadMap}
						/>
					)}
				</div>
				<Modal
					title={'Collapse history'}
					centered
					open={isCollapseHistoryModalOpen}
					width={500}
					destroyOnClose
					onCancel={handleCloseCollapseHistoryModal}
					footer={[]}
					className='collapse-history'
				>
					<List
						dataSource={mergeHistory.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())}
						renderItem={merge => (
							<List.Item className='list-item'>
								<div className='merged-box'>
									<Tag className='new-construct-tag' style={{ border: 'solid 1px ' + merge.newConstruct.type?.color, color: merge.newConstruct.type?.color }}>
										{merge.newConstruct.name}
									</Tag>
									{
										!!merge.canBeUnmerged && (
											<Tooltip placement='top' title='Unmerge'>
												<div className='unmerge-button'>
													<ArrowsAltOutlined className='arrow' onClick={() => unmerge(merge.newConstruct.id)} />
												</div>
											</Tooltip>
										)
									}
								</div>
								<div className='constructs-history'>
									{
										<div>
											<Tag className='new-construct-tag' style={{ border: 'solid 1px ' + merge.construct1.type?.color, color: merge.construct1.type?.color }}>
												{merge.construct1.name}
											</Tag>
											<Tag className='new-construct-tag' style={{ border: 'solid 1px ' + merge.construct2.type?.color, color: merge.construct2.type?.color }}>
												{merge.construct2.name}
											</Tag>
										</div>
									}
								</div>
								<div className='author'>
									{`by ${merge.createdBy.firstName} ${merge.createdBy.lastName} on ${moment(merge.createdAt).format(dateFormat)}`}
								</div>
							</List.Item>
						)}
					/>
				</Modal>
				<Modal
					title={'Customize your map default colors'}
					centered
					open={isMapColorsModalOpen}
					width={500}
					destroyOnClose
					onCancel={handleCloseMapColorsModal}
					footer={[
						<Button key='back' onClick={handleCloseMapColorsModal}>
						Cancel
						</Button>,
						<Button
							key='submit'
							type='primary'
							onClick={onSaveNewMapColors}
						>
							Save colors
						</Button>,
					]}
					className='default-colors'
				>
					<div className='colors-box'>
						<div className='constructs'>
							{
								mapColors.map(constructType => (
									<div key={constructType.id} className='construct-box'>
										<div
											className='color-box'
											style={{ backgroundColor: constructType.color, opacity: constructType.id === selectedConstructType.id ? 1 : 0.5 }}
											onClick={() => onSelectColor(constructType)}
										/>
										<Tag className='tag-box' style={{ border: 'solid 1px ' + constructType.color, color: constructType.color }}>
											{constructType.value}
										</Tag>
									</div>
								))
							}
						</div>
						<div className='color-palette'>
							{
								!loadingColor ? (
									<ColorPalettePicker initialColor={selectedConstructType?.color || 'black'} onChangeComplete={onChangeComplete} />
								) : (
									<Spin size='large' />
								)
							}
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default ProjectMap;
