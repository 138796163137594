interface ActiveProject {
	name: string;
}

interface ClientIndustry {
	id: number;
	name: string;
}

interface ClientPartner {
	id: number;
	name: string;
}

export enum ClientStatus {
	DISABLED = 0,
	ENABLED = 1,
}

export interface Client {
	key: number;
	name: string;
	description?: string;
	globalPartner?: ClientPartner;
	activeProjects?: [ActiveProject];
	industry?: ClientIndustry;
	status?: ClientStatus;
}
