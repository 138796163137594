import axios from 'axios';
import BaseService from './BaseService';
import Configuration from './ServiceConfig';
import { Lexicon } from '../interfaces/Lexicon';
import { Paragraph } from '../interfaces/Paragraph';

interface DataResponse {
	lexicon: Lexicon[];
	paragraphs: { [key: string]: Paragraph };
}

interface Response {
	data: DataResponse;
	totalCount: number;
}

class LexiconService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	constructor(projectId: number) {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}projects/${projectId}/`;
		this.token = localStorage.getItem('token');
	}

	getConstructExcerpts = async (): Promise<Response | void> => {
		return axios
			.get(`${this.path}lexicons`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				const paragraphs: { [key: string]: Paragraph } = {};

				if (response.data.paragraphs) {
					response.data.paragraphs.forEach((paragraph : Paragraph) => {
						paragraphs[paragraph.id] = paragraph;
					});
				}

				const data = {
					lexicon: response.data.constructs as Lexicon[],
					paragraphs,
				};

				return {
					data,
					totalCount: response.data.length,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	};

	getConstructExcerptsByTranscript = async (transcriptId: number): Promise<Response | void> => {
		return axios
			.get(`${this.path}transcripts/${transcriptId}/lexicons`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				const paragraphs: { [key: string]: Paragraph } = {};

				if (response.data.paragraphs) {
					response.data.paragraphs.forEach((paragraph : Paragraph) => {
						paragraphs[paragraph.id] = paragraph;
					});
				}

				const data = {
					lexicon: response.data.constructs as Lexicon[],
					paragraphs,
				};

				return {
					data,
					totalCount: response.data.length,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	};

	exportLexicon = async () => {
		return axios.get(`${this.path}lexicons/export`, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');

			link.href = url;

			const contentDisposition = response.headers['content-disposition'];
			const match = contentDisposition?.match( /filename=(.*)/);

			if (match && match.length === 2) {
				const filename = match[1];

				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}

		}).catch((err) => {
			if (err.response) {
				this.handleResponseError(err.response);
			} else {
				this.handleError(err);
			}
		});
	};
}

export default LexiconService;
