import React from 'react';

export const ProjectTranscriptScrollContext = React.createContext();

const ProjectTranscriptScrollProvider = (props) => {
	const [transcriptScrollY, setTranscriptScrollY] = React.useState(0);

	return (
		<ProjectTranscriptScrollContext.Provider
			value={{ transcriptScrollY, setTranscriptScrollY }}
		>
			{props.children}
		</ProjectTranscriptScrollContext.Provider>
	);
};

export default ProjectTranscriptScrollProvider;
