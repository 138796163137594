import {
	COLOR_BLUE_HEX,
	COLOR_GOLD_HEX,
	COLOR_MAGENTA_HEX,
	COLOR_ORANGE_HEX,
	COLOR_PURPLE_HEX,
	COLOR_SLATE_BLUE_HEX,
	COLOR_TEAL_HEX
} from './ColorsHex';

let map = new Map();

map.set(COLOR_TEAL_HEX, '#00826A4C');
map.set(COLOR_ORANGE_HEX, '#CC4D084C');
map.set(COLOR_MAGENTA_HEX, '#C41D7F4C');
map.set(COLOR_PURPLE_HEX, '#5A26AE4C');
map.set(COLOR_BLUE_HEX, '#096DD94C');
map.set(COLOR_GOLD_HEX, '#CD9B464C');
map.set(COLOR_SLATE_BLUE_HEX, '#4654724C');

export default map;
