import './BtnTab.less';
import PropTypes from 'prop-types';
import React from 'react';

const BtnTab = ({label, onClick, isActive}) => {
	return (
		<button className={isActive ? 'BtnTab active' : 'BtnTab'} onClick={onClick}>
			{label}
		</button>
	);
};

BtnTab.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	isActive: PropTypes.bool,
};

export default BtnTab;
