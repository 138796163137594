const FindSentence = (paragragh: string, startIndex: number, endIndex: number): string => {
	const sentenceDelimiter = '.';

	const firstParagraphIndex = 0;
	const firstSubstring = paragragh.substring(firstParagraphIndex, startIndex);
	let sentenceStartIndex = firstSubstring.lastIndexOf(sentenceDelimiter);

	if (sentenceStartIndex === -1) {
		sentenceStartIndex = firstParagraphIndex;
	} else {
		sentenceStartIndex = sentenceStartIndex + 1;
	}

	const lastParagraphIndex = paragragh.length;
	const secondSubstring = paragragh.substring(endIndex, lastParagraphIndex);
	let sentenceEndIndex = secondSubstring.indexOf(sentenceDelimiter);

	if (sentenceEndIndex === -1) {
		sentenceEndIndex = lastParagraphIndex;
	} else {
		sentenceEndIndex = sentenceEndIndex + endIndex;
	}

	return paragragh.substring(sentenceStartIndex, sentenceEndIndex).trim();
};

export default FindSentence;
