import './Dashboard.less';

import React, { useContext, useEffect, useState } from 'react';

import DashboardConstructs from '../../components/DashboardConstructs/DashboardConstructs';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import DashboardService from '../../services/DashboardService';
import { ErrorContext } from '../../providers/ErrorProvider';
import IndustriesService from '../../services/IndustriesService';
import RecentProjects from '../../components/RecentProjects/RecentProjects';

const Dashboard = () => {
	const { setError } = useContext(ErrorContext);
	const [projects, setProjects] = useState([]);
	const [projectCount, setProjectCount] = useState(0);
	const [filterLatestConstructsIndustry, setFilterLatestConstructsIndustry] = useState(undefined);
	const [filterMostUsedConstructsIndustry, setFilterMostUsedConstructsIndustry] = useState(undefined);
	const [filterMostUsedConstructsPeriod, setFilterMostUsedConstructsPeriod] = useState(undefined);
	const [industries, setIndustries] = useState([]);
	const [latestConstructs, setLatestConstructs] = useState([]);
	const [mostUsedConstructs, setMostUsedConstructs] = useState([]);
	const [periods, setPeriods] = useState([]);
	const dashboardService = new DashboardService(setError);
	const industriesService = new IndustriesService();

	const getProjects = async () => {
		try {
			const response = await dashboardService.getRecentProjects();

			setProjects(response.data);
			setProjectCount(response.totalCount);
		} catch (err) {
			setError(err.toString());
		}
	};

	const getIndustries = async () => {
		try {
			const response = await industriesService.getAll();

			setIndustries(response.data);
		} catch (err) {
			setError(err.toString());
		}
	};

	const getLatestConstructs = async () => {
		try {
			const constructs = await dashboardService.getLatestConstructs(filterLatestConstructsIndustry);

			setLatestConstructs(constructs);
		} catch (err) {
			setError(err.toString());
		}
	};

	const getMostUsedConstructs = async () => {
		try {
			const constructs = await dashboardService.getMostUsedConstructs(filterMostUsedConstructsIndustry, filterMostUsedConstructsPeriod);

			setMostUsedConstructs(constructs);
		} catch (err) {
			setError(err.toString());
		}
	};

	const getPeriods = async () => {
		try {
			const periods = await dashboardService.getPeriods();

			setPeriods(periods);
		} catch (err) {
			setError(err.toString());
		}
	};

	useEffect(() => {
		getLatestConstructs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterLatestConstructsIndustry]);

	useEffect(() => {
		getMostUsedConstructs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterMostUsedConstructsIndustry, filterMostUsedConstructsPeriod]);

	useEffect(() => {
		getProjects();
		getIndustries();
		getLatestConstructs();
		getPeriods();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<DashboardHeader />
			<div className="dashboard-content">
				<RecentProjects
					projects={projects}
					projectCount={projectCount}
				/>
				<div className="constructs-info">
					<DashboardConstructs
						constructsData={latestConstructs}
						industries={industries}
						title="LATEST CONSTRUCTS"
						handleSelectIndustry={setFilterLatestConstructsIndustry}
					/>
					<DashboardConstructs
						constructsData={mostUsedConstructs}
						industries={industries}
						title="MOST USED CONSTRUCTS"
						periods={periods}
						handleSelectIndustry={setFilterMostUsedConstructsIndustry}
						handleSelectPeriod={setFilterMostUsedConstructsPeriod}
					/>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
