import { Checkbox, Col, Divider, Form, Input, Row, Select } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import ClientsService from '../../services/ClientsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import PartnersService from '../../services/PartnersService';
import { UserContext } from '../../providers/UserProvider';
import { UserOutlined } from '@ant-design/icons';

const userGray = '#BFBFBF';

const AnalystForm = ({ field }) => {
	const [clients, setClients] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [partners, setPartners] = React.useState([]);
	const [selectedPartner, setSelectedPartner] = React.useState(null);
	const [isAdministrator, setIsAdministrator] = React.useState(false);
	const { Option } = Select;
	const { setError } = React.useContext(ErrorContext);
	const { user } = React.useContext(UserContext);
	const clientsService = new ClientsService();
	const partnersService = new PartnersService();

	const getPartners = () => {
		setLoading(true);

		partnersService
			.getAll()
			.then(result => {
				if (result !== undefined) {
					setPartners(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getClientsByPartner = partnerId => {
		setLoading(true);

		clientsService
			.getByPartnerId(partnerId)
			.then(result => {
				if (result !== undefined) {
					setClients(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		user?.role === AnalystRole.ADMIN ? getPartners() : getClientsByPartner(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<Row>
				<Col style={{ width: '280px', marginRight: '20px' }}>
					<Form.Item
						className="new-analyst-data"
						name={[field.name, 'email']}
						fieldKey={[field.fieldKey, 'email']}
						label="Email"
						rules={[{ required: true, type: 'email', message: 'Required field with email format.'}]}
					>
						<Input prefix={<UserOutlined style={{ color: userGray }} />} />
					</Form.Item>
				</Col>
				<Col style={{ width: '150px', marginRight: '14px' }}>
					<Form.Item
						{...field}
						className="new-analyst-data"
						name={[field.name, 'name']}
						fieldKey={[field.fieldKey, 'name']}
						label="Name"
						rules={[{ required: true, message: 'This field is required.'}]}
					>
						<Input prefix={<UserOutlined style={{ color: userGray }} />} />
					</Form.Item>
				</Col>
				<Col style={{ width: '150px' }}>
					<Form.Item
						className="new-analyst-data"
						name={[field.name, 'surname']}
						fieldKey={[field.fieldKey, 'surname']}
						label="Surname"
						rules={[{ required: true, message: 'This field is required.'}]}
					>
						<Input prefix={<UserOutlined style={{ color: userGray }} />} />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item
				noStyle={true}
				name={[field.name, 'administrator']}
				fieldKey={[field.fieldKey, 'administrator']}
				valuePropName="checked"
				initialValue={false}
			>
				<Checkbox className="new-analyst confidential-checkbox" onChange={e => setIsAdministrator(e.target.checked)}>
					{' '}
					Make this analyst an administrator{' '}
				</Checkbox>
			</Form.Item>
			{
				user?.role === AnalystRole.ADMIN && (
					<Form.Item
						className="new-analyst-partners"
						name={[field.name, 'partner']}
						fieldKey={[field.fieldKey, 'partner']}
						label="Partner"
						rules={[{ required: true, message: 'This field is required.'}]}
					>
						<Select
							style={{ width: 195 }}
							onSelect={(partnerId) => {
								getClientsByPartner(partnerId);
							}}
							onChange={value => setSelectedPartner(value)}
						>
							{partners.map((partner, index) => (
								<Option key={index} value={partner.key}>
									{partner.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				)
			}
			{
				!isAdministrator && (
					<Form.Item
						className="new-analyst-clients"
						name={[field.name, 'clients']}
						fieldKey={[field.fieldKey, 'clients']}
						label="Clients"
					>
						<Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Select clients" disabled={(!selectedPartner && AnalystRole.ADMIN) || loading} loading={loading} >
							{clients.map((client, index) => (
								<Option key={index} value={client.key}>
									{client.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				)
			}
			<Divider className="analyst divider" />
		</Fragment>
	);
};

export default AnalystForm;
