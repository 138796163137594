import mixpanel from 'mixpanel-browser';
mixpanel.init('c1c1c1a');

let env_check = true; //process.env.NODE_ENV === 'production';

let actions = {
	identify: (id) => {
		if (env_check) {mixpanel.identify(id);}
	},
	alias: (id) => {
		if (env_check) {mixpanel.alias(id);}
	},
	track: (name, props) => {
		if (env_check) {mixpanel.track(name, props);}
	},
	people: {
		set: (props) => {
			if (env_check) {mixpanel.people.set(props);}
		},
	},
};

export let Mixpanel = actions;
