import './SidebarOptionsLexicon.less';
import {
	CheckCircleOutlined,
	LeftOutlined,
	RightOutlined
} from '@ant-design/icons';
import {
	COLOR_BRIGHT_GRAY_HEX,
	COLOR_BUBBLES_HEX,
	COLOR_CULTURED_HEX,
	COLOR_LIGHT_GREY_HEX
} from '../../utils/ColorsHex';
import React, { useEffect, useState } from 'react';
import { Spin, Typography } from 'antd';
import MapColors from '../../utils/MapColorsForTag';
import MapColorsForTagBackground from '../../utils/MapColorsForTagBackground';
import Tag from '../Tag/Tag';

const SidebarOptionsLexicon = ({
	onClickConstruct,
	usedConstructType,
	constructList,
	loading,
	constructSelected,
	disableConstructList,
}) => {
	const [collapsable, setCollapsable] = useState(false);
	const { Text } = Typography;

	const handleCollapsable = () => {
		setCollapsable(true);
	};

	const handleClose = () => {
		setCollapsable(false);
	};

	const onClickOption = (construct) => {
		onClickConstruct(construct);
	};

	useEffect(() => {
		if (constructSelected?.type?.id === usedConstructType.id) {
			handleCollapsable(usedConstructType);
		} else {
			setCollapsable(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [constructSelected]);

	return (
		<>
			{
				<div className="SidebarOptionsLexicon">
					{!collapsable ? (
						<div
							className="sidebar-header"
							style={{ borderColor: usedConstructType.color }}
							onClick={handleCollapsable}
						>
							<Text className="text">
								{usedConstructType.value}
							</Text>
							<div className="arrow-toggle">
								<RightOutlined />
							</div>
						</div>
					) : (
						<div>
							<div
								className="sidebar-header"
								onClick={handleClose}
								style={{
									justifyContent: 'start',
									border: 'none',
								}}
							>
								<div className="arrow-toggle">
									<LeftOutlined />
								</div>
								<Text className="text">
									{usedConstructType.value}
								</Text>
							</div>
							<Spin spinning={loading} className="spinner" />
							{!loading &&
								constructList &&
								constructList.map((construct, index) => {
									const disabled =
										disableConstructList &&
										disableConstructList.find(
											(link) => link?.id === construct?.id
										);

									return (
										<div
											key={construct.id}
											style={{
												borderTop:
													index > 0
														? 'none'
														: `solid 1px ${COLOR_BRIGHT_GRAY_HEX}`,
												backgroundColor:
													constructSelected &&
														constructSelected.id ===
														construct.id
														? COLOR_BUBBLES_HEX
														: disabled
															? COLOR_CULTURED_HEX
															: 'white',
											}}
											className="excerpt-component"
											onClick={
												disabled
													? undefined
													: () =>
														onClickOption(
															construct
														)
											}
										>
											<Tag
												color={disabled ? COLOR_LIGHT_GREY_HEX : MapColors.get(
													usedConstructType.color
												)}
												backgroundColor={MapColorsForTagBackground.get(
													usedConstructType.color
												)}
												borderColor={MapColors.get(
													usedConstructType.color
												)}
												style={{ cursor: 'pointer', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
											>
												{construct.name}
											</Tag>
											{constructSelected && constructSelected.id === construct.id && (
												<CheckCircleOutlined
													className="arrow-toggle"
													style={{
														color: '#2174C1',
													}}
												/>
											)}
										</div>
									);
								})}
						</div>
					)}
				</div>
			}
		</>
	);
};

export default SidebarOptionsLexicon;
