interface AnalystClient {
	name: string;
}

interface AnalystPartner {
	id: number;
	name: string;
	clients?: AnalystClient[];
}

export interface Analyst {
	key: number;
	analyst: {
		name: string;
		surname: string;
		email: string;
	};
	clients?: AnalystClient[];
	globalPartner?: AnalystPartner;
	profile: number;
	status: number;
}

export enum AnalystRole {
	ADMIN = 0,
	GLOBAL_PARTNER_ADMIN = 1,
	ANALYST = 2,
}

export enum AnalystStatus {
	SUSPENDED = 0,
	ACTIVE = 1,
	INVITED = 2,
}
