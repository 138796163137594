import './SidebarTranscripts.less';
import { AutoComplete, Button, Input, notification, Spin, Tabs, Tag, Typography } from 'antd';
import {
	DeleteOutlined,
	LinkOutlined,
	SearchOutlined
} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import ConstructsService from '../../services/ConstructsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import MapColors from '../../utils/MapColorsForTag';
import MapColorsAnnotator from '../../utils/MapColorsForAnnotator';
import ProjectConstructsService from '../../services/Project/ProjectConstructsService';
import ProjectTranscriptService from '../../services/Project/ProjectTranscriptService';
import { ROUTES } from '../../constants/routes-path';
import SidebarOptionsLexicon from '../SidebarOptionsLexicon/SidebarOptionsLexicon';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;
const { TabPane } = Tabs;

const SidebarTranscripts = ({
	annotatorCallback,
	constructs,
	annotateSelected,
	setIsAnnotateRemoved,
	projectId,
	transcriptId,
	paragraphSelected,
	loading,
	projectLevel,
	usedConstructsInExcerpts = [],
	setUsedConstructsInExcerpts = () => {},
}) => {
	const [constructToggle] = useState('');
	const [constructList, setConstructList] = useState([]);
	const [linkedConstructs, setLinkedConstructs] = useState([]);
	const [constructSearch, setConstructSearch] = useState('');
	const [linkSearch, setLinkSearch] = useState('');
	const [constructTypes, setConstructTypes] = useState(new Set());
	const [constructSelected, setConstructSelected] = useState(null);
	const [loadingSidebar, setLoadingSidebar] = useState(false);
	const { setError } = useContext(ErrorContext);
	const projectTranscriptService = new ProjectTranscriptService(
		projectId,
		+transcriptId
	);
	const navigate = useNavigate();
	const constructService = new ConstructsService();
	const projectConstructsService = new ProjectConstructsService(projectId);
	const [api, contextHolder] = notification.useNotification();

	const renderConstructSearchItem = (
		construct,
		constructType,
		color,
		description
	) => (
		<div
			style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
		>
			<Tag color={MapColors.get(color)} style={{ marginBottom: 6 }}>
				{construct}
			</Tag>
			<div
				style={{
					display: 'flex',
					marginLeft: 18,
					overflowWrap: 'break-word',
					wordWrap: 'break-word',
					whiteSpace: 'initial',
				}}
			>
				<p>
					<b>{`${constructType}${description ? ':' : ''}`}</b>&nbsp;
					{description}
				</p>
			</div>
		</div>
	);

	const searchConstructOptionsAnnotate = constructs
		.map((construct) => ({
			value: construct.name,
			label: renderConstructSearchItem(
				construct.name,
				construct.type.value,
				construct.type.color,
				construct.description
			),
		}));

	const searchConstructOptionsLink = constructs
		.filter((construct) => usedConstructsInExcerpts.includes(construct.id))
		.map((construct) => ({
			value: construct.name,
			label: renderConstructSearchItem(
				construct.name,
				construct.type.value,
				construct.type.color,
				construct.description
			),
		}));

	const onClickRemove = async () => {
		try {
			setLoadingSidebar(true);

			if (annotateSelected.id) {
				await projectTranscriptService.removeExcerpt(
					paragraphSelected,
					annotateSelected.id
				);
				setUsedConstructsInExcerpts([
					...usedConstructsInExcerpts.filter(
						(constructId) =>
							constructId !== annotateSelected.construct.id
					),
				]);
				await fetchConstructLinks();
				annotatorCallback.call();
			}

			setConstructSelected(null);

			if (setIsAnnotateRemoved) {
				setIsAnnotateRemoved(true);
			}
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoadingSidebar(false);
		}
	};

	const onClickSelectConstruct = async (construct) => {
		try {
			setLoadingSidebar(true);

			if (!annotateSelected.id) {
				const newId = await projectTranscriptService.createExcerpt(
					paragraphSelected,
					{ ...annotateSelected, construct }
				);

				annotateSelected.id = newId;
			} else {
				await projectTranscriptService.updateExcerpt(
					paragraphSelected,
					annotateSelected.id,
					{ ...annotateSelected, construct }
				);
				api['warning']({
					message: 'Construct updated',
					description: `You just changed ${annotateSelected.construct.name} construct for ${construct.name} construct on this Excerpt`,
				});
			}

			annotateSelected.construct = construct;
			setConstructSelected(construct);
			setUsedConstructsInExcerpts([
				...new Set([...usedConstructsInExcerpts, construct.id]),
			]);
			annotatorCallback.call();
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoadingSidebar(false);
		}
	};

	const createNewLink = async (construct) => {
		try {
			setLoadingSidebar(true);

			await projectConstructsService.addLink(
				constructSelected.id,
				construct.id,
				+transcriptId
			);
			await fetchConstructLinks();
			api['success']({
				message: 'Link created',
				description: `You just created a new Link between ${construct.name} construct and this Construct`,
			});
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoadingSidebar(false);
		}
	};

	const deleteLink = async (construct) => {
		try {
			setLoadingSidebar(true);

			await projectConstructsService.removeLink(
				constructSelected.id,
				construct.id,
				+transcriptId
			);
			await fetchConstructLinks();
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoadingSidebar(false);
		}
	};

	const fetchConstructLinks = async () => {
		try {
			setLoadingSidebar(true);

			if (constructSelected) {
				const constructs = await projectConstructsService.getLinks(
					constructSelected.id,
					projectLevel ? null : +transcriptId
				);

				setLinkedConstructs(constructs);
			} else {
				setLinkedConstructs([]);
			}
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoadingSidebar(false);
		}
	};

	const onSelectConstructOption = async (constructValue) => {
		const construct = constructs.find(
			(construct) => construct.name === constructValue
		);

		if (construct) {
			onClickSelectConstruct(construct);
		}

		setConstructSearch('');
	};

	const onSelectLinkOption = async (constructValue) => {
		const construct = constructs.find(
			(construct) => construct.name === constructValue
		);

		if (construct) {
			onClickConstructLink(construct);
		}

		setLinkSearch('');
	};

	const onClickConstructAnnotate = async (construct) => {
		if (construct.name === annotateSelected.construct?.name) {
			onClickRemove();
			alert('removing');
		} else {
			onClickSelectConstruct(construct);
		}
	};

	const sidebarOptionsLexiconAnnotate = () => {
		return (
			<div className="options-container">
				<div className={`options ${constructToggle} `}>
					{Array.from(constructTypes).map((constructType, i) => {
						return (
							<SidebarOptionsLexicon
								key={i}
								onClickConstruct={onClickConstructAnnotate}
								usedConstructType={
									constructService.constructsTypeMap[constructType]
								}
								constructList={constructList.filter(
									(construct) =>
										construct.type.id === constructType
								)}
								loading={loading || loadingSidebar}
								constructSelected={constructSelected}
							/>
						);
					})}
				</div>
			</div>
		);
	};

	const onClickConstructLink = async (construct) => {
		createNewLink(construct);
	};

	const sidebarOptionsLexiconLink = () => {
		return (
			<div className="options-container">
				<div className={`options ${constructToggle} `}>
					{Array.from(constructTypes).map((constructType, i) => {
						return (
							<SidebarOptionsLexicon
								key={i}
								onClickConstruct={onClickConstructLink}
								usedConstructType={
									constructService.constructsTypeMap[constructType]
								}
								constructList={constructList
									.filter((construct) =>
										usedConstructsInExcerpts.includes(
											construct.id
										)
									)
									.filter(
										(construct) =>
											construct.type.id === constructType
									)}
								loading={loading || loadingSidebar}
								constructSelected={null}
								disableConstructList={[
									...linkedConstructs.filter(
										(construct) =>
											construct.type === constructType
									),
									constructSelected,
								]}
							/>
						);
					})}
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (constructs) {
			const usedConstructTypes = new Set();

			const orderMap = new Map(constructService.getAllConstructsType().map((element, index) => [element.id, index]));

			constructs.forEach((construct) => {
				usedConstructTypes.add(construct.type?.id);
			});

			const usedConstructTypesOrdered = [...usedConstructTypes].sort((a, b) => orderMap.get(a) - orderMap.get(b));

			setConstructTypes(usedConstructTypesOrdered);
			setConstructList(constructs);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [constructs]);

	useEffect(() => {
		fetchConstructLinks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [constructSelected]);

	useEffect(() => {
		if (annotateSelected && annotateSelected.construct) {
			setConstructSelected(annotateSelected.construct);
		} else {
			setConstructSelected(null);
		}
	}, [annotateSelected]);

	return (
		<div className="SidebarTranscripts">
			{contextHolder}
			<Tabs defaultActiveKey="1">
				<TabPane key="1" tab="ANNOTATE">
					<div>
						<div className="tab-container">
							<div className="details-container">
								<Text strong className="text">
									Excerpt
								</Text>
								<div>
									{
										loadingSidebar ? (
											<Spin spinning={loadingSidebar} className="remove-trash" />
										) : (
											annotateSelected && (<DeleteOutlined
												onClick={onClickRemove}
												className="remove-trash"
											/>)
										)
									}
									{
										annotateSelected ? (
											<Text
												className="excerpt"
												style={{
													backgroundColor:
														annotateSelected?.construct &&
															annotateSelected?.construct
																.type
															? MapColorsAnnotator.get(
																annotateSelected
																	.construct
																	.type.color
															)
															: '#B2F1E8',
													marginBottom: 10,
												}}
											>
												{annotateSelected?.text}
											</Text>
										) : (
											<Text className="no-excerpt">
												No excerpt selected
											</Text>
										)
									}
								</div>
								<Text strong className="text">
									Construct
								</Text>
								<AutoComplete
									options={searchConstructOptionsAnnotate}
									style={{ width: '100%' }}
									placeholder="Start typing"
									filterOption={(inputValue, option) =>
										option.value
											.toLowerCase()
											.includes(
												inputValue.toLowerCase()
											)
									}
									suffix={<SearchOutlined />}
									onSelect={onSelectConstructOption}
									allowClear
									value={constructSearch}
									onChange={setConstructSearch}
								>
									<Input.Search size="medium" />
								</AutoComplete>
							</div>

							{sidebarOptionsLexiconAnnotate()}

							<div className="add-construct">
								<Button
									type="link"
									onClick={() =>
										navigate(ROUTES.PROJECT_ADD_CONSTRUCTS(projectId))
									}
								>
									+ Add a construct
								</Button>
							</div>
						</div>
					</div>
				</TabPane>

				<TabPane key="2" tab="LINK">
					{(
						<div>
							<div className="tab-container">
								<div className="details-container">
									<Text strong className="text">
										Excerpt
									</Text>
									<div
										style={{
											display: 'grid',
											marginBottom: 10,
										}}
									>
										{
											annotateSelected ? (
												<Text
													className="excerpt"
													style={{
														backgroundColor:
															annotateSelected?.construct &&
																annotateSelected?.construct
																	.type
																? MapColorsAnnotator.get(
																	annotateSelected?.construct.type.color
																)
																: '#B2F1E8',
														marginBottom: 10,
													}}
												>
													{annotateSelected?.text}
												</Text>
											) : (
												<Text className="no-excerpt">
													No excerpt selected
												</Text>
											)
										}
										{annotateSelected && annotateSelected.construct && annotateSelected.construct.type && (
											<Tag
												color={MapColors.get(
													annotateSelected
														.construct.type
														.color
												)}
												style={{
													width: 'fit-content',
												}}
											>
												{
													annotateSelected
														.construct.name
												}
											</Tag>
										)}
									</div>
									{!projectLevel && (
										<>
											<Text strong className="text">
												Link to
											</Text>
											<AutoComplete
												options={
													searchConstructOptionsLink
												}
												style={{ width: '100%' }}
												placeholder="Start typing"
												filterOption={(
													inputValue,
													option
												) =>
													option.value
														.toLowerCase()
														.includes(
															inputValue.toLowerCase()
														)
												}
												suffix={<SearchOutlined />}
												onSelect={onSelectLinkOption}
												allowClear
												value={linkSearch}
												onChange={setLinkSearch}
											>
												<Input.Search size="medium" />
											</AutoComplete>
										</>
									)}
								</div>

								{!projectLevel && sidebarOptionsLexiconLink()}
							</div>
							<div className="link-container">
								{linkedConstructs && linkedConstructs.length > 0 && !loadingSidebar && (
									<>
										<p style={{ fontWeight: 600 }}>
											Linked with
										</p>
										{linkedConstructs.map((link, i) => {
											const constructType =
												constructService.constructsTypeMap[link.type];

											return (
												<div
													key={i}
													className="linked-excerpts"
												>
													<Text
														className="excerpt"
														style={{
															marginBottom: 10,
															backgroundColor:
																MapColorsAnnotator.get(
																	constructType.color
																),
														}}
													>
														{constructType.value}
													</Text>
													<div className="link-construct">
														<Tag
															color={MapColors.get(
																constructType.color
															)}
															style={{ cursor: 'pointer', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
														>
															{link.name}
														</Tag>
														{!projectLevel && (
															<LinkOutlined
																onClick={() =>
																	deleteLink(link)
																}
																style={{
																	cursor: 'pointer',
																}}
															/>
														)}
													</div>
												</div>
											);
										})}
									</>
								)}
								<div style={{ textAlign: 'center' }}><Spin spinning={loadingSidebar} className="remove-trash" /></div>
							</div>
						</div>
					)}
				</TabPane>
			</Tabs>
		</div>
	);
};

export default SidebarTranscripts;
