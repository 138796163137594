import { Button, Col, Input, Row, Space } from 'antd';
import { ExportOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import CollapsableLexicon from '../../components/CollapsableLexicon/CollapsableLexicon';
import ConstructsService from '../../services/ConstructsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import Form from 'antd/lib/form/Form';
import LexiconService from '../../services/LexiconService';
import PROJECT_ROUTES from '../../constants/project-constants';
import ProjectConstructsService from '../../services/Project/ProjectConstructsService';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectsService from '../../services/ProjectsService';
import ProjectTranscriptsService from '../../services/Project/ProjectTranscriptsService';
import SidebarTranscripts from '../../components/SidebarTranscripts/SidebarTranscripts';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

const Lexicon = () => {
	const [constructsWithExcerpts, setConstructsWithExcerpts] = useState([]);
	const [projectConstructs, setProjectConstructs] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [annotateSelected, setAnnotateSelected] = useState(null);
	const [paragraphSelected, setParagraphSelected] = useState(null);
	const [paragraphsDictionary, setParagraphsDictionary] = useState({});
	const [annotatorCallback, setAnnotatorCallback] = useState(null);
	const [constructTypes, setConstructTypes] = useState([]);
	const [loading, setLoading] = useState(false);
	const { setError } = useContext(ErrorContext);
	const { projectId } = useParams();
	const lexiconService = new LexiconService(projectId);
	const constructsService = new ConstructsService();
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);
	const projectsService = new ProjectsService();
	const [transcripts, setTranscripts] = useState([]);
	const projectConstructsService = new ProjectConstructsService(projectId);
	const transcriptsService = new ProjectTranscriptsService(projectId);
	const { user } = useContext(UserContext);

	const getTranscripts = () => {
		setLoading(true);

		transcriptsService
			.getAllWithoutPagination()
			.then(result => {
				if (result !== undefined) {
					setTranscripts(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getConstructTypes = () => {
		const allConstructTypes = constructsService.getAllConstructsType();

		setConstructTypes(allConstructTypes);
	};

	const getConstructExcerpts = async () => {
		setLoading(true);

		try {
			const response = await lexiconService.getConstructExcerpts();

			setConstructsWithExcerpts(response.data.lexicon);
			setParagraphsDictionary(response.data.paragraphs);
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const getProjectConstructs = async () => {
		try {
			const constructsPerProject = await projectConstructsService.getAllWithoutPagination();

			setProjectConstructs(constructsPerProject.data.sort((a, b) => a.name.localeCompare(b.name)));
		} catch (err) {
			setError(err.toString());
		}
	};

	useEffect(() => {
		projectsService.get(projectId).then(data => {
			getConstructTypes();
			getConstructExcerpts();
			getProjectConstructs();
			getTranscripts();
			const actualRoutes = [
				{
					path: 'projects',
					breadcrumbName: 'Projects',
				},
				{
					path: projectId,
					breadcrumbName: data.name,
				},
				{
					path: 'lexicon',
					breadcrumbName: 'Lexicon',
				},
			];

			setRoutes(actualRoutes);
			setActive(PROJECT_ROUTES.LEXICON);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const searchLexicon = (event) => {
		if (event.key === 'Enter') {
			setSearchText(event.currentTarget.value);
		}
	};

	return (
		<div className="Lexicon">
			<div className="filters-container">
				<Form layout="vertical">
					<Row justify="space-between" align="middle" gutter={20}>
						<Col lg={5}>
							<Input
								width="332"
								placeholder="Search"
								onKeyDown={searchLexicon}
								suffix={
									<SearchOutlined />
								}
							/>
						</Col>
						<Col>
							<Button
								type="primary"
								icon={<ExportOutlined />}
								onClick={() => lexiconService.exportLexicon()}
							>
										Export lexicon
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
			<Row gutter={10} style={{margin: '10px 0 30px'}}>
				<Col sm={(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) ? 18 : 24}>
					<Space direction="vertical" size={10} style={{ width: '100%' }}>
						{constructTypes.map((constructType, i) => (
							<CollapsableLexicon
								key={i}
								color={constructType.color}
								label={constructType.value}
								constructsWithExcerpts={constructsWithExcerpts.filter(construct => searchText ? construct.type === constructType.id && construct.name.toLowerCase().includes(searchText.toLowerCase()) : construct.type === constructType.id).sort((a, b) => a.name.localeCompare(b.name))}
								setAnnotateSelected={setAnnotateSelected}
								setParagraphSelected={setParagraphSelected}
								paragraphDictionary={paragraphsDictionary}
								setAnnotatorCallback={setAnnotatorCallback}
								refreshLexicons={() => { getConstructExcerpts(); setAnnotateSelected(null); }}
								transcripts={transcripts}
							/>
						))}
					</Space>
				</Col>
				{
					(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
						<Col sm={6}>
							<div className="sidebar-lexicon">
								<SidebarTranscripts
									constructs={projectConstructs.filter(construct => !construct.disabled)}
									annotatorCallback={annotatorCallback}
									annotateSelected={annotateSelected}
									setAnnotateSelected={setAnnotateSelected}
									paragraphSelected={paragraphSelected}
									projectId={projectId}
									transcriptId={annotateSelected?.paragraph?.transcript?.id || 1}
									loading={loading}
									projectLevel={true}
								/>
							</div>
						</Col>
					)
				}
			</Row>
		</div>
	);
};

export default Lexicon;
