import { GenericResponseError, ResponseError } from '../interfaces/Api';

class BaseService {
	handleResponseError(response: ResponseError) {
		throw ((response.data && response.data.message) || response.status);
	}

	handleError(error: GenericResponseError) {
		throw (error.message);
	}

	getErrorMessage(error: any): string {
		if (error.response) {
			return (error.response.data && error.response.data.message) || error.response.status;
		} else if (error.message) {
			return error.message;
		}

		return error;
	}
}

export default BaseService;
