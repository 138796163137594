import './Header.less';
import { Avatar, Layout, Menu, Typography } from 'antd';
import React, { Fragment } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import MetaphoriaLogo from '../../assets/images/OZ-header.svg';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
	const { Header } = Layout;
	const { Text } = Typography;
	const { user, isAuthenticated, logout } = useAuth0();

	const logOut = () => {
		logout({ returnTo: window.location.origin });
	};

	const userName = () => {
		return (
			<div >
				{isAuthenticated &&
					<Fragment>
						<Avatar size="small" src={user.picture} style={{ marginRight: 10 }} />{user.name}<CaretDownOutlined style={{ marginLeft: 10 }} />
					</Fragment>
				}
			</div>
		);
	};

	return (
		<Header className="page-header">
			<div className="page-header__left">
				<div className="logo">
					<Link to="/">
						<img className="metaphoria-logo" srcSet={MetaphoriaLogo} alt="Logo" />
					</Link>
				</div>
				<div className="brand">
					<div className="separator-brand"></div>
					<Link to="/">
						<Text>Metaphoria</Text>
					</Link>
				</div>
			</div>
			{
				isAuthenticated && (
					<Menu mode="horizontal" items={[
						{
							key: userName(),
							label: userName(),
							children: [
								{key: 'logout',
									label: <Menu.Item onClick={logOut} key="logout">Logout</Menu.Item>,
								},
							],
						},
					]} />

				)
			}
		</Header>
	);
};

export default Header;
