import { AnalystDetails, AnalystList, ClientDetails, ClientsList, ConstructDetails, Constructs, Dashboard, Error, Industry, IndustryDetails, Lexicon, NewAnalyst, NewClient, NewConstruct, NewIndustry, NewPartner, NewProject, NewSegment, NewTypes, PartnerDetails, Partners, ProjectAddConstructs, ProjectConstructs, ProjectDetails, ProjectDuplicate, ProjectMap, Projects, ProjectSegments, ProjectTranscript, ProjectTranscripts, ProjectTypes, SegmentDetails, TranscriptDetails, TypeDetails } from './containers';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AnalystRole } from './interfaces/Analyst';
import LoadUserInformationLayout from './layouts/LoadUserInformationLayout/LoadUserInformationLayout';
import MainLayout from './layouts/MainLayout/MainLayout';
import ProjectLayout from './layouts/ProjectLayout/ProjectLayout';
import ProjectLayoutProvider from './providers/ProjectLayoutProvider';
import ProtectedLayout from './layouts/ProtectedLayout/ProtectedLayout';
import ProvidersLayout from './layouts/ProvidersLayout/ProvidersLayout';
import React from 'react';
import { ROUTES } from './constants/routes-path';

const router = createBrowserRouter([
	{
		element: <ProvidersLayout />,
		errorElement: <Error />,
		children: [
			{
				element: <LoadUserInformationLayout />,
				children: [
					{
						element: <MainLayout />,
						children: [
							{
								path: ROUTES.DASHBOARD,
								element: <Dashboard />,
							},
							{
								path: ROUTES.CONSTRUCTS,
								element: <Constructs />,
							},
							{
								path: ROUTES.NEW_CONSTRUCT,
								element: <NewConstruct />,
							},
							{
								path: ROUTES.CONSTRUCT_DETAILS(),
								element: <ConstructDetails />,
							},
							{
								element: <ProtectedLayout permissions={[AnalystRole.ADMIN]} />,
								children: [
									{
										path: ROUTES.PARTNERS,
										element: <Partners />,
									},
									{
										path: ROUTES.NEW_PARTNERS,
										element: <NewPartner />,
									},
									{
										path: ROUTES.PARTNER_DETAILS(),
										element: <PartnerDetails />,
									},
									{
										path: ROUTES.INDUSTRIES,
										element: <Industry />,
									},
									{
										path: ROUTES.NEW_INDUSTRY,
										element: <NewIndustry />,
									},
									{
										path: ROUTES.INDUSTRY_DETAILS(),
										element: <IndustryDetails />,
									},
								],
							},
							{
								element: (
									<ProtectedLayout
										permissions={[AnalystRole.ADMIN, AnalystRole.GLOBAL_PARTNER_ADMIN]}
									/>
								),
								children: [
									{
										path: ROUTES.ANALYSTS,
										element: <AnalystList />,
									},
									{
										path: ROUTES.NEW_ANALYST,
										element: <NewAnalyst />,
									},
									{
										path: ROUTES.ANALYST_DETAILS(),
										element: <AnalystDetails />,
									},
									{
										path: ROUTES.CLIENTS,
										element: <ClientsList />,
									},
									{
										path: ROUTES.CLIENT_DETAILS(),
										element: <ClientDetails />,
									},
								],
							},
							{
								element: <ProtectedLayout permissions={[AnalystRole.GLOBAL_PARTNER_ADMIN]} />,
								children: [
									{
										path: ROUTES.NEW_CLIENT,
										element: <NewClient />,
									},
								],
							},
							{
								element: (
									<ProtectedLayout
										permissions={[AnalystRole.GLOBAL_PARTNER_ADMIN, AnalystRole.ANALYST]}
									/>
								),
								children: [
									{
										path: ROUTES.NEW_PROJECT,
										element: <NewProject />,
									},
								],
							},
							{
								path: ROUTES.PROJECTS,
								children: [
									{
										index: true,
										element: <Projects />,
									},
									{
										path: ROUTES.PROJECT_DETAILS(),
										element: <Navigate to={'transcripts'} replace />,
									},
									{
										element: (
											<ProjectLayoutProvider>
												<ProjectLayout />
											</ProjectLayoutProvider>
										),
										children: [
											{
												path: ROUTES.PROJECT_TRANSCRIPTS(),
												element: <ProjectTranscripts />,
											},
											{
												path: ROUTES.PROJECT_CONSTRUCTS(),
												element: <ProjectConstructs />,
											},
											{
												path: ROUTES.PROJECT_LEXICON(),
												element: <Lexicon />,
											},
											{
												path: ROUTES.PROJECT_SEGMENTS(),
												element: <ProjectSegments />,
											},
											{
												path: ROUTES.PROJECT_MAP(),
												element: <ProjectMap />,
											},
											{
												path: ROUTES.PROJECT_TYPES(),
												element: <ProjectTypes />,
											},
											{
												element: (
													<ProtectedLayout
														permissions={[
															AnalystRole.GLOBAL_PARTNER_ADMIN,
															AnalystRole.ANALYST,
														]}
													/>
												),
												children: [
													{
														path: ROUTES.PROJECT_ADD_CONSTRUCTS(),
														element: <ProjectAddConstructs />,
													},
												],
											},
										],
									},
									{
										path: ROUTES.PROJECT_NEW_TYPE(),
										element: <NewTypes />,
									},
									{
										path: ROUTES.PROJECT_TYPE_DETAILS(),
										element: <TypeDetails />,
									},
									{
										path: ROUTES.PROJECT_SEGMENTS_DETAILS(),
										element: <SegmentDetails />,
									},
									{
										path: ROUTES.PROJECT_TRANSCRIPT_SELECTED(),
										element: <ProjectTranscript />,
									},
									{
										element: (
											<ProtectedLayout
												permissions={[AnalystRole.GLOBAL_PARTNER_ADMIN, AnalystRole.ANALYST]}
											/>
										),
										children: [
											{
												path: ROUTES.PROJECT_DETAILS_EDIT(),
												element: <ProjectDetails />,
											},
											{
												path: ROUTES.PROJECT_DUPLICATE(),
												element: <ProjectDuplicate />,
											},
											{
												path: ROUTES.PROJECT_TRANSCRIPT_DETAILS_EDIT(),
												element: <TranscriptDetails />,
											},
											{
												path: ROUTES.PROJECT_SEGMENTS_NEW_SEGMENTS(),
												element: <NewSegment />,
											},
										],
									},
								],
							},
						],
					},
				],
			},
		],
	},
]);

export default router;
