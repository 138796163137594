import { ConstructType, MergeHistoryItem } from '../../interfaces/Construct';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { Map as MapI, MapLink, MapNode, MergedNode } from '../../interfaces/Map';
import ConstructsService from '../../services/ConstructsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import { FiltersArray } from '../../interfaces/Api';
import { Graph } from '@antv/g6';
import { IColor } from 'react-color-palette';
import PROJECT_ROUTES from '../../constants/project-constants';
import ProjectConstructsService from '../../services/Project/ProjectConstructsService';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectMapService from '../../services/Project/ProjectMapService';
import ProjectSegmentsService from '../../services/Project/ProjectSegmentsService';
import ProjectsService from '../../services/ProjectsService';
import ProjectTypesService from '../../services/Project/ProjectTypesService';
import { Segment } from '../../interfaces/Segment';
import { Type } from '../../interfaces/Type';
import { useParams } from 'react-router-dom';

interface filterInterface {
	constructType: ConstructType[];
	segmentId: string[];
	typeOptionId: number[];
}

interface UseProjectMapControllerReturn {
	addMerge: (construct1Id: number, construct2Id: number, id: number, name: string, description: string, type: number) => void;
	addNodePosition: (nodes: MapNode | MapNode[]) => void;
	changeMapColors: () => void;
	constructsTypes: ConstructType[];
	filteredMap: null | { nodes: MapNode[], links: MapLink[] };
	filters: filterInterface;
	form: FormInstance;
	getMap: () => Promise<void>;
	graph: Graph | null;
	handleCloseCollapseHistoryModal: () => void;
	handleCloseMapColorsModal: () => void;
	handleLoadMap: (_: never, graph: Graph) => void;
	handleSave: () => void;
	isCollapseHistoryModalOpen: boolean;
	isMapColorsModalOpen: boolean;
	loading: boolean;
	loadingColor: boolean;
	mapColors: ConstructType[];
	maxThreshold: number;
	mergedNodes: MergedNode[]
	mergeHistory: MergeHistoryItem[];
	onChangeComplete: (color: IColor) => void;
	onSaveNewMapColors: () => Promise<void>;
	onSelectColor: (constructType: ConstructType) => void;
	onTresholdChange: (value: number) => void;
	openMergeHistory: () => Promise<void>;
	savedNodesPositions: MapNode[];
	segments: Segment[];
	selectedConstructType: ConstructType;
	setFilters: Dispatch<SetStateAction<filterInterface>>;
	setGraph: Dispatch<SetStateAction<Graph | null>>;
	setMergedNodes: Dispatch<SetStateAction<MergedNode[]>>;
	threshold: number;
	types: Type[];
	unmerge: (constructId: number) => Promise<void>;
}

const UseProjectMapController = (): UseProjectMapControllerReturn => {
	const { setError } = useContext(ErrorContext);
	const constructsService = new ConstructsService();
	const projectsService = new ProjectsService();
	const constructsTypes = constructsService.getAllConstructsType();
	const [filters, setFilters] = useState<filterInterface>({ constructType: [], segmentId: [], typeOptionId: [] });
	const [loading, setLoading] = useState<boolean>(false);
	const [map, setMap] = useState<void | null | MapI>(null);
	const [filteredMap, setFilteredMap] = useState<null | {nodes: MapNode[], links: MapLink[]}>(null);
	const [segments, setSegments] = useState<Segment[]>([]);
	const [types, setTypes] = useState<Type[]>([]);
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);
	const { projectId } = useParams<{ projectId: string }>();
	const projectConstructsService = new ProjectConstructsService(+(projectId||'0'));
	const mapService = new ProjectMapService(+(projectId||'0'));
	const segmentsService = new ProjectSegmentsService(+(projectId||'0'));
	const typesService = new ProjectTypesService(+(projectId||'0'));
	const [graph, setGraph] = useState<null | Graph>(null);
	const [maxThreshold, setMaxThreshold] = useState(1);
	const [form] = Form.useForm();
	const [mergedNodes, setMergedNodes] = useState<MergedNode[]>([]);
	const [savedNodesPositions, setSavedNodesPositions] = useState<MapNode[]>([]);
	const [mergeHistory, setMergeHistory] = useState<MergeHistoryItem[]>([]);
	const [isCollapseHistoryModalOpen, setIsCollapseHistoryModalOpen] = useState(false);
	const [isMapColorsModalOpen, setIsMapColorsModalOpen] = useState(false);
	const [selectedConstructType, setSelectedConstructType] = useState<ConstructType>(constructsTypes[0]);
	const [loadingColor, setLoadingColor] = useState(false);
	const [mapColors, setMapColors] = useState<ConstructType[]>([]);
	const [defaultMapColors, setDefaultMapColors] = useState<ConstructType[]>([]);
	const [threshold, setThreshold] = useState(1);

	const getMap = async () => {
		setLoading(true);

		try {
			const result = await mapService.get(filters as unknown as FiltersArray);

			setMap(result);

			if (result) {

				let thresholdSelected = 1;
				let maxCount = Math.max(...result.links.map(link => link.count));

				maxCount = Number.isFinite(maxCount) ? maxCount : 1;
				thresholdSelected = threshold > maxCount ? maxCount : threshold;
				form.setFieldsValue({ Threshold: thresholdSelected });
				setMaxThreshold(()=>maxCount);
				setThreshold(() => thresholdSelected);

				const newLinks = result.links.filter(link => link.count >= thresholdSelected);
				const newNodes = result.nodes.filter(node => newLinks.some(link => link.source === node.id || link.target === node.id));

				setFilteredMap({ nodes: newNodes, links: newLinks });

			}
		} catch (err) {
			setMap(null);
			setFilteredMap(null);
			setError(err as string);
		}

		setLoading(false);
	};

	const getSegments = async () => {
		setLoading(true);

		try {
			const result = await segmentsService.getAllWithoutPagination();

			if (result) {
				setSegments(result.data);
			}
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	const getTypes = async () => {
		setLoading(true);

		try {
			const result = await typesService.getAllWithoutPagination();

			if (result) {
				setTypes(result.data.filter(type => type.options && type.options.length));
			}
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	const getMapColors = async () => {
		setLoading(true);

		try {
			if (projectId) {
				const result = await projectsService.getConstructTypeColors(+projectId);

				const constructTypeColors = constructsTypes.map(constructType => {
					const constructTypeColor = result.find(constructTypeColor => constructTypeColor.type === constructType.id);

					return ({
						...constructType,
						color: constructTypeColor?.color || constructType.color,
					});
				});

				setMapColors(constructTypeColors);
				setDefaultMapColors(constructTypeColors);
			}
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	useEffect(() => {
		if (projectId) {
			projectsService.get(+projectId).then(async data => {
				await getSegments();
				await getTypes();
				await getMapColors();
				const actualRoutes = [
					{
						path: 'projects',
						breadcrumbName: 'Projects',
					},
					{
						path: projectId,
						breadcrumbName: data.name,
					},
					{
						path: 'map',
						breadcrumbName: 'Map',
					},
				];

				setRoutes(actualRoutes);
				setActive(PROJECT_ROUTES.MAP);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getMap();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const saveMerges = async () => {
		try {
			await projectConstructsService.addMerges(mergedNodes);
			setMergedNodes([]);
		} catch (err) {
			setError(err as string);
		}
	};

	const savePosition = async () => {
		try {
			await projectConstructsService.saveProjectConstructsPosition(savedNodesPositions);
			setSavedNodesPositions([]);
		} catch (err) {
			setError(err as string);
		}
	};

	const handleSave = async () => {
		if (savedNodesPositions.length > 0) {
			await savePosition();
		}

		if (mergedNodes.length > 0) {
			await saveMerges();
		}

		await getMap();
	};

	const addMerge = (construct1Id: number, construct2Id: number, id: number, name: string, description: string, type: number) => {
		setMergedNodes(prevState => ([...prevState, {
			construct1: { id: +construct1Id },
			construct2: { id: +construct2Id },
			newConstruct: {
				id,
				name,
				description,
				type,
			},
		}]));
	};

	const addNodePosition = (nodes: MapNode | MapNode[]) => {
		const newNodes = Array.isArray(nodes) ? nodes : [nodes];

		setSavedNodesPositions(oldNodes => {
			const updatedOldNodes = oldNodes.map((oldNode) => {
				const updatedNodeIndex = newNodes.findIndex((node) => node.id === oldNode.id);

				if (updatedNodeIndex !== -1) {
					const [updatedNode] = newNodes.splice(updatedNodeIndex, 1);

					return updatedNode;
				}

				return oldNode;
			});

			return [...updatedOldNodes, ...newNodes];
		});

	};

	const openMergeHistory = async () => {
		try {
			const items = await projectConstructsService.mergeHistory();

			if (items) {
				setMergeHistory(items);
				setIsCollapseHistoryModalOpen(true);
			}
		} catch (err) {
			setError(err as string);
		}
	};

	const unmerge = async (constructId: number) => {
		try {
			await projectConstructsService.unmerge(constructId);
			await getMap();
			handleCloseCollapseHistoryModal();
		} catch (err) {
			setError(err as string);
		}
	};

	const handleCloseCollapseHistoryModal = () => {
		setIsCollapseHistoryModalOpen(false);
	};

	const changeMapColors = () => {
		setIsMapColorsModalOpen(true);
	};

	const handleCloseMapColorsModal = () => {
		setIsMapColorsModalOpen(false);
		setMapColors(defaultMapColors);
		setSelectedConstructType(defaultMapColors[0]);
	};

	const onSelectColor = (constructType: ConstructType) => {
		setSelectedConstructType(constructType);
		setLoadingColor(true);

		setTimeout(() => {
			setLoadingColor(false);
		}, 500);
	};

	const onChangeComplete = (color: IColor) => {
		setMapColors(mapColors.map(mapColor => {
			if (mapColor.id === selectedConstructType.id) {
				return ({
					...mapColor,
					color: color.hex,
				});
			}

			return mapColor;
		}));
	};

	const onSaveNewMapColors = async () => {
		try {
			if (projectId) {
				await projectsService.changeConstructTypeColors(+projectId, mapColors);
			}

			await getMap();
			setDefaultMapColors(mapColors);
			setIsMapColorsModalOpen(false);
			setSelectedConstructType(mapColors[0]);
		} catch (err) {
			setError(err as string);
		}
	};

	const onTresholdChange = (value: number) => {
		setLoading(true);
		setTimeout(() => {
			if (filteredMap && map) {
				const newLinks = map.links.filter(link => link.count >= value);
				const newNodes = map.nodes.filter(node => newLinks.some(link => link.source === node.id || link.target === node.id));

				setFilteredMap({ nodes: newNodes, links: newLinks });
			}

			setLoading(false);
		}, 100);
		setThreshold(value);
	};

	const handleLoadMap = async (_: never, graph: Graph) => {
		if (filteredMap && filteredMap.nodes.length && filteredMap.nodes.every(node => node.x === undefined && node.y === undefined)) {
			await projectConstructsService.saveProjectConstructsPosition(graph.getNodes().map((node) => {
				return node.getModel() as MapNode;
			}));

			await getMap();
		}
	};

	return {
		addMerge,
		addNodePosition,
		changeMapColors,
		constructsTypes,
		filteredMap,
		filters,
		form,
		getMap,
		graph,
		handleCloseCollapseHistoryModal,
		handleCloseMapColorsModal,
		handleLoadMap,
		handleSave,
		isCollapseHistoryModalOpen,
		isMapColorsModalOpen,
		loading,
		loadingColor,
		mapColors,
		maxThreshold,
		mergedNodes,
		mergeHistory,
		onChangeComplete,
		onSaveNewMapColors,
		onSelectColor,
		onTresholdChange,
		openMergeHistory,
		savedNodesPositions,
		segments,
		selectedConstructType,
		setFilters,
		setGraph,
		setMergedNodes,
		threshold,
		types,
		unmerge,
	};
};

export default UseProjectMapController;
