import { Col, Form, Input, Layout, Row, Select, Spin } from 'antd';
import CommonForm from '../../components/CommonForm/CommonForm';
import ConstructTags from '../../components/ConstructTags/ConstructTags';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import React from 'react';
import useNewConstructController from './useNewConstructController';

const NewConstruct = () => {
	const { constructsType, languages, activeValue, setActiveValue, loading, addConstruct, backToConstructs, form } = useNewConstructController();

	const { TextArea } = Input;
	const { Content } = Layout;
	const { Option } = Select;

	const routes = [
		{ path: '/constructs', breadcrumbName: 'Construct' },
		{ path: '', breadcrumbName: 'New Construct' },
	];

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Create a new construct"
					primaryButton="Create construct"
					onSubmit={addConstruct}
					onCancel={backToConstructs}
					secondClass="project-construct-details"
					form={form}
					disabled={loading}
				>
					<Form.Item
						className="field-name"
						name="type"
						label="Construct type"
						required
					>
						<Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
							{constructsType.map(constructType => (
								<Col key={constructType.id} style={{ margin: 5 }}>
									<ConstructTags
										label={constructType.value}
										active={activeValue === constructType.id ? true : false}
										color={constructType.color}
										onClick={() => setActiveValue(constructType.id)}
									/>
								</Col>
							))}
						</Row>
					</Form.Item>
					<Form.Item
						className="field-name"
						name="name"
						label="Construct name"
						rules={[{ required: true }, { min: 2 }, { max: 255 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Definition"
						rules={[{ required: true }, { min: 2 }, { max: 5000 }]}
					>
						<TextArea className="common-textarea project-construct" rows={3} />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="language"
						label="Construct Language"
						rules={[{ required: true }]}
					>
						<Select
							allowClear
							placeholder="Select language"
						>
							{languages.map((language) => (
								<Option key={language.code} value={language.code}>
									{language.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default NewConstruct;
