import './ProjectTranscript.less';
import { Button, Col, Input, Row, Space, Spin, Switch, Tabs } from 'antd';
import { EditFilled, SearchOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalystRole } from '../../interfaces/Analyst';
import CollapsableLexicon from '../../components/CollapsableLexicon/CollapsableLexicon';
import CollapsableTranscripts from '../../components/CollapsableTranscripts/CollapsableTranscripts';
import ConstructsService from '../../services/ConstructsService';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import LexiconService from '../../services/LexiconService';
import ProjectConstructsService from '../../services/Project/ProjectConstructsService';
import ProjectsService from '../../services/ProjectsService';
import ProjectTranscriptScrollProvider from './ProjectTranscriptScrollProvider';
import ProjectTranscriptsService from '../../services/Project/ProjectTranscriptsService';
import { ROUTES } from '../../constants/routes-path';
import SidebarTranscripts from '../../components/SidebarTranscripts/SidebarTranscripts';
import TranscriptMap from '../TranscriptMap/TranscriptMap';
import { UserContext } from '../../providers/UserProvider';

const ProjectTranscript = () => {
	const [annotatorCallback, setAnnotatorCallback] = useState(null);
	const [annotateSelected, setAnnotateSelected] = useState(null);
	const [constructFilter, setConstructFilter] = useState('');
	const [constructsWithExcerpts, setConstructsWithExcerpts] = useState([]);
	const [isAnnotateRemoved, setIsAnnotateRemoved] = useState(false);
	const [paragraphSelected, setParagraphSelected] = useState(null);
	const [projectConstructs, setProjectConstructs] = useState([]);
	const [paragraphsDictionary, setParagraphsDictionary] = useState({});
	const [loading, setLoading] = useState(false);
	const [routes, setRoutes] = useState(null);
	const [projectTranscript, setProjectTranscript] = useState({
		name: 'UNKNOWN',
		paragraphs: [],
	});
	const [switchChecked, setSwitchChecked] = useState(false);
	const { setError } = useContext(ErrorContext);
	const navigate = useNavigate();
	const { projectId, id: transcriptId } = useParams();
	const { TabPane } = Tabs;
	const projectTranscriptsService = new ProjectTranscriptsService(+projectId);
	const projectConstructsService = new ProjectConstructsService(+projectId);
	const lexiconService = new LexiconService(+projectId);
	const projectsService = new ProjectsService();
	const constructService = new ConstructsService();
	const [usedConstructsInExcerpts, setUsedConstructsInExcerpts] = useState([]);
	const [activeTab, setActiveTab] = new useState('1');
	const { user } = useContext(UserContext);

	const getConstructExcerpts = async () => {
		setLoading(true);

		try {
			const response =
				await lexiconService.getConstructExcerptsByTranscript(
					+transcriptId
				);

			setConstructsWithExcerpts(response.data.lexicon);
			setParagraphsDictionary(response.data.paragraphs);
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const updateRoutes = async (transcriptName) => {
		try {
			const project = await projectsService.get(projectId);
			const actualRoutes = [
				{ path: 'projects', breadcrumbName: 'Projects' },
				{ path: projectId, breadcrumbName: project.name },
				{ path: 'transcripts', breadcrumbName: 'Transcripts' },
				{ path: '', breadcrumbName: transcriptName },
			];

			setRoutes(actualRoutes);
		} catch (err) {
			setError(err.toString());
		}
	};

	const getProjectTranscript = async (transcriptId, shouldUpdateRoutes) => {
		setLoading(true);

		try {
			const transcriptData = await projectTranscriptsService.get(
				+transcriptId
			);

			setProjectTranscript(transcriptData);
			setSwitchChecked(transcriptData.completed);
			setUsedConstructsInExcerpts([
				...new Set(
					transcriptData?.paragraphs
						? transcriptData.paragraphs
							.map((paragraph) => {
								return paragraph.excerpts.map(
									(excerpt) => excerpt.construct.id
								);
							})
							.flat()
						: []
				),
			]);

			if (shouldUpdateRoutes) {
				updateRoutes(transcriptData.name || 'unknown');
			}
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const getProjectConstructs = async () => {
		setLoading(true);

		try {
			const constructsPerProject = await projectConstructsService.getAllWithoutPagination();

			setProjectConstructs(constructsPerProject.data.sort((a, b) => a.name.localeCompare(b.name)));
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const goToEditTranscript = () =>
		navigate(ROUTES.PROJECT_TRANSCRIPT_DETAILS_EDIT(projectId, transcriptId));

	const filterConstructs = (event) => {
		if (event.keyCode === 13) {
			setConstructFilter(event.target.value);
		}
	};

	const onChangeTabs = (activeKey) => {
		if (activeKey === '2') {
			getConstructExcerpts();
			setAnnotateSelected(null);
		} else if (activeKey === '1') {
			setProjectTranscript({ name: 'UNKNOWN', paragraphs: [] });
			getProjectTranscript(+transcriptId, false);
			setAnnotateSelected(null);
		} else if (activeKey === '3') {
			getProjectConstructs();
		}

		setActiveTab(activeKey);
	};

	const onChangeSwitch = async (checked) => {
		setLoading(true);

		try {
			setSwitchChecked(checked);
			const transcriptDataTemp = {
				...projectTranscript,
				completed: !!checked,
			};

			await projectTranscriptsService.update(
				transcriptDataTemp.id,
				transcriptDataTemp.name,
				transcriptDataTemp.segment,
				transcriptDataTemp.types,
				transcriptDataTemp.completed
			);
		} catch (err) {
			setSwitchChecked(!checked);
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		async function fetchData() {
			await getProjectTranscript(+transcriptId, true);
			await getProjectConstructs();
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="transcript-container">
			<Row justify="space-between">
				<Col>
					<div className="breadcrum-wrapper">
						<CustomBreadcrumb routes={routes} />
					</div>
				</Col>
				{
					(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
						<Col>
							<Button
								onClick={goToEditTranscript}
								style={{
									borderColor: '#1991EB',
									color: '#1991EB',
									padding: '5.5px 11.05px',
								}}
								icon={<EditFilled />}
							>
								Edit Transcript
							</Button>
						</Col>
					)
				}
			</Row>
			<Row gutter="20" style={{ height: '100%' }}>
				<Col flex="auto" style={{ height: '100%' }}>
					<ProjectTranscriptScrollProvider>
						<Tabs
							defaultActiveKey="1"
							onChange={onChangeTabs}
							style={{ height: '100%' }}
							tabBarExtraContent={
								<div>
									Mark completed{' '}
									<Switch
										checked={switchChecked}
										size="small"
										onChange={onChangeSwitch}
									/>
								</div>
							}
						>
							<TabPane tab="TRANSCRIPT" key="1" style={{ height: '100%' }}>
								<div style={{ height: '100%' }}>
									<div style={{ textAlign: 'center' }}>
										<Spin spinning={loading} className="spinner" />
									</div>
									{!loading && (
										<CollapsableTranscripts
											transcriptParagraphs={
												projectTranscript.paragraphs
											}
											setAnnotatorCallback={setAnnotatorCallback}
											setAnnotateSelected={setAnnotateSelected}
											setParagraphSelected={setParagraphSelected}
											annotateSelected={annotateSelected}
											isAnnotateRemoved={isAnnotateRemoved}
											setIsAnnotateRemoved={setIsAnnotateRemoved}
										/>
									)}
								</div>
							</TabPane>
							<TabPane tab="LEXICON" key="2">
								<div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
									<Space
										direction="vertical"
										size={10}
										style={{ width: '100%' }}
									>
										{constructService
											.getAllConstructsType()
											.map((constructType, i) => (
												<CollapsableLexicon
													key={i}
													color={constructType.color}
													label={constructType.value}
													constructsWithExcerpts={constructsWithExcerpts.filter(
														(construct) =>
															construct.type ===
															constructType.id
													).sort((a, b) => a.name.localeCompare(b.name))}
													setAnnotateSelected={
														setAnnotateSelected
													}
													setParagraphSelected={
														setParagraphSelected
													}
													paragraphDictionary={
														paragraphsDictionary
													}
													setAnnotatorCallback={
														setAnnotatorCallback
													}
													refreshLexicons={() => {
														getConstructExcerpts();
														setAnnotateSelected(null);
													}}
												/>
											))}
									</Space>
								</div>
							</TabPane>
							<TabPane tab="MAP" key="3">
								<TranscriptMap transcriptLoading={loading} />
							</TabPane>
						</Tabs>
					</ProjectTranscriptScrollProvider>
				</Col>
				{
					activeTab !== '3' && (user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
						<Col flex="304px" style={{ height: '100%', overflowY: 'auto' }}>
							<Input
								placeholder="Search"
								suffix={<SearchOutlined />}
								style={{ marginBottom: '14px' }}
								onKeyDown={filterConstructs}
							/>
							<SidebarTranscripts
								constructs={constructFilter !== '' ? projectConstructs.filter(construct => construct.name.toLowerCase().includes(constructFilter.toLowerCase())).filter(construct => !construct.disabled) : projectConstructs.filter(construct => !construct.disabled)}
								annotatorCallback={annotatorCallback}
								annotateSelected={annotateSelected}
								setAnnotateSelected={setAnnotateSelected}
								paragraphSelected={paragraphSelected}
								setIsAnnotateRemoved={setIsAnnotateRemoved}
								projectId={projectId}
								transcriptId={transcriptId}
								usedConstructsInExcerpts={usedConstructsInExcerpts}
								setUsedConstructsInExcerpts={setUsedConstructsInExcerpts}
								loading={loading}
							/>
						</Col>
					)
				}
			</Row>
		</div>
	);
};

export default ProjectTranscript;
