import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

export const Auth0ProviderWithNavigate : React.FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();

	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

	const onRedirectCallback = (appState?: AppState) => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	if (!(domain && clientId)) {
		return null;
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={
				{
					redirect_uri: window.location.origin,
					audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				}
			}
			onRedirectCallback={onRedirectCallback}
		>
			{children}
		</Auth0Provider>

	);
};
