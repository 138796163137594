import './DashboardConstructs.less';
import { Select, Space, Typography } from 'antd';
import ConstructTags from '../ConstructTags/ConstructTags';
import React from 'react';

const DashboardConstructs = ({ constructsData, industries, title, periods, handleSelectIndustry, handleSelectPeriod }) => {
	const { Text } = Typography;
	const { Option } = Select;

	return (
		<div className="latest-constructs">
			<div className="latest-header">
				<Space direction="vertical">
					<Text style={{ lineHeight: '18px', fontWeight: '600' }}>{title}</Text>

					<div>
						<Space style={{ marginRight: '25px' }}>
							<Text >Industry</Text>
							<Select
								placeholder="All"
								// allowClear
								mode="multiple"
								className='select industry'
								onChange={value => handleSelectIndustry(value)}
								// onClear={() => handleSelectIndustry(undefined)}
							>
								{industries.map(industry => (
									<Option value={industry.key} key={industry.key}>{industry.name}</Option>
								))}
							</Select>
						</Space>
						{periods
							?
							<Space>
								<Text> Period </Text>
								<Select defaultValue={1} className='select period' onChange={value => handleSelectPeriod(value)}>
									{periods.map((period, index) => (
										<Option value={period.value} key={index}>{period.name}</Option>
									))}
								</Select>
							</Space>
							: null
						}
					</div>
				</Space>
			</div>
			<div className="latest-content">
				{constructsData && constructsData.map((constructType, index) => (
					<div className="content-row" key={index}>
						<div className="content-tag">
							<ConstructTags label={constructType.name} color={constructType.color} active={true} />
						</div>
						{constructType.constructs.map((construct, index) => (
							<div className="content-tag" key={index}>
								<ConstructTags label={construct.name} color={constructType.color} />
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default DashboardConstructs;
