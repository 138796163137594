import axios from 'axios';
import BaseService from '../BaseService';
import Configuration from '../ServiceConfig';
import { Excerpt } from '../../interfaces/Excerpt';

class ProjectTranscriptService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	constructor(projectId: number, transcriptId: number) {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}projects/${projectId}/transcripts/${transcriptId}/`;
		this.token = localStorage.getItem('token');
	}

	async createExcerpt(paragraphId: number, excerpt: Excerpt): Promise<number> {
		return axios
			.post(`${this.path}paragraphs/${paragraphId}/excerpts`, excerpt, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async updateExcerpt(paragraphId: number, excerptId: number, excerpt: Excerpt): Promise<void> {
		return axios
			.patch(`${this.path}paragraphs/${paragraphId}/excerpts/${excerptId}`, excerpt, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async removeExcerpt(paragraphId: number, excerptId: number): Promise<void> {
		return axios
			.delete(`${this.path}paragraphs/${paragraphId}/excerpts/${excerptId}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async addLinks(items: { source: { id: number }, target: { id: number } }[]) {
		const payload = items;

		return axios
			.post(`${this.path}links`, payload, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default ProjectTranscriptService;
