import './NewTypes.less';

import { Button, Form, Input, Layout, Spin, Tooltip, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { Fragment, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import CommonForm from '../../components/CommonForm/CommonForm';
import { ErrorContext } from '../../providers/ErrorProvider';
import ProjectTypesService from '../../services/Project/ProjectTypesService';
import { ROUTES } from '../../constants/routes-path';

const NewTypes = () => {
	const [counter, setCounter] = useState(1);
	const [fields, setFields] = useState([
		{
			typeOption: '',
			id: 0,
		},
	]);
	const [loading, setLoading] = React.useState(false);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { Content } = Layout;
	const { projectId } = useParams();
	const { setError } = React.useContext(ErrorContext);
	const { Text } = Typography;
	const typesService = new ProjectTypesService(projectId, setError);

	const addField = () => {
		setCounter(counter + 1);
		setFields([
			...fields,
			{
				id: counter,
			},
		]);
	};

	const backToTypes = () => navigate(ROUTES.PROJECT_TYPES(projectId), {replace: true});

	const onSubmit = async values => {
		setLoading(true);

		const inputs = Object.values(values);
		let name = '';
		const typeOptions = [];

		inputs.forEach(input => {
			if (typeof input === 'object') {
				typeOptions.push({
					name: input.typeOption,
				});
			} else {
				name = input;
			}
		});

		const newType = {
			name,
			options: typeOptions,
			project: projectId,
		};

		try {
			await typesService.create(newType);
			backToTypes();
			setLoading(false);
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const removeField = index => {
		const toBeRemoved = fields;
		const remainigFields = toBeRemoved.filter(field => field.id !== index);

		setFields(remainigFields);
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CommonForm
					title="Create new type"
					primaryButton="Create type"
					secondClass="new-type"
					titleClass="new-type"
					onCancel={backToTypes}
					onSubmit={onSubmit}
					form={form}
					disabled={loading}
				>
					<Form.Item
						className="edit-type-name"
						name="type"
						label="Type name"
						rules={[{ required: true, message: 'Name is required' }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Fragment>
						{fields.length !== 0 ? <Text className="type-options">Type options</Text> : null}
						{fields.map(field => (
							<div className="option-input" key={5}>
								<Form.Item
									className="type-option-item"
									name={[field.id, 'typeOption']}
									rules={[
										{ required: true, message: 'Option is required' },
										{ min: 2 },
										{ max: 50 },
									]}
								>
									<Input disabled={loading} />
								</Form.Item>
								<Tooltip placement="top" title="Remove option">
									<DeleteOutlined
										style={{ color: '#1991EB' }}
										onClick={() => {
											removeField(field.id);
										}}
										disabled={loading}
									/>
								</Tooltip>
							</div>
						))}
						<Button
							className="another-button types"
							onClick={() => addField()}
							type="link"
							icon={<PlusOutlined />}
							disabled={loading}
						>
							{fields.length !== 0 ? (
								<span>Add another option</span>
							) : (
								<span>Add a type option</span>
							)}
						</Button>
					</Fragment>
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default NewTypes;
