import { useEffect, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

const useUnsavedChangesWarning = (message = 'Wait, You have unsaved changes?') => {
	const [isDirty, setDirty] = useState(false);

	unstable_usePrompt({
		message: message,
		when: ({ currentLocation, nextLocation }) => isDirty &&
		currentLocation.pathname !== nextLocation.pathname,
	});

	useEffect(() => {
		// Detecting browser closing
		window.onbeforeunload = isDirty
			? () => isDirty && !!message
			: null;

		return () => {
			window.removeEventListener('beforeunload', () => {
				console.log();
			});
		};
	}, [isDirty, message]);

	const onDirty = () => setDirty(true);
	const onPristine = () => setDirty(false);

	return { onDirty, onPristine };
};

export default useUnsavedChangesWarning;
