import 'react-color-palette/css';
import { ColorPicker, IColor, useColor } from 'react-color-palette';
import React from 'react';

interface ColorPalettePickerProps {
	initialColor: string;
	onChangeComplete: (color: IColor) => void;
}

const ColorPalettePicker: React.FC<ColorPalettePickerProps> = ({ initialColor, onChangeComplete }) => {
	console.log(initialColor);
	const [color, setColor] = useColor(initialColor);
	const [, updateState] = React.useState<unknown>();
	const forceUpdate = React.useCallback(() => updateState({}), []);

	React.useEffect(() => {
		forceUpdate();
	}, [initialColor]);

	const onChange = (newColor: IColor) => {
		setColor(newColor);
		onChangeComplete(newColor);
	};

	return (
		<div style={{ width: 250 }}>
			<ColorPicker hideInput={['hsv']} hideAlpha height={200} color={color} onChange={onChange} />
		</div>
	);
};

export default ColorPalettePicker;
