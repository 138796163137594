import './ProjectAddConstructs.less';
import { Col, Form, Input, Row, Select, Table, Tag, Tooltip, Typography } from 'antd';
import { ColumnType, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { DeleteOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { ConstructAddListItem } from '../../interfaces/Construct';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { HighlightedText } from '../../components';
import setTagColor from '../../utils/setTagColor';
import SidebarConstructs from '../../components/SidebarConstructs/SidebarConstructs';
import { Sorter } from '../../interfaces/Api';
import { useParams } from 'react-router-dom';
import useProjectAddConstructsController from './useProjectAddConstructsController';

const ProjectAddConstructs: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const {
		constructs,
		selectedConstructs,
		constructTypes,
		languages,
		getConstructsData,
		toggleSelectedConstruct,
		removeSelectedConstruct,
		onAddSelected,
		reload,
		totalCount,
		loading,
		getProjectData,
	} = useProjectAddConstructsController(projectId||'');

	const [filters, setFilters] = useState({ languageCode: [] as string[], name: '', type: [] as string[], description: '', includeUsage: 'true' });
	const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION);
	const [sorter, setSorter] = useState<Sorter | null>(null);

	const { Text } = Typography;
	const { Option } = Select;

	useEffect(() => {
		getConstructsData(filters, sorter, pagination);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination, reload]);

	useEffect(() => {
		getProjectData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const actions = (item: ConstructAddListItem) => {
		return (
			<div>
				{item.isSelected ? (
					<Tooltip placement="top" title="Delete construct">
						<DeleteOutlined
							style={{ color: '#1991EB' }}
							onClick={() => {
								toggleSelectedConstruct(item);
							}}
						/>
					</Tooltip>
				) : (
					<Tooltip placement="top" title="Add construct">
						<PlusCircleOutlined
							style={{ color: '#1991EB' }}
							onClick={() => {
								toggleSelectedConstruct(item);
							}}
						/>
					</Tooltip>
				)}
			</div>
		);
	};

	const displayRender = (item: ConstructAddListItem) => <a><HighlightedText searchWord={filters.name} text={item.name} /></a>;

	const displayLanguage = (item: ConstructAddListItem) => <p>{item.language.name}</p>;

	const displayDescription = (item: ConstructAddListItem) => <a>
		<HighlightedText searchWord={filters.description} text={item.description || ''} />
	</a>;

	const displayRenderColor = (item: ConstructAddListItem) => (
		<Tag color={setTagColor(item.type?.id)}>{item.type?.value}</Tag>
	);

	const displayUsage = (item: ConstructAddListItem) => (
		<div className='construct-usage-container'>
			<div className='construct-usage' >{item.usage}</div>
		</div>
	);

	const columns: ColumnType<ConstructAddListItem>[] = [
		{
			title: 'Construct',
			dataIndex: 'name',
			render: (_, item) => displayRender(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Construct type',
			dataIndex: 'type',
			render: (_, item) => displayRenderColor(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Definition',
			dataIndex: 'description',
			render: (_, item) => displayDescription(item),
		},
		{
			title: 'Language',
			dataIndex: 'language',
			render: (_, item) => displayLanguage(item),
			sorter: true,
		},
		{
			title: 'Usage',
			dataIndex: 'usage',
			render: (_, item) => displayUsage(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
			align: 'center',
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'x',
			render: element => actions(element),
		},
	];

	const handleTableChange = (
		tablePagination: TablePaginationConfig,
		tableSorter: SorterResult<ConstructAddListItem>
	) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField: { [key: string]: string } = {
				name: 'name',
				type: 'type',
				usage: 'usage',
				language: 'languageCode',
			};

			const mapSorterOrder: { [key: string]: string } = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(null);
			} else {
				setSorter({
					sort: `${mapSorterField[tableSorter.field.toString()]}:${mapSorterOrder[tableSorter.order]}`,
				});
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	const filterByName = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setFilters({ ...filters, name: event.currentTarget.value, description: event.currentTarget.value });
		}
	};

	return (
		<div className="ProjectAddConstructs">
			<div className="container">
				<div className="constructs-container">
					<Col>
						<div className="constructs-filter">
							<Form layout="vertical">
								<Row className="constructs-filter" gutter={20}>
									<Col span={10}>
										<Text>Search on global library</Text>
										<Input
											placeholder="All"
											suffix={<SearchOutlined />}
											onKeyDown={filterByName}
											style={{ marginTop: '8px' }}
										/>
									</Col>
									<Col span={8}>
										<Text>Construct types</Text>
										<Select
											className="construct-types-select"
											placeholder="All"
											onChange={value => {
												setFilters({ ...filters, type: value as string[] });
											}}
											loading={loading}
											mode="multiple"
											allowClear
										>
											{constructTypes.map(construct => (
												<Option value={construct.id} key={construct.id}>
													{construct.value}
												</Option>
											))}
										</Select>
									</Col>
									<Col lg={5}>
										<Form.Item label="Filter by Language" name="constructLanguage">
											<Select
												placeholder="All"
												onChange={value => {
													setFilters({ ...filters, languageCode: value as string[] });
												}}
												loading={loading}
												mode="multiple"
											>
												{languages.map(language => (
													<Option value={language.code} key={language.code}>
														{language.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
						<div className="constructs-list">
							<Table
								columns={columns}
								dataSource={constructs}
								pagination={{
									total: totalCount,
									showQuickJumper: true,
									showSizeChanger: true,
									...pagination,
								}}
								onChange={(pagination, _, sorter) =>
									handleTableChange(pagination, sorter as SorterResult<ConstructAddListItem>)
								}
								loading={loading}
							/>
						</div>
					</Col>
				</div>
				<div className="sidebar">
					<Col>
						<div className="sidebar-constructs">
							<div className="title-container">
								<Text strong className="text">
									Selected Constructs for this projects
								</Text>
							</div>
							<SidebarConstructs
								constructTypes={constructTypes}
								constructList={selectedConstructs}
								removeSelectedConstruct={removeSelectedConstruct}
								onAddSelected={onAddSelected}
							/>
						</div>
					</Col>
				</div>
			</div>
		</div>
	);
};

export default ProjectAddConstructs;
