import axios from 'axios';
import BaseService from '../BaseService';
import Configuration from '../ServiceConfig';
import { SegmentBreakdown } from '../../interfaces/Segment';

interface ApiResult {
	count: number,
	segmentsBreakdown: SegmentBreakdown[]
}

class ProjectSegmentsBreakdownService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;
	projectId: number;

	constructor(projectId: number) {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}projects/${projectId}/segments-breakdown/`;
		this.token = localStorage.getItem('token');
		this.projectId = projectId;
	}

	async get(sourceConstructId:number, targetConstructId:number) : Promise<ApiResult> {
		return axios
			.get(`${this.path}`, {
				params: {sourceConstructId, targetConstructId},
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default ProjectSegmentsBreakdownService;
