import { Button, Divider, Form, Input, Layout, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CommonForm from '../../components/CommonForm/CommonForm';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DeleteOutlined } from '@ant-design/icons';
import { ErrorContext } from '../../providers/ErrorProvider';
import IndustriesService from '../../services/IndustriesService';
import { ROUTES } from '../../constants/routes-path';

const IndustryDetails = () => {
	const [industryName, setIndustryName] = useState('');
	const [loading, setLoading] = React.useState(false);
	const [showModal, setShowModal] = React.useState(false);
	const { TextArea } = Input;
	const { Content } = Layout;
	const { Text } = Typography;
	const navigate = useNavigate();
	const { setError } = React.useContext(ErrorContext);
	const service = new IndustriesService();
	const [form] = Form.useForm();
	const { id } = useParams();
	const routes = [
		{ path: '/', breadcrumbName: 'Admin' },
		{ path: '/industries', breadcrumbName: 'Industries' },
		{ path: '', breadcrumbName: industryName },
	];

	const backToIndustries = () => {
		navigate(ROUTES.INDUSTRIES, {replace: true});
	};

	const updateIndustry = async values => {
		setLoading(true);

		try {
			await service.update(id, values);
			setLoading(false);
			backToIndustries();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const toggleShowModal = () => setShowModal(!showModal);

	const deleteIndustry = async () => {
		setLoading(true);
		toggleShowModal();

		try {
			await service.delete(id);
			setLoading(false);
			backToIndustries();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const getModalDescription = () => [
		<Text key={1}> Are you sure you want to delete the </Text>,
		<Text strong key={2}>
			industry {industryName}?
		</Text>,
	];

	useEffect(() => {
		setLoading(true);
		service
			.get(id)
			.then(data => {
				setIndustryName(data.name);
				form.setFieldsValue({
					name: data.name,
					description: data.description,
				});

				setLoading(false);
			})
			.catch(err => setError(err))
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Industry details"
					primaryButton="Save industry"
					onSubmit={updateIndustry}
					onCancel={backToIndustries}
					form={form}
				>
					<Form.Item
						className="file-name"
						name="name"
						label="Industry name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea" rows={4} />
					</Form.Item>
					<Divider className="divider" />
					<Button
						className="delete-button"
						type="link"
						icon={<DeleteOutlined />}
						onClick={() => toggleShowModal()}
						disabled={loading}
					>
						Delete industry
					</Button>
					{loading && (
						<Spin style={{ display: 'flex', justifyContent: 'center' }} />
					)}
					<ConfirmModal
						sideSeparation="0px"
						visible={showModal}
						cancelTitle="No, cancel"
						okTitle="Yes, delete industry"
						handleOk={deleteIndustry}
						handleCancel={toggleShowModal}
						description={getModalDescription()}
					/>
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default IndustryDetails;
