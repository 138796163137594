import { Button, Col, DatePicker, Form, Input, Row, Select, Table, Tag, Tooltip, Typography } from 'antd';
import { ColumnType, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { DeleteOutlined, PicLeftOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalystRole } from '../../interfaces/Analyst';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { Construct } from '../../interfaces/Construct';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import moment from 'moment';
import { ROUTES } from '../../constants/routes-path';
import setTagColor from '../../utils/setTagColor';
import { Sorter } from '../../interfaces/Api';
import useProjectConstructsController from './useProjectConstructsController';
import { UserContext } from '../../providers/UserProvider';

const { RangePicker } = DatePicker;

const ProjectConstructs: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const { constructs, constructsType, loading, getProject, getConstructs, deleteConstruct, reload, totalCount } =
		useProjectConstructsController((projectId||'').toString());

	const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION);
	const [filters, setFilters] = useState({
		name: '',
		description: '',
		constructType: '',
		type: [] as string[],
		dateAdded: '',
		fromCreatedAt: '',
		toCreatedAt: '',
	});
	const [sorter, setSorter] = useState<Sorter | null>(null);
	const [selected, setSelected] = useState<Construct | null>(null);
	const [showModal, setShowModal] = useState(false);

	const { Option } = Select;

	const { Text } = Typography;
	const navigate = useNavigate();

	const { user } = useContext(UserContext);

	const dateFormat = 'YYYY/MM/DD';

	const displayRender = (text: string) => <a>{text}</a>;

	const displayRenderColor = (item: Construct) => <Tag color={setTagColor(item.type?.id)}>{item.type?.value}</Tag>;

	const toggleShowModal = (item: Construct | null) => {
		setShowModal(!showModal);

		if (item && item.id) {
			setSelected(item);
		}
	};

	const actions = (item: Construct) => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Delete construct">
					<DeleteOutlined
						style={{ color: '#1991EB', marginLeft: 16 }}
						onClick={() => toggleShowModal(item)}
					/>
				</Tooltip>
			</div>
		);
	};

	const columns: ColumnType<Construct>[] = [
		{
			title: 'Construct',
			dataIndex: 'name',
			render: text => displayRender(text),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Construct type',
			dataIndex: 'type',
			render: (_, item) => displayRenderColor(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Date added',
			dataIndex: 'createdAt',
			render: value => <span>{moment(value).format(dateFormat)}</span>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Definition',
			dataIndex: 'description',
		},
	];

	if (user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) {
		columns.push({
			title: 'Actions',
			dataIndex: '',
			align: 'left',
			key: 'x',
			render: element => actions(element),
		});
	}

	const resetSelected = () => {
		setSelected(null);
	};

	const getSelectedName = () => {
		return selected && selected.name ? `construct ${selected.name}?` : 'construct?';
	};

	const getModalDescription = () => {
		return (
			<>
				<Text key="description">Are you sure you want to delete the </Text>,
				<Text strong key="name">
					{getSelectedName()}
				</Text>
			</>
		);
	};

	const filterByName = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setFilters({ ...filters, name: event.currentTarget.value, description: event.currentTarget.value });
		}
	};

	const handleTableChange = (tablePagination: TablePaginationConfig, tableSorter: SorterResult<Construct>) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField: { [key: string]: string } = {
				createdAt: 'createdAt',
				name: 'name',
				type: 'type',
			};
			const mapSorterOrder: { [key: string]: string } = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(null);
			} else {
				setSorter({
					sort: `${mapSorterField[tableSorter.field.toString()]}:${mapSorterOrder[tableSorter.order]}`,
				});
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	useEffect(() => {
		getProject();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getConstructs(filters, sorter, pagination);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination, reload]);

	return (
		<div className="ProjectConstructs">
			<div className="filters-container">
				<Form layout="vertical">
					<Row gutter={25}>
						<Col lg={5}>
							<Form.Item label="Construct" name="construct">
								<Input placeholder="All" onKeyDown={filterByName} suffix={<SearchOutlined />} />
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Construct Type" name="constructType">
								<Select
									placeholder="All"
									onChange={value => setFilters({ ...filters, type: value as string[] })}
									loading={loading}
									mode="multiple"
								>
									{constructsType.map(construct => (
										<Option value={construct.id} key={construct.id}>
											{construct.value}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col flex="auto">
							<Form.Item label="Date added" name="dateAdded">
								<RangePicker
									format={dateFormat}
									onChange={(moment, dateString) =>
										setFilters({
											...filters,
											fromCreatedAt: dateString[0],
											toCreatedAt: dateString[1],
										})
									}
								/>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label=" " name="addConstruct" style={{ alignItems: 'flex-end' }}>
								{(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN ||
									user?.role === AnalystRole.ANALYST) && (
									<Button
										type="primary"
										onClick={() => navigate(ROUTES.PROJECT_ADD_CONSTRUCTS(projectId))}
										icon={<PicLeftOutlined />}
									>
										Add Constructs
									</Button>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={constructs}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={(pagination, _, sorter) =>
						handleTableChange(pagination, sorter as SorterResult<Construct>)
					}
					loading={loading}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle="Yes, delete construct"
				handleOk={() => {
					deleteConstruct(selected);
					toggleShowModal(null);
				}}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
			/>
		</div>
	);
};

export default ProjectConstructs;
