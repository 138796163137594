import './ProjectSegments.less';
import { Button, Divider, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {Link, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import PROJECT_ROUTES from '../../constants/project-constants';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectSegmentsService from '../../services/Project/ProjectSegmentsService';
import ProjectsService from '../../services/ProjectsService';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';

const ProjectSegments = () => {
	const [loading, setLoading] = useState(false);
	const [segments, setSegments] = useState([]);
	const [selected, setSelected] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const { setError } = useContext(ErrorContext);
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);
	const { projectId } = useParams();
	const { Text } = Typography;
	const segmentsService = new ProjectSegmentsService(projectId);
	const navigate = useNavigate();
	const projectsService = new ProjectsService();
	const [totalCount, setTotalCount] = useState(0);
	const [sorter, setSorter] = useState(undefined);
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const { user } = useContext(UserContext);

	const toggleShowModal = (item) => {
		setShowModal(!showModal);

		if (item && item.key) {
			setSelected(item);
		}
	};

	const editSegment = item => navigate(ROUTES.PROJECT_SEGMENTS_DETAILS(projectId, item.key), {replace: true});

	const actions = (item) => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Edit segment">
					<EditOutlined style={{ color: '#1991EB' }} onClick={() => editSegment(item)} />
				</Tooltip>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				<Tooltip placement="top" title="Delete segment">
					<DeleteOutlined style={{ color: '#1991EB' }} onClick={() => toggleShowModal(item)} />
				</Tooltip>
			</div>
		);
	};

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				name: 'name',
				transcript: 'transcript',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(undefined);
			} else {
				setSorter({ sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}` });
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	const renderText = item => <Link to={ROUTES.PROJECT_SEGMENTS_DETAILS(projectId, item.key)}>{item.name}</Link>;

	const columns = [
		{
			title: 'Segments',
			dataIndex: 'name',
			render: (_, item) => renderText(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Description',
			dataIndex: 'description',
		},
		{
			title: 'Transcripts',
			dataIndex: 'transcripts',
			align: 'center',
		},
	];

	if (user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) {
		columns.push({
			title: 'Actions',
			dataIndex: '',
			align: 'right',
			key: 'x',
			render: (element) => actions(element),
		});
	}

	const getSegments = () => {
		setLoading(true);

		segmentsService
			.getAll(undefined, sorter, pagination.pageSize, (pagination.current - 1) * pagination.pageSize)
			.then(result => {
				if (result !== undefined) {
					setSegments(result.data);
					setTotalCount(result.totalCount);
				}
			}).catch(err => {
				setError(err.toString());
			}).finally(() => {
				setLoading(false);
			});
	};

	const resetSelected = () => setSelected(null);

	const getSelectedName = () => selected && selected.name ? `${selected.name} segment?` : 'segment?';

	const getModalDescription = () => [<Text key={1} >Are you sure you want to delete the </Text>, <Text strong key={2}>{getSelectedName()}</Text>];

	const deleteSegment = async () => {
		setLoading(true);
		toggleShowModal();

		try {
			await segmentsService.delete(selected.key);
			getSegments();
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		projectsService.get(projectId).then(data => {
			getSegments();
			const actualRoutes = [
				{
					path: 'projects',
					breadcrumbName: 'Projects',
				},
				{
					path: projectId,
					breadcrumbName: data.name,
				},
				{
					path: 'segments',
					breadcrumbName: 'Segments',
				},
			];

			setRoutes(actualRoutes);
			setActive(PROJECT_ROUTES.SEGMENTS);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getSegments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sorter, pagination]);

	const onClick = () => {
		navigate(ROUTES.PROJECT_SEGMENTS_NEW_SEGMENTS(projectId));
	};

	return (
		<div className="ProjectSegments">
			<div className="filters-container" style={{display: 'flex', justifyContent: 'flex-end'}}>
				{
					(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
						<Button type="primary" icon={<PlusOutlined />} onClick={onClick}>
							Create new segment
						</Button>
					)
				}
			</div>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={segments}
					loading={loading}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
				/>
			</div>
			<ConfirmModal afterClose={resetSelected} visible={showModal} cancelTitle="No, cancel" okTitle="Yes, delete segment" handleOk={deleteSegment} handleCancel={toggleShowModal} description={getModalDescription()} />
		</div>
	);
};

export default ProjectSegments;
