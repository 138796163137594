import { Form, FormInstance } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConstructsService from '../../services/ConstructsService';
import { ConstructType } from '../../interfaces/Construct';
import { ErrorConstructAlreadyExist } from '../../components';
import { ErrorContext } from '../../providers/ErrorProvider';
import { Industry } from '../../interfaces/Industry';
import { Language } from '../../interfaces/Language';
import LanguageService from '../../services/LanguageService';
import { Project } from '../../interfaces/Project';
import { ROUTES } from '../../constants/routes-path';

interface UseConstructDetailsControllerReturn {
	constructsType: ConstructType[];
	languages: Language[];
	activeValue: number;
	setActiveValue: (constructTypeId: number) => void;
	loading: boolean;
	backToConstructs: () => void;
	form: FormInstance
	updateConstruct: (construct: FormValues) => void;
	relatedIndustries: {
		industry: Industry;
		count: number;
	}[],
	relatedProjects: {
		project: Project;
		count: number;
	}[]
	constructName: string,
}

interface FormValues {
	type: number,
	name: string,
	description: string,
	language: string
}

const useConstructDetailsController = (): UseConstructDetailsControllerReturn => {
	const { id } = useParams<{id:string}>();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const constructsService = new ConstructsService();
	const languageService = new LanguageService();
	const [languages, setLanguages] = useState<Language[]>([]);
	const [constructsType] = useState(constructsService.getAllConstructsType());
	const [loading, setLoading] = useState<boolean>(false);
	const { setError } = useContext(ErrorContext);
	const [activeValue, setActiveValue] = useState<number>(1);
	const [relatedIndustries, setRelatedIndustries] = useState<{
		industry: Industry;
		count: number;
	}[]>([]);
	const [relatedProjects, setRelatedProjects] = useState<{
		project: Project;
		count: number;
	}[]>([]);
	const [constructName, setConstructName] = useState('');

	useEffect( () => {
		languageService.getLanguages().then((response) => {
			setLanguages(response);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getRelatedIndustries = (id:string) => {
		constructsService
			.getRelatedIndustries(+id)
			.then((result) => {
				if (result !== undefined) {
					setRelatedIndustries(result);
				}
			});
	};

	const getRelatedProjects = (id: string) => {
		constructsService
			.getRelatedProjects(+id)
			.then((result) => {
				if (result !== undefined) {
					setRelatedProjects(result);
				}
			});
	};

	useEffect(() => {
		setLoading(true);

		if (id) {
			constructsService.get(+id).then(data => {
				setConstructName(data.name);
				setActiveValue(data.type);
				getRelatedProjects(id);
				getRelatedIndustries(id);
				form.setFieldsValue({
					type: data.type,
					name: data.name,
					description: data.description,
					language: data.language.code,
				});
			}).catch((err) => {
				setError(err.toString());
			}).finally(() => {
				setLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateConstruct = async (values: FormValues) => {
		setLoading(true);

		let totalCount = 0;
		let construct = null;

		try {
			const constructsResult = await constructsService.getAll(
				{ name: values.name, isNameExact: 'true' }, null, 1, 0);

			if (constructsResult) {
				totalCount = constructsResult.totalCount;
				construct = constructsResult.data[0];
			}

		} catch (error) {
			setError(error);
			setLoading(false);

			return;
		}

		if (id && construct && totalCount >= 1 && construct.id !== +id) {
			setError(<ErrorConstructAlreadyExist constructId={construct.id} name={construct.name} />);
			setLoading(false);
			form.setFields([
				{
					name: 'name',
					errors: [''],
				},
			]);

			return;
		}

		if (id) {
			try {

				const selectedLanguage = languages.find(element => values.language === element.code);

				if (selectedLanguage)
				{
					await constructsService.update(+id, {
						...values, type: ConstructsService.getConstructTypeById(activeValue) || ConstructsService.getConstructTypeById(1), language: selectedLanguage, id: 0,
					});
				}

				backToConstructs();
			} catch (err) {
				setError(err as string);
			} finally {
				setLoading(false);
			}
		}
	};

	const backToConstructs = () => {
		navigate(ROUTES.CONSTRUCTS);
	};

	return {
		constructsType,
		languages,
		activeValue,
		setActiveValue,
		loading,
		relatedIndustries,
		relatedProjects,
		constructName,
		updateConstruct,
		backToConstructs,
		form,
	};
};

export default useConstructDetailsController;
