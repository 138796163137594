import './HighlightedText.less';
import React, { useMemo } from 'react';

interface HighlightedTextProps{
	text: string,
	searchWord: string
}

const HighlightedText : React.FC<HighlightedTextProps> = ({ text, searchWord }) => {
	const { parts } = useMemo(() => {
		if (!searchWord) {
			return { parts: [text] };
		}

		const regex = new RegExp(`(${searchWord})`, 'ig');
		const parts = text.split(regex);

		return { parts };
	}, [text, searchWord]);

	return (
		<span>
			{parts.map((part, index) =>
				part.toLowerCase() === searchWord.toLowerCase() ? (
					<span key={index} className="highlighted-text">
						{part}
					</span>
				) : (
					<span key={index}>{part}</span>
				)
			)}
		</span>
	);
};

export default HighlightedText;
