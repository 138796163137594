import './DuplicateProjectConfirmationModal.less';

import { Button, Checkbox } from 'antd';
import { AnalystRole } from '../../interfaces/Analyst';
import Modal from 'antd/lib/modal/Modal';
import { Project } from '../../interfaces/Project';
import React from 'react';
import { UserContext } from '../../providers/UserProvider';

interface DuplicateProjectConfirmationModalProps {
	open: boolean
	projectContent: Project,
	objectsToDuplicate: string[]
	onConfirm: () => void
	onCancel: ()=>void
}

const ModalTitle = <b>Review project to duplicate</b>;

interface ProjectContentProps {
	contentTitle: string,
	content: React.ReactNode
}

const ProjectContent: React.FC<ProjectContentProps> = ({ contentTitle, content }) => {
	return <div className='project-content-container'>
		<p className='title'>{contentTitle}</p>
		<div>{content}</div>
	</div>;
};

const CheckboxGroup = Checkbox.Group;

const DuplicateProjectConfirmationModal: React.FC<DuplicateProjectConfirmationModalProps> = ({ open, projectContent, onCancel, onConfirm, objectsToDuplicate }) => {
	const { user } = React.useContext(UserContext);

	return <Modal title={ModalTitle} open={open} className='project-duplicate-modal' onCancel={onCancel} footer={[
		<Button key="back" type="link" onClick={onCancel}>
			Cancel
		</Button>,
		<Button key="submit" type="primary" onClick={onConfirm}>
			Confirm
		</Button>,
	]}>
		<ProjectContent contentTitle='Project Name' content={projectContent.name} />
		<ProjectContent contentTitle='Client' content={ projectContent.client.name } />
		<ProjectContent contentTitle='Description' content={projectContent.description} />
		<ProjectContent contentTitle='Select objects to duplicate' content={
			<>
				<CheckboxGroup className='project-checkbox-selected' disabled defaultValue={objectsToDuplicate} >
					<Checkbox value="segments">Segments</Checkbox>
					<Checkbox value="types">Types</Checkbox>
					<Checkbox value="constructs">Constructs</Checkbox>
					{user?.role === AnalystRole.ADMIN || user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN && <Checkbox value="transcripts">Transcripts and Segments</Checkbox>}
				</CheckboxGroup>
			</>
		} />
	</Modal>;
};

export default DuplicateProjectConfirmationModal;
