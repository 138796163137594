import { ApiConstruct, ConstructType, ConstructWithLanguage } from '../interfaces/Construct';
import { Filters, Sorter } from '../interfaces/Api';
import { Project, ApiProject } from '../interfaces/Project';
import axios from 'axios';
import BaseService from './BaseService';
import Configuration from './ServiceConfig';
import { Industry } from '../interfaces/Industry';
import setTagColor from '../utils/setTagColor';

interface Response {
	data: ConstructWithLanguage[];
	totalCount: number;
}

class ConstructsService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	public constructsTypeMap: { [key: number]: ConstructType } = {};

	private static constructsType: ConstructType[] = [
		{
			id: 5,
			value: 'Attribute',
			color: setTagColor(5),
		},
		{
			id: 4,
			value: 'Functional',
			color: setTagColor(4),
		},
		{
			id: 3,
			value: 'Personal/Social',
			color: setTagColor(3),
		},
		{
			id: 2,
			value: 'Emotional',
			color: setTagColor(2),
		},
		{
			id: 1,
			value: 'Consumer Mindset',
			color: setTagColor(1),
		},
		{
			id: 7,
			value: 'Context',
			color: setTagColor(7),
		},
		{
			id: 6,
			value: 'Behavior',
			color: setTagColor(6),
		},
	];

	constructor() {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}${'constructs/'}`;
		this.token = localStorage.getItem('token');
		ConstructsService.constructsType.forEach(constructType => {
			this.constructsTypeMap[constructType.id] = constructType;
		});
	}

	static getConstructTypeById(id: number | undefined): ConstructType | undefined {
		if (!id) {
			return undefined;
		}

		const constructType = this.constructsType.find(constructType => constructType.id === id);

		return constructType;
	}

	static parseToApiConstruct(item: ConstructWithLanguage): ApiConstruct {
		return {
			id: item.id,
			name: item.name,
			createdAt: item.createdAt,
			description: item.description,
			disabled: item.disabled,
			type: item.type?.id || 0,
			languageCode: item.language.code,
		};
	}

	static parseToProject(item: ApiProject) : Project {
		return {
			key: item.id,
			name: item.name,
			description: item.description,
			client: item.client,
			updatedAt: item.updatedAt,
			status: item.status,
		};
	}

	private parseDataTable(results: any[]): ConstructWithLanguage[] {
		return results.map(object => {
			return {
				id: object.id,
				name: object.name,
				description: object.description,
				createdAt: object.createdAt,
				type: ConstructsService.getConstructTypeById(object.type),
				usage: object.usage,
				language: object.language,
			};
		});
	}

	async getAll(filters: Filters | null, sorter: Sorter | null, limit: number | null = 10, offset: number | null = 0): Promise<Response | void> {
		let params = {};

		if (filters) {
			Object.entries(filters).forEach(([key, value]) => {
				if (value) {
					params = { ...params, [key]: value };
				}
			});
		}

		if (sorter && sorter.sort) {
			params = { ...params, ...sorter };
		}

		params = { ...params, limit, offset };

		return axios
			.get(this.path, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	getAllConstructsType() {
		return ConstructsService.constructsType;
	}

	async get(id: number) {
		return axios
			.get(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getRelatedIndustries(id: number): Promise<{
		industry: Industry;
		count: number;
	}[]> {
		try {
			const relatedIndustries = await axios
				.get(`${this.path}${id}/related-industries`, {
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
				});

			return relatedIndustries.data;
		} catch (err: any) {
			throw this.getErrorMessage(err);
		}
	}

	async getRelatedProjects(id: number): Promise<{
		project: Project;
		count: number;
	}[]> {
		try {
			const response = await axios
				.get(`${this.path}${id}/related-projects`, {
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
				});

			const relatedProjects = response.data as { project: ApiProject; count: number; }[];

			return relatedProjects.map((item: { project: ApiProject; count: number; }) => {
				return {
					project: ConstructsService.parseToProject(item.project),
					count: item.count,
				};
			});
		} catch (err: any) {
			throw this.getErrorMessage(err);
		}
	}

	async create(item: ConstructWithLanguage) {
		return axios
			.post(this.path, ConstructsService.parseToApiConstruct(item), {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async delete(id: number) {
		return axios
			.delete(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async update(id: number, item: ConstructWithLanguage) {
		return axios
			.patch(`${this.path}${id}`, ConstructsService.parseToApiConstruct(item), {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default ConstructsService;
