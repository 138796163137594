import './Industry.less';

import { Button, Col, Divider, Input, Row, Table, Tag, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';

import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import IndustriesService from '../../services/IndustriesService';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

const Industry = () => {
	const [filters, setFilters] = useState({ name: '' });
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const [sorter, setSorter] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [industries, setIndustries] = useState([]);
	const [selected, setSelected] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const navigate = useNavigate();
	const { setError } = useContext(ErrorContext);
	const industriesService = new IndustriesService();
	const { Text } = Typography;
	const routes = [
		{
			path: '/',
			breadcrumbName: 'Admin',
		},
		{
			path: '/industry',
			breadcrumbName: 'Industries',
		},
	];

	const toggleShowModal = item => {
		setShowModal(!showModal);

		if (item && item.key) {
			setSelected(item);
		}
	};

	const goToAddIndustry = () => navigate(ROUTES.NEW_INDUSTRY);

	const goToEditIndustry = id => navigate(ROUTES.INDUSTRY_DETAILS(id));

	const actions = item => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Edit industry">
					<EditOutlined style={{ color: '#1991EB' }} onClick={() => goToEditIndustry(item.key)} />
				</Tooltip>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				<Tooltip placement="top" title="Delete industry">
					<DeleteOutlined style={{ color: '#1991EB' }} onClick={() => toggleShowModal(item)} />
				</Tooltip>
			</div>
		);
	};

	const renderLinkedText = (item) => <a onClick={() => goToEditIndustry(item.key)}>{item.name}</a>;
	const renderTags = tags => (
		<span>
			{tags && tags.length ? (
				tags.map((tag, index) => {
					return <Tag key={index} style={{marginBottom: 6}}>{tag.name.toUpperCase()}</Tag>;
				})
			) : (
				<Text>No latest projects</Text>
			)}
		</span>
	);

	const columns = [
		{
			title: 'Industries',
			dataIndex: 'name',
			render: (_, item) => renderLinkedText(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Latest projects',
			dataIndex: 'latestProjects',
			render: tags => renderTags(tags),
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'x',
			align: 'right',
			render: element => actions(element),
		},
	];

	const getIndustries = () => {
		setLoading(true);

		industriesService
			.getAll(filters, sorter, pagination.pageSize, (pagination.current - 1) * pagination.pageSize)
			.then(result => {
				if (result !== undefined) {
					console.log(result);
					setIndustries(result.data);
					setTotalCount(result.totalCount);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const resetSelected = () => setSelected(null);

	const getSelectedName = () => {
		return selected && selected.name ? `industry ${selected.name}?` : 'unknown?';
	};

	const getModalDescription = () => [
		<Text key={1}> Are you sure you want to delete the </Text>,
		<Text strong key={2}>
			{getSelectedName()}
		</Text>,
	];

	const deleteIndustry = async () => {
		setLoading(true);
		toggleShowModal();

		try {
			await industriesService.delete(selected.key);
			getIndustries();
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				name: 'name',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(undefined);
			} else {
				setSorter({ sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}` });
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	useEffect(() => {
		getIndustries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination]);

	const filterByName = event => {
		if (event.keyCode === 13) {
			setFilters({ ...filters, name: event.target.value });
			setPagination(DEFAULT_PAGINATION);
		}
	};

	return (
		<div className="Industry">
			<Row justify="space-between">
				<Col>
					<CustomBreadcrumb routes={routes} />
				</Col>
				<Col>
					<Row gutter={22} style={{ marginTop: '6px' }}>
						<Col style={{ width: '354px' }}>
							<Input
								width="332"
								placeholder="Search"
								suffix={<SearchOutlined />}
								onKeyDown={filterByName}
							/>
						</Col>
						<Col>
							<Button type="primary" onClick={goToAddIndustry} icon={<PlusOutlined />}>
								Add new industry
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={industries}
					loading={loading}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle="Yes, delete industry"
				handleOk={deleteIndustry}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
			/>
		</div>
	);
};

export default Industry;
