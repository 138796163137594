import { Construct, ConstructType, ConstructWithLanguage } from '../../interfaces/Construct';
import { Form, FormInstance } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import ConstructsService from '../../services/ConstructsService';
import { ErrorConstructAlreadyExist } from '../../components';
import { ErrorContext } from '../../providers/ErrorProvider';
import { Language } from '../../interfaces/Language';
import LanguageService from '../../services/LanguageService';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

interface FormValues {
	type: number,
	name: string,
	description: string,
	language: string
}

interface UseNewConstructControllerReturn {
	constructsType: ConstructType[];
	languages: Language[];
	activeValue: number;
	setActiveValue: (constructTypeId: number) => void;
	loading: boolean;
	addConstruct: (construct: FormValues) => void;
	backToConstructs: () => void;
	form: FormInstance
}

const useNewConstructController = (): UseNewConstructControllerReturn => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const languageService = new LanguageService();
	const constructsService = new ConstructsService();
	const [loading, setLoading] = useState<boolean>(false);
	const [languages, setLanguages] = useState<Language[]>([]);
	const { setError } = useContext(ErrorContext);
	const constructsType = constructsService.getAllConstructsType();
	const [activeValue, setActiveValue] = useState<number>(1);

	useEffect( () => {
		languageService.getLanguages().then((response) => {
			setLanguages(response);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addConstruct = async (values: FormValues) => {
		setLoading(true);

		let totalCount = 0;
		let construct : Construct|null = null;

		try {
			const constructsResult = await constructsService.getAll(
				{ name: values.name, isNameExact: 'true' }, null, 1, 0);

			if (constructsResult) {
				totalCount = constructsResult.totalCount;
				construct = constructsResult.data[0];
			}

		} catch (error) {
			setError(error as string);
			setLoading(false);

			return;
		}

		if (construct && totalCount >= 1) {
			setError(<ErrorConstructAlreadyExist constructId={construct.id} name={construct.name} />);
			setLoading(false);
			form.setFields([
				{
					name: 'name',
					errors: [''],
				},
			]);

			return;
		}

		try {

			const selectedLanguage = languages.find(element => values.language === element.code);

			if (selectedLanguage) {
				const newConstruct: ConstructWithLanguage = {
					id: 0,
					name: values.name,
					type: ConstructsService.getConstructTypeById(activeValue),
					description: values.description,
					language: selectedLanguage,

				};

				await constructsService.create(newConstruct);
			}

			setLoading(false);
			backToConstructs();
		} catch (error) {
			setError(error as string);
			setLoading(false);
		}
	};

	const backToConstructs = () => {
		navigate(ROUTES.CONSTRUCTS, {replace: true});
	};

	return {
		constructsType,
		languages,
		activeValue,
		setActiveValue,
		loading,
		addConstruct,
		backToConstructs,
		form,
	};
};

export default useNewConstructController;
