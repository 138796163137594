/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Layout, Menu, MenuProps } from 'antd';
import {
	FileDoneOutlined,
	LayoutOutlined,
	// QuestionCircleOutlined,
	ReconciliationOutlined,
	SafetyCertificateOutlined
} from '@ant-design/icons';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import { ErrorContext } from '../../providers/ErrorProvider';
import Header from '../../components/Header/Header';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

interface MenuItem{
	key: string,
	icon?: React.ReactNode,
	label: string,
	url?: string,
	permission?: number[]
	children?: MenuItem[]
}

type MenuPropsAntd = Required<MenuProps>['items'][number]

const menuItems: MenuItem[] = [
	{
		key: ROUTES.DASHBOARD,
		icon: <LayoutOutlined />,
		label: 'Dashboard',
		url: ROUTES.DASHBOARD,
	},
	{
		key: 'sub1',
		icon: <SafetyCertificateOutlined />,
		label: 'Admin',
		permission: [AnalystRole.ADMIN, AnalystRole.GLOBAL_PARTNER_ADMIN],
		children: [
			{
				key: ROUTES.PARTNERS,
				label: 'Partners',
				url: ROUTES.PARTNERS,
				permission: [AnalystRole.ADMIN],
			},
			{
				key: ROUTES.ANALYSTS,
				label: 'Analysts',
				url: ROUTES.ANALYSTS,
			},
			{
				key: ROUTES.CLIENTS,
				label: 'Clients',
				url: ROUTES.CLIENTS,
			},
			{
				key: ROUTES.INDUSTRIES,
				label: 'Industries',
				url: ROUTES.INDUSTRIES,
				permission: [AnalystRole.ADMIN],
			},
		],
	},
	{
		key: ROUTES.PROJECTS,
		icon: <FileDoneOutlined />,
		label: 'Projects',
		url: ROUTES.PROJECTS,
	},
	{
		key: ROUTES.CONSTRUCTS,
		icon: <ReconciliationOutlined />,
		label: 'Constructs',
		url: ROUTES.CONSTRUCTS,
	},

];

const filterMenuItemsByRole = (userRole: number, items: MenuItem[] = menuItems): MenuItem[] => {
	return items
		.filter(item => !item.permission || item.permission.includes(userRole) )
		.map(item => {
			if (item.children) {
				const filteredChildren = filterMenuItemsByRole(userRole, item.children);

				if (filteredChildren.length > 0) {
					return { ...item, children: filteredChildren };
				}

				// OBS: If after filtering a submenu there are no child elements, the submenu will not be displayed
				return null;
			}

			return item;
		})
		.filter(Boolean) as MenuItem[];
};

const parseMenuItemToAntdMenu = (items: MenuItem[]): MenuPropsAntd[] => {
	return items
		.map(item => {
			const antdItem: MenuPropsAntd = {
				key: item.key,
				icon: item.icon,
				label: item.url ? <NavLink to={item.url}>{item.label}</NavLink> : item.label,
			};

			if (item.children) {
				const filteredChildren = parseMenuItemToAntdMenu(item.children);

				return { ...antdItem, children: filteredChildren };
			}

			return antdItem;
		})
		.filter(Boolean) as MenuPropsAntd[];
};

const MainLayout = () => {
	const location = useLocation();
	const { error, setError } = useContext(ErrorContext);
	const { user } = React.useContext(UserContext);

	const menuItems = parseMenuItemToAntdMenu(filterMenuItemsByRole(user?.role));

	const onErrorClose = () => {
		setError('');
	};

	const basePath = location.pathname.split('/').slice(0, 2).join('/');

	return (
		<Layout style={{ height: '100%' }}>
			<Header />
			<Layout style={{ height: '100%' }}>
				<Sider collapsible style={{ height: '100%' }}>
					<Menu
						mode="inline"
						selectedKeys={[basePath]}
						defaultOpenKeys={['sub1']}
						style={{ height: '100%', borderRight: 0 }}
						items={menuItems}
					/>
				</Sider>
				<Layout style={{ padding: location.pathname === ROUTES.DASHBOARD ? '0 1px 24px 6px' : '28px 24px 24px', overflowY: 'auto', overflowX: 'hidden' }}>
					{
						(error.length > 0|| React.isValidElement(error)) && (
							error !== '' && (
								<Alert
									message={error}
									type="error"
									showIcon
									style={{ marginTop: '10px', marginBottom: '10px' }}
									closable
									afterClose={onErrorClose}
								/>
							)
						)
					}
					<Content
						style={{
							padding: 0,
							margin: 0,
							minHeight: 280,
						}}
					>
						<Outlet/>
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
};

export default withAuthenticationRequired(MainLayout); //MainLayout
