import { AnalystRole } from '../interfaces/Analyst';
import axios from 'axios';
import BaseService from './BaseService';
import Configuration from './ServiceConfig';

interface User {
	role: AnalystRole;
	firstName: string;
	lastName: string;
}

class UsersService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	constructor() {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}${'user/'}`;
		this.token = localStorage.getItem('token');
	}

	async get(): Promise<User> {
		return axios
			.get(`${this.path}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default UsersService;
