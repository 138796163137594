import { ConstructType, ConstructWithLanguage} from '../../interfaces/Construct';
import { Filters, Sorter } from '../../interfaces/Api';
import { useContext, useEffect, useState } from 'react';
import ConstructsService from '../../services/ConstructsService';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import { Language } from '../../interfaces/Language';
import LanguageService from '../../services/LanguageService';
import { ROUTES } from '../../constants/routes-path';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { useNavigate } from 'react-router-dom';

interface UseConstructControllerReturn {
	constructs: ConstructWithLanguage[];
	constructsType: ConstructType[];
	languages: Language[]
	loading: boolean;
	getConstructs: (filters: Filters | null, sorter: Sorter | null, pagination: TablePaginationConfig) => void;
	editConstruct: (id: number) => void;
	deleteConstruct: (selected: ConstructWithLanguage | null) => Promise<void>;
	goToAddNewConstruct: () => void;
	totalCount: number;
	reload: boolean;
}

const useConstructController = (): UseConstructControllerReturn => {
	const navigate = useNavigate();
	const languageService = new LanguageService();
	const constructsService = new ConstructsService();
	const [constructsType] = useState(constructsService.getAllConstructsType());
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [constructs, setConstructs] = useState<ConstructWithLanguage[]>([]);
	const [languages, setLanguages] = useState<Language[]>([]);
	const [reload, setReload] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);
	const [totalCount, setTotalCount] = useState(0);
	const { setError } = useContext(ErrorContext);

	useEffect( () => {
		languageService.getLanguages().then((response) => {
			setLanguages(response);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getConstructs = (filters: Filters | null, sorter: Sorter | null, pagination: TablePaginationConfig) => {
		setLoading(true);

		const currentPage = pagination?.current ?? DEFAULT_PAGINATION.current;
		const pageSize = pagination?.pageSize ?? DEFAULT_PAGINATION.pageSize;

		constructsService
			.getAll(filters, sorter, pagination.pageSize, (currentPage - 1) * pageSize)
			.then(result => {
				if (result !== undefined) {
					setConstructs(result.data);
					setTotalCount(result.totalCount);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const deleteConstruct = async (selected: ConstructWithLanguage | null) => {
		setLoading(true);

		try {
			if (selected) {
				await constructsService.delete(selected.id);
			}

			setReload(prev => !prev);
		} catch (err) {
			setError(err as string);
		} finally {
			setLoading(false);
		}
	};

	const editConstruct = (id: number) => navigate(ROUTES.CONSTRUCT_DETAILS(id.toString()));

	const goToAddNewConstruct = () => {
		navigate(ROUTES.NEW_CONSTRUCT);
	};

	return {
		constructs,
		constructsType,
		languages,
		loading,
		getConstructs,
		editConstruct,
		deleteConstruct,
		goToAddNewConstruct,
		totalCount,
		reload,
	};
};

export default useConstructController;
