import './ErrorConstructAlreadyExist.less';
import { ExportOutlined } from '@ant-design/icons';
import React from 'react';

interface ErrorConstructAlreadyExistProps {
	constructId: number,
	name: string
}

const ErrorConstructAlreadyExist: React.FC<ErrorConstructAlreadyExistProps> = ({ constructId, name }) => {
	return (
		<p className="already-exist-error-container">
			<b>Construct Name Already Exists.</b> The construct{' '}
			<a target="_blank" rel="noreferrer" href={`/construct-details/${constructId}`}>
				<b>{name}</b>
			</a>{' '}
			already exists. Please enter a diferente name or{' '}
			<a target="_blank" rel="noreferrer" href={`/construct-details/${constructId}`}>
				{' '}
				review the existing construct
			</a>{' '}<ExportOutlined className='export-icon' />{' '}
			to check if it meets your needs.
		</p>
	);
};

export default ErrorConstructAlreadyExist;
