import {
	COLOR_BLUE_HEX,
	COLOR_GOLD_HEX,
	COLOR_MAGENTA_HEX,
	COLOR_ORANGE_HEX,
	COLOR_PURPLE_HEX,
	COLOR_SLATE_BLUE_HEX,
	COLOR_TEAL_HEX
} from './ColorsHex';

let map = new Map();

map.set(COLOR_TEAL_HEX, COLOR_TEAL_HEX);
map.set(COLOR_ORANGE_HEX, COLOR_ORANGE_HEX);
map.set(COLOR_MAGENTA_HEX, COLOR_MAGENTA_HEX);
map.set(COLOR_PURPLE_HEX, COLOR_PURPLE_HEX);
map.set(COLOR_BLUE_HEX, COLOR_BLUE_HEX);
map.set(COLOR_GOLD_HEX, COLOR_GOLD_HEX);
map.set(COLOR_SLATE_BLUE_HEX, COLOR_SLATE_BLUE_HEX);

export default map;
