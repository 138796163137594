import { Form, Input, Layout, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import PartnersService from '../../services/PartnersService';
import { PartnerStatus } from '../../interfaces/Partner';
import React from 'react';
import { ROUTES } from '../../constants/routes-path';

const PartnerDetails = () => {
	const [loading, setLoading] = React.useState(false);
	const [partnerName, setPartnerName] = React.useState('');
	const [partnerStatus, setPartnerStatus] = React.useState(PartnerStatus.DISABLED);
	const { setError } = React.useContext(ErrorContext);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { id } = useParams();
	const partnersService = new PartnersService();
	const { Content } = Layout;
	const { TextArea } = Input;
	const routes = [
		{
			path: ROUTES.DASHBOARD,
			breadcrumbName: 'Admin',
		},
		{
			path: ROUTES.PARTNERS,
			breadcrumbName: 'Partners',
		},
		{
			path: '',
			breadcrumbName: partnerName,
		},
	];

	const backToPartners = () => navigate(ROUTES.PARTNERS, {replace: true});

	const updatePartner = async values => {
		setLoading(true);

		try {
			await partnersService.update(id, values);
			setLoading(false);
			backToPartners();
		} catch (err) {
			setLoading(false);
			setError(err.toString());
		}
	};

	React.useEffect(() => {
		partnersService.get(id).then(data => {
			setPartnerName(data.name);
			setPartnerStatus(data.status);
			form.setFieldsValue({
				name: data.name,
				description: data.description,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Partner Details"
					primaryButton="Save partner"
					onSubmit={updatePartner}
					onCancel={backToPartners}
					form={form}
					disabled={loading || partnerStatus === PartnerStatus.DISABLED}
				>
					<Form.Item
						className="file-name"
						name="name"
						label="Partner Name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea" rows={4} />
					</Form.Item>
				</CommonForm>
			</Content>
			{loading && (
				<Spin style={{ display: 'flex', justifyContent: 'center' }} />
			)}
		</Layout>
	);
};

export default PartnerDetails;
