import './UploadTranscripts.less';
import { Button, Col, Form, Layout, message, Modal, Row, Select, Spin, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ErrorContext } from '../../providers/ErrorProvider';
import ProjectsService from '../../services/ProjectsService';
import ProjectTranscriptsService from '../../services/Project/ProjectTranscriptsService';
import ProjectTypesService from '../../services/Project/ProjectTypesService';
import { PropTypes } from 'prop-types';
import SegmentsService from '../../services/Project/ProjectSegmentsService';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const UploadTranscripts = ({ isModalOpen, onClose }) => {
	const [fileList, setFileList] = useState([]);
	const [selectedTypeOptions, setSelectedTypeOptions] = useState({});
	const [isDisabledUploadButton, setIsDisabledUploadButton] = useState(true);
	const [loading, setLoading] = useState(false);
	const [segments, setSegments] = useState([]);
	const [projectTypes, setProjectTypes] = useState([]);
	const [segmentSelected, setSegmentSelected] = useState('');
	const [title, setTitle] = useState('');
	const { setError } = useContext(ErrorContext);
	const projectsService = new ProjectsService();
	const { projectId } = useParams();
	const transcriptsService = new ProjectTranscriptsService(projectId, setError);
	const segmentsService = new SegmentsService(projectId);
	const typesService = new ProjectTypesService(projectId, setError);
	const { Content } = Layout;
	const { Option } = Select;

	const props = {
		name: 'file',
		multiple: true,
		defaultFileList: [''],
		showUploadList: true,
		beforeUpload: file => {
			this.setFileList([...fileList, file]);

			return false;
		},
		onChange(info) {
			const status = info.file.status;

			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}

			setFileList(info.fileList);
		},
	};

	const getSegments = async () => {
		setLoading(true);
		segmentsService
			.getAllWithoutPagination()
			.then(result => {
				if (result !== undefined) {
					setSegments(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClose = () => {
		setFileList([]);
		onClose();
	};

	const uploadTranscripts = async () => {
		try {
			setLoading(true);

			const types = Object.keys(selectedTypeOptions).map(typeId => {
				return {
					id: typeId,
					option: {
						id: selectedTypeOptions[typeId],
					},
				};
			});

			await Promise.all(fileList.map(file => transcriptsService.create(file.originFileObj, segmentSelected, types)));

			setLoading(false);
			handleClose();
		} catch (err) {
			setError(err);
			setLoading(false);
		}
	};

	const getNameProject = async () => {
		setLoading(true);
		projectsService
			.get(projectId)
			.then(result => {
				if (result !== undefined) {
					setTitle('Upload transcripts to ' + result.name);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getTypes = () => {
		setLoading(true);

		typesService
			.getAllWithoutPagination()
			.then(result => {
				setProjectTypes(result.data);
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSelectedTypeOption = (typeId, typeOptionId) => {
		setSelectedTypeOptions({
			...selectedTypeOptions,
			[typeId]: typeOptionId,
		});
	};

	useEffect(() => {
		getSegments();
		getNameProject();
		getTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsDisabledUploadButton(
			!segmentSelected || !fileList.length > 0
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [segmentSelected, fileList]);

	return (
		<Modal
			title={title}
			centered
			open={isModalOpen}
			width={800}
			destroyOnClose
			onCancel={handleClose}
			footer={[
				<Button key="back" onClick={handleClose}>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					loading={loading}
					onClick={() => uploadTranscripts()}
					disabled={isDisabledUploadButton}
				>
					Upload
				</Button>,
			]}
		>
			<Content style={{ display: '100%' }}>
				<Row gutter={40}>
					<Col className="gutter-row" span={14}>
						<div style={{ padding: '8px 0' }}>
							<label className="gutter-row-label" style={{ fontWeight: 'bold' }}>
								Selected files
							</label>
							<hr></hr>
							<div id="content-list">
								<Upload {...props} fileList={fileList} disabled={loading}>
									<Button icon={<UploadOutlined />}>Upload</Button>
								</Upload>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={10}>
						<div style={{ padding: '8px 0' }}>
							<div>
								<label className="gutter-row" style={{ fontWeight: 'bold' }}>
									Segments
								</label>
								<span style={{ fontWeight: 'bold', color: 'red' }}> * </span>
								<label
									className="gutter-row"
									style={{
										paddingRight: '0px',
										fontStyle: 'italic',
										textAlign: 'right',
										float: 'right',
									}}
								>
									Affects all selected files
								</label>
							</div>
							<Form.Item className="edit-segments" name="segments" rules={[{ required: true }]}>
								<Select
									className="segment-select"
									allowClear
									value={null}
									style={{ width: '100%' }}
									placeholder="Loyalist"
									onChange={value => setSegmentSelected(segments[value])}
								>
									{segments.map((segment, index) => (
										<Option key={index} value={index}>
											{segment.name}
										</Option>
									))}
								</Select>
							</Form.Item>
							<div>
								<label className="gutter-row" style={{ fontWeight: 'bold' }}>
									Types
								</label>
								<label
									className="gutter-row"
									style={{
										paddingRight: '0px',
										fontStyle: 'italic',
										textAlign: 'right',
										float: 'right',
									}}
								>
									Affects all selected files
								</label>
							</div>
							<hr></hr>
							<div className="upload-transcripts-types">
								{
									projectTypes.map((projectType, index) => (
										<React.Fragment key={index}>
											<div id="label-types">{projectType.name}</div>
											<Form.Item className="edit-age-types" name={projectType.name}>
												<Select
													className="age-select"
													allowClear
													value={null}
													style={{ width: '100%' }}
													placeholder="Select"
													onChange={typeOptionId => handleSelectedTypeOption(projectType.key, typeOptionId)}
												>
													{projectType.options.map((item, index) => (
														<Option key={index} value={item.id}>
															{item.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</React.Fragment>
									))
								}
							</div>
						</div>
					</Col>
				</Row>
				{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
			</Content>
		</Modal>
	);
};

UploadTranscripts.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default UploadTranscripts;
