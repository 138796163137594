import './ProjectTranscripts.less';
import { Badge, Col, Form, Input, Row, Select, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import moment from 'moment';
import PROJECT_ROUTES from '../../constants/project-constants';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectSegmentsService from '../../services/Project/ProjectSegmentsService';
import ProjectsService from '../../services/ProjectsService';
import ProjectTranscriptsService from '../../services/Project/ProjectTranscriptsService';
import { UserContext } from '../../providers/UserProvider';

const dateFormat = 'MM/DD/YYYY';

const ProjectTranscripts = () => {
	const { setError } = useContext(ErrorContext);
	const projectsService = new ProjectsService();
	const [segments, setSegments] = useState([]);
	const [transcripts, setTranscripts] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [filters, setFilters] = useState({ name: null, segmentId: null, completed: null });
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const [sorter, setSorter] = useState(undefined);
	const [selected, setSelected] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);
	const { Option } = Select;
	const { projectId } = useParams();
	const projectSegmentsService = new ProjectSegmentsService(projectId);
	const projectTranscriptsService = new ProjectTranscriptsService(projectId);
	const [status] = useState(projectTranscriptsService.getAllStatusOptions());
	const { user } = useContext(UserContext);
	const { Text } = Typography;

	const getBadge = status => {
		if (status.toLowerCase() === 'active') {
			return <Badge size={12} color="#1991EB" text="Active" />;
		} else if (status.toLowerCase() === 'complete') {
			return <Badge size={12} color="#858B94" text="Complete" />;
		}
	};

	const toggleShowModal = (item) => {
		setShowModal(!showModal);

		if (item && item.key) {
			setSelected(item);
		}
	};

	const actions = (item) => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Delete transcript">
					<DeleteOutlined
						style={{ color: '#1991EB', marginLeft: 16 }}
						onClick={() => toggleShowModal(item)}
					/>
				</Tooltip>
			</div>
		);
	};

	const columns = [
		{
			title: 'Names',
			dataIndex: 'name',
			render: (_, transcript) => <Link to={`/projects/${projectId}/transcripts/${transcript.key}`}>{transcript.name}</Link>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Segment',
			dataIndex: 'segment',
			render: (_, transcript) => <span>{transcript.segment.name}</span>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Last Edit',
			dataIndex: 'updatedAt',
			render: (value) => <span>{moment(value).format(dateFormat)}</span>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: status => getBadge(status),
			sorter: true,
			align: 'right',
			sortDirections: ['descend', 'ascend'],
		},
	];

	if (user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) {
		columns.push({
			title: 'Actions',
			dataIndex: '',
			align: 'left',
			key: 'x',
			render: element => actions(element),
		});
	}

	const resetSelected = () => {
		setSelected(null);
	};

	const getSelectedName = () => {
		return selected && selected.name
			? `transcript ${selected.name}?`
			: 'transcript?';
	};

	const getModalDescription = () => {
		return [
			<Text key="modal-text">
				Are you sure you want to delete the{' '}
			</Text>,
			<Text strong key="modal-text-name">
				{getSelectedName()}
			</Text>,
		];
	};

	const deleteTranscript = async () => {
		setLoading(true);
		toggleShowModal();

		try {
			await projectTranscriptsService.delete(selected.key);
			getTranscripts();
		} catch (err) {
			setError(err.toString());
		}

		setLoading(false);
	};

	const getTranscripts = () => {
		setLoading(true);

		projectTranscriptsService
			.getAll(filters, sorter, pagination.pageSize, (pagination.current - 1) * pagination.pageSize)
			.then(result => {
				if (result !== undefined) {
					setTranscripts(result.data);
					setTotalCount(result.totalCount);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getSegments = () => {
		setLoading(true);

		projectSegmentsService
			.getAll()
			.then(result => {
				if (result !== undefined) {
					setSegments(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const filterByName = event => {
		if (event.key === 'Enter') {
			setFilters({ ...filters, name: event.target.value });
		}
	};

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				name: 'name',
				segment: 'segment',
				status: 'completed',
				updatedAt: 'updatedAt',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(undefined);
			} else {
				setSorter({ sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}` });
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	useEffect(() => {
		projectsService.get(projectId).then(data => {
			getSegments();
			const actualRoutes = [
				{
					path: 'projects',
					breadcrumbName: 'Projects',
				},
				{
					path: projectId,
					breadcrumbName: data.name,
				},
				{
					path: 'transcripts',
					breadcrumbName: 'Transcripts',
				},
			];

			setRoutes(actualRoutes);
			setActive(PROJECT_ROUTES.TRANSCRIPTS);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getTranscripts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination]);

	return (
		<div className="ProjectTranscripts">
			<div className="filters-container">
				<Form layout="vertical">
					<Row gutter={20}>
						<Col lg={5}>
							<Form.Item label="Name" name="name">
								<Input placeholder="All" onKeyDown={filterByName} suffix={<SearchOutlined />} />
							</Form.Item>
						</Col>
						<Col lg={10}>
							<Form.Item label="Segment" name="segment">
								<Select
									placeholder="All"
									onChange={value => setFilters({ ...filters, segmentId: value })}
									loading={loading}
									mode="multiple"
									allowClear
								>
									{segments.map(segment => (
										<Option key={segment.key}>{segment.name}</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Status" name="status">
								<Select
									placeholder="All"
									onChange={value => setFilters({ ...filters, completed: value == 1 })}
									loading={loading}
									allowClear
								>
									{status.map(item => (
										<Option key={item.key}>{item.name}</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={transcripts}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
					loading={loading}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle="Yes, delete transcript"
				handleOk={deleteTranscript}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
			/>
		</div>
	);
};

export default ProjectTranscripts;
