import { Construct, ConstructType } from '../../interfaces/Construct';
import { Filters, Sorter } from '../../interfaces/Api';
import { useContext, useState } from 'react';
import ConstructService from '../../services/ConstructsService';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import PROJECT_ROUTES from '../../constants/project-constants';
import ProjectConstructsService from '../../services/Project/ProjectConstructsService';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectsService from '../../services/ProjectsService';
import { TablePaginationConfig } from 'antd/lib/table/interface';

interface UseProjectConstructsControllerReturn {
	constructs: Construct[];
	constructsType: ConstructType[];
	loading: boolean;
	getProject: () => void;
	getConstructs: (filters: Filters | null, sorter: Sorter | null, pagination: TablePaginationConfig) => void;
	deleteConstruct: (selected: Construct | null) => Promise<void>;
	totalCount: number;
	reload: boolean;
}

const useProjectConstructsController = (projectId: string): UseProjectConstructsControllerReturn => {
	const constructsService = new ConstructService();
	const projectsService = new ProjectsService();
	const projectConstructsService = new ProjectConstructsService(+projectId);

	const { setError } = useContext(ErrorContext);
	const [constructs, setConstructs] = useState<Construct[]>([]);
	const [constructsType] = useState(constructsService.getAllConstructsType());
	const [loading, setLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [reload, setReload] = useState<boolean>(false);
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);

	const getConstructs = (filters: Filters | null, sorter: Sorter | null, pagination: TablePaginationConfig) => {
		setLoading(true);

		const currentPage = pagination?.current ?? DEFAULT_PAGINATION.current;
		const pageSize = pagination?.pageSize ?? DEFAULT_PAGINATION.pageSize;

		projectConstructsService
			.getAll(filters, sorter, pagination.pageSize, (currentPage - 1) * pageSize)
			.then(result => {
				if (result !== undefined) {
					setConstructs(result.data.filter(construct => !construct.disabled));
					setTotalCount(result.totalCount);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const deleteConstruct = async (selected: Construct | null) => {
		setLoading(true);

		try {
			if (selected) {
				await projectConstructsService.delete(selected.id);
			}

			setReload(prev => !prev);
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	const getProject = () => {
		projectsService.get(+projectId).then(data => {
			setReload(prev => !prev);
			const actualRoutes = [
				{
					path: 'projects',
					breadcrumbName: 'Projects',
				},
				{
					path: projectId,
					breadcrumbName: data.name,
				},
				{
					path: 'constructs',
					breadcrumbName: 'Constructs',
				},
			];

			setRoutes(actualRoutes);
			setActive(PROJECT_ROUTES.CONSTRUCTS);
		});
	};

	return { constructs, constructsType, loading, getProject, getConstructs, deleteConstruct, totalCount, reload };
};

export default useProjectConstructsController;
