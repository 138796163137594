import React, { useEffect, useState } from 'react';
import { ApiConstruct } from '../../interfaces/Construct';
import ConstructsService from '../../services/ConstructsService';
import { DataNode } from 'antd/lib/tree';
import ProjectSegmentsBreakdownService from '../../services/Project/ProjectSegmentsBreakdownService';
import { SegmentBreakdown } from '../../interfaces/Segment';
import { useParams } from 'react-router-dom';

const formatSegmentsBreakdownToDataNode = (segmentsBreakdown: SegmentBreakdown[]): DataNode[] => {

	return segmentsBreakdown.map((segment, segmentIndex) => {

		let types: DataNode[] = [];

		if (segment.types) {
			types = segment.types.map((type, typeIndex) => {
				return {
					title: (
						<span className="node-title">
							{type.name}
							<span className="count-number">{type.count}</span>
						</span>
					),
					className: 'node type-node',
					key: `${segmentIndex}-${typeIndex}`,
					children: type.options.map((typeOption, typeOptionIndex) => ({
						title: (
							<p className='option-title-container'>
								<span className="node-title">
									{typeOption.name}:
								</span>
								<span className="count-number">{typeOption.count}</span>
							</p>
						),
						key: `${segmentIndex}-${typeIndex}-${typeOptionIndex}`,
						className: 'option-node',
						disableCheckbox: true,
					})),
				};
			});
		}

		return {
			title: (
				<p className="node-title">
					{segment.name}
					<span className="count-number">{segment.count}</span>
				</p>
			),
			key: segment.name,
			className: 'node segment-node',
			children: types,
		};

	});
};

export interface ConstructsId {
	constructLeftId: string;
	constructRightId: string;
}

interface SegmentsBreakdownModalControllerReturn {
	constructLeft?: ApiConstruct,
	constructRight?: ApiConstruct
	loading: boolean,
	tree: DataNode[]
	totalCount: number,
	handleClose: ()=>void
}

const useSegmentsBreakdownModalController = (onClose: ()=>void, constructsId?: ConstructsId): SegmentsBreakdownModalControllerReturn => {
	const constructsService = new ConstructsService();
	const { projectId } = useParams<{ projectId: string }>();
	const projectSegmentsBreakdownService = new ProjectSegmentsBreakdownService(+(projectId ||''));
	const [constructLeft, setConstructLeft] = useState<ApiConstruct>();
	const [constructRight, setConstructRight] = useState<ApiConstruct>();
	const [loading, setLoading] = useState(true);
	const [tree, setTree] = useState<DataNode[]>([]);
	const [totalCount, setTotalCount] = useState(0);

	const loadConstructs = async () => {
		if (constructsId) {
			const left = await constructsService.get(+constructsId.constructLeftId);
			const right = await constructsService.get(+constructsId.constructRightId);

			setConstructLeft(left);
			setConstructRight(right);
		}
	};

	const loadSegmentBreakdown = async () => {
		if (constructsId) {
			projectSegmentsBreakdownService.get(+constructsId.constructLeftId, +constructsId.constructRightId).then((data) => {
				setTotalCount(()=>data.count);
				setTree(()=>formatSegmentsBreakdownToDataNode(data.segmentsBreakdown));
			}).finally(() => {
				setLoading(false);

			});
		}
	};

	useEffect(() => {
		loadConstructs();
		loadSegmentBreakdown();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [constructsId]);

	const handleClose = () => {
		setLoading(()=>true);
		setConstructLeft(()=>undefined);
		setConstructRight(()=>undefined);
		setTree(()=>[]);
		onClose();
	};

	return {
		constructLeft,
		constructRight,
		loading,
		tree,
		totalCount,
		handleClose,
	};

};

export default useSegmentsBreakdownModalController;
