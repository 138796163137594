import './CollapsableTranscripts.less';
import Annotator from './Annotator';
import { ProjectTranscriptScrollContext } from '../../containers/ProjectTranscript/ProjectTranscriptScrollProvider';
import React from 'react';

const CollapsableTranscripts = ({
	marginBottom,
	setAnnotatorCallback,
	transcriptParagraphs,
	constructSelected,
	setAnnotateSelected,
	annotateSelected,
	isAnnotateRemoved,
	setIsAnnotateRemoved,
	setParagraphSelected,
}) => {
	const { transcriptScrollY, setTranscriptScrollY } = React.useContext(ProjectTranscriptScrollContext);
	const containerRef = React.useRef(null);

	let filterTimeout ;

	const handleScroll = () => {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(() => {
			setTranscriptScrollY(containerRef.current.scrollTop);
		}, 500);
	};

	React.useEffect(() => {
		if (containerRef) {
			containerRef.current.scrollTo({
				top: transcriptScrollY || 0,
				behavior: 'smooth',
			});
		}
	}, [containerRef]);

	return (
		<div
			style={{
				borderRadius: '2px',
				marginBottom: marginBottom ?? 50,
				backgroundColor: 'white',
				padding: 20,
				height: '100%',
				overflowY: 'auto',
			}}
			onScroll={handleScroll}
			ref={containerRef}
		>
			{transcriptParagraphs.map((paragraph) => (
				<div key={paragraph.id} style={{ marginBottom: 20 }}>
					{paragraph.text && (
						<Annotator
							setAnnotatorCallback={setAnnotatorCallback}
							text={paragraph.text}
							constructSelected={constructSelected}
							setAnnotateSelected={setAnnotateSelected}
							annotateSelected={annotateSelected}
							isAnnotateRemoved={isAnnotateRemoved}
							setIsAnnotateRemoved={setIsAnnotateRemoved}
							excerpts={paragraph.excerpts}
							setParagraphSelected={setParagraphSelected}
							paragraphId={paragraph.id}
						/>
					)}
				</div>
			))}
		</div>
	);
};

export default CollapsableTranscripts;
