import './ClientsList.less';
import {
	Button,
	Col,
	Divider,
	Input,
	Row,
	Switch,
	Table,
	Tag,
	Tooltip,
	Typography
} from 'antd';
import {
	EditOutlined,
	PlusOutlined,
	SearchOutlined
} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import { BUTTON_TYPES } from '../../constants/button-types';
import ClientsService from '../../services/ClientsService';
import { ClientStatus } from '../../interfaces/Client';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

const ClientsList = () => {
	const [clients, setClients] = useState([]);
	const [filters, setFilters] = useState({ name: '' });
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const [sorter, setSorter] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [selected, setSelected] = useState(null);
	const [totalCount, setTotalCount] = useState(0);
	const navigate = useNavigate();
	const { setError } = useContext(ErrorContext);
	const { user } = useContext(UserContext);
	const clientsService = new ClientsService();
	const [buttonModalType, setButtonModalType] = useState(BUTTON_TYPES.danger);
	const { Text } = Typography;
	const routes = [
		{
			path: '/',
			breadcrumbName: 'Admin',
		},
		{
			path: '/clients',
			breadcrumbName: 'Clients',
		},
	];

	const toggleShowModal = (disabled, item) => {
		if (item && item.key) {
			setSelected(item);
		}

		setShowModal(!showModal);
		setButtonModalType(disabled ? BUTTON_TYPES.primary : BUTTON_TYPES.danger);
	};

	const goToAddClient = () => {
		navigate(ROUTES.NEW_CLIENT);
	};

	const goToEditClient = id => navigate(ROUTES.CLIENT_DETAILS(id));

	const actions = item => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Edit client">
					<EditOutlined
						style={{ color: '#1991EB' }}
						onClick={() => goToEditClient(item.key)}
					/>
				</Tooltip>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				<Tooltip placement="top" title="Disable client">
					<Switch
						size="small"
						checked={item.status === ClientStatus.DISABLED}
						onChange={checked => toggleShowModal(checked, item)}
					/>
				</Tooltip>
			</div>
		);
	};

	const renderLinkedText = (item) => <a onClick={() => goToEditClient(item.key)}>{item.name}</a>;
	const renderText = (text) => <span>{text}</span>;
	const renderTags = tags => (
		<span>
			{tags && tags.length ? (
				tags.map((tag, index) => {
					return <Tag key={index} style={{marginBottom: 6}}>{tag.name.toUpperCase()}</Tag>;
				})
			) : (
				<Text>No active projects</Text>
			)}
		</span>
	);

	const columns = [
		{
			title: 'Clients',
			dataIndex: 'name',
			fixed: 'left',
			width: 300,
			render: (_, item) => renderLinkedText(item),
			sorter: true,
			sortDirections: ['ascend', 'descend'],
		},
		{
			title: 'Active projects',
			dataIndex: 'activeProjects',
			fixed: 'left',
			key: 'tags',
			render: tags => renderTags(tags),
		},
	];

	if (user?.role === AnalystRole.ADMIN) {
		columns.push({
			title: 'Partner',
			dataIndex: 'partner',
			render: text => renderText(text),
		});
	}

	if (user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN) {
		columns.push({
			title: 'Actions',
			dataIndex: '',
			key: 'x',
			align: 'right',
			render: element => actions(element),
		});
	}

	const getClients = () => {
		setLoading(true);

		clientsService
			.getAll(filters, sorter, pagination.pageSize, (pagination.current - 1) * pagination.pageSize)
			.then(result => {
				if (result !== undefined) {
					setClients(result.data);
					setTotalCount(result.totalCount);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const toggleDisableClient = () => {
		setLoading(true);

		clientsService
			.update(selected.key, { status: selected.status === ClientStatus.DISABLED ? ClientStatus.ENABLED : ClientStatus.DISABLED })
			.then(() => {
				getClients();
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
				toggleShowModal();
			});
	};

	const resetSelected = () => setSelected(null);

	const getSelectedName = () => {
		return selected && selected.name ? `${selected.name}` : 'unknown';
	};

	const getConfirmMessagge = () => {
		return selected && selected.activeProjects.length > 0 ? `The client ${getSelectedName()} has projects.` : `Are you sure you want to ${selected && selected.status === ClientStatus.ENABLED ? 'enable' : 'disable'} '${getSelectedName()}' client?`;
	};

	const getOkTitle = () => {
		return selected && selected.status === ClientStatus.ENABLED ? 'Yes, disable' : 'Yes, enable';
	};

	const getModalDescription = () => {
		return [
			<Text key={1} strong>
				{getConfirmMessagge()}
			</Text>,
			<Text key={2}>
				{' '}
				Please confirm you want to proceed.
			</Text>,
		];
	};

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				name: 'name',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(undefined);
			} else {
				setSorter({ sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}` });
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	useEffect(() => {
		getClients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination]);

	const filterByName = event => {
		if (event.keyCode === 13) {
			setFilters({ ...filters, name: event.target.value });
			setPagination(DEFAULT_PAGINATION);
		}
	};

	return (
		<div className="Client">
			<Row justify="space-between">
				<Col>
					<CustomBreadcrumb routes={routes} />
				</Col>
				<Col>
					<Row gutter={22} style={{ marginTop: '6px' }}>
						<Col style={{ width: '354px' }}>
							<Input
								width="354"
								placeholder="Search"
								suffix={<SearchOutlined />}
								onKeyDown={filterByName}
							/>
						</Col>
						{
							user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN && (
								<Col>
									<Button
										type="primary"
										onClick={goToAddClient}
										icon={<PlusOutlined />}
									>
										Add new client
									</Button>
								</Col>
							)
						}
					</Row>
				</Col>
			</Row>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={clients}
					loading={loading}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle={getOkTitle()}
				handleOk={toggleDisableClient}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
				okType={buttonModalType}
			/>
		</div>
	);
};

export default ClientsList;
