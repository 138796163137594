import { Button, Col, Row } from 'antd';
import { CopyOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import BtnTab from '../../components/BtnTab/BtnTab';
import CollapsableProject from '../../components/CollapsableProject/CollapsableProject';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PROJECT_ROUTES from '../../constants/project-constants';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import { ROUTES } from '../../constants/routes-path';
import UploadTranscripts from '../../containers/UploadTranscripts/UploadTranscripts';
import { UserContext } from '../../providers/UserProvider';

const ProjectLayout = () => {
	const [isVisibleUploadTranscriptsModal, setIsVisibleUploadTranscriptsModal] =
		useState(false);
	const { routes, active } = useContext(ProjectLayoutContext);
	const navigate = useNavigate();
	const { projectId } = useParams();
	const { user } = useContext(UserContext);

	const goToRoute = route => {
		navigate(route);
	};

	return (
		<div className="ProjectTranscripts">
			<Row justify="space-between">
				<Col>
					<CustomBreadcrumb routes={routes} />
				</Col>
				<Col>
					<Row gutter={10}>
						{
							user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN && (
								<Col>
									<Button className="noBackgroundButton" icon={<CopyOutlined />} onClick={() => navigate(ROUTES.PROJECT_DUPLICATE(projectId))}>
										Duplicate Project
									</Button>
								</Col>
							)
						}
						{
							user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN && (
								<Col>
									<Button className="blueButton" icon={<EditOutlined />} onClick={() => navigate(ROUTES.PROJECT_DETAILS_EDIT(projectId))}>
										Edit Project
									</Button>
								</Col>
							)
						}
						{
							(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
								<Col>
									<Button
										type="primary"
										icon={<UploadOutlined />}
										onClick={() => setIsVisibleUploadTranscriptsModal(true)}
									>
										Upload Transcripts
									</Button>
								</Col>
							)
						}
					</Row>
				</Col>
			</Row>
			<UploadTranscripts
				isModalOpen={isVisibleUploadTranscriptsModal}
				onClose={() => { setIsVisibleUploadTranscriptsModal(false); window.location.reload(); }}
			/>
			<CollapsableProject project={projectId} />
			<div className="btn-group" style={{ marginTop: '20px' }}>
				<BtnTab
					label="Transcripts"
					onClick={() => goToRoute(`/projects/${projectId}/transcripts`)}
					isActive={active === PROJECT_ROUTES.TRANSCRIPTS}
				/>
				<BtnTab
					label="Segments"
					onClick={() => goToRoute(`/projects/${projectId}/segments`)}
					isActive={active === PROJECT_ROUTES.SEGMENTS}
				/>
				<BtnTab
					label="Types"
					onClick={() => goToRoute(`/projects/${projectId}/types`)}
					isActive={active === PROJECT_ROUTES.TYPES}
				/>
				<BtnTab
					label="Constructs"
					onClick={() => goToRoute(`/projects/${projectId}/constructs`)}
					isActive={active === PROJECT_ROUTES.CONSTRUCTS}
				/>
				<BtnTab
					label="Lexicon"
					onClick={() => goToRoute(`/projects/${projectId}/lexicon`)}
					isActive={active === PROJECT_ROUTES.LEXICON}
				/>
				<BtnTab
					label="Map"
					onClick={() => goToRoute(`/projects/${projectId}/map`)}
					isActive={active === PROJECT_ROUTES.MAP}
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default ProjectLayout;
