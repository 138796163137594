import {
	COLOR_BLUE_HEX,
	COLOR_GOLD_HEX,
	COLOR_MAGENTA_HEX,
	COLOR_ORANGE_HEX,
	COLOR_PURPLE_HEX,
	COLOR_SLATE_BLUE_HEX,
	COLOR_TEAL_HEX
} from './ColorsHex';

let map = new Map();

map.set(COLOR_TEAL_HEX, '#E5F3F0');
map.set(COLOR_ORANGE_HEX, '#FAEDE6');
map.set(COLOR_MAGENTA_HEX, '#FAEBF5');
map.set(COLOR_PURPLE_HEX, '#F3EEFB');
map.set(COLOR_BLUE_HEX, '#EAF1FA');
map.set(COLOR_GOLD_HEX, '#F5F0E5');
map.set(COLOR_SLATE_BLUE_HEX, '#ECEEF1');

export default map;
