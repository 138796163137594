import './Projects.less';
import { Button, Col, Divider, Form, Input, Row, Select, Switch, Table, Tooltip, Typography } from 'antd';
import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import { BUTTON_TYPES } from '../../constants/button-types';
import ClientsService from '../../services/ClientsService';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import IndustriesService from '../../services/IndustriesService';
import moment from 'moment';
import ProgressCustom from '../../components/ProgressCustom/ProgressCustom';
import ProjectsService from '../../services/ProjectsService';
import { ProjectStatus } from '../../interfaces/Project';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';

const dateFormat = 'MM/DD/YYYY';
const STORED_SORTING = 'STORED_SORTING';

const Projects = () => {
	const { setError } = useContext(ErrorContext);
	const [projects, setProjects] = useState([]);
	const projectsService = new ProjectsService();
	const clientsService = new ClientsService();
	const industriesService = new IndustriesService();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const { user } = useContext(UserContext);

	const getSorting = () => {
		const sorting = sessionStorage.getItem(STORED_SORTING);

		if (sorting) {
			try {
				return JSON.parse(sorting);
			} catch (e) {
				return { sort: 'updatedAt:desc' };
			}
		}

		return { sort: 'updatedAt:desc' };
	};

	const [sorter, setSorter] = useState(getSorting());
	const [filters, setFilters] = useState({
		name: '',
		clientId: [],
		industryId: [],
	});
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const [clients, setClients] = useState([]);
	const [industries, setIndustries] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selected, setSelected] = useState(null);
	const [buttonModalType, setButtonModalType] = React.useState(BUTTON_TYPES.danger);
	const { Text } = Typography;

	const routes = [
		{
			path: '',
			breadcrumbName: 'Projects',
		},
	];

	const getProjects = () => {
		setLoading(true);

		projectsService
			.getAll(
				filters,
				sorter,
				pagination.pageSize,
				(pagination.current - 1) * pagination.pageSize
			)
			.then((result) => {
				if (result !== undefined) {
					setProjects(result.data);
					setTotalCount(result.totalCount);
				}
			})
			.catch((err) => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getClients = () => {
		setLoading(true);

		clientsService
			.getAllWithoutPagination()
			.then((result) => {
				if (result !== undefined) {
					setClients(result.data);
				}
			})
			.catch((err) => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getIndustries = () => {
		setLoading(true);

		industriesService
			.getAllWithoutPagination()
			.then((result) => {
				if (result !== undefined) {
					setIndustries(result.data);
				}
			})
			.catch((err) => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getClients();
		getIndustries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getProjects();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination]);

	const { Option } = Select;

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				project: 'name',
				client: 'client',
				industry: 'industry',
				updatedAt: 'updatedAt',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			const sorting = !tableSorter.order
				? undefined
				: {
					sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}`,
				};

			setSorter(sorting);

			if (!tableSorter.order) {
				sessionStorage.removeItem(STORED_SORTING);
			} else {
				sessionStorage.setItem(STORED_SORTING, JSON.stringify(sorting));
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	const filterByProject = (event) => {
		if (event.keyCode === 13) {
			setFilters({ ...filters, name: event.target.value });
			setPagination(DEFAULT_PAGINATION);
		}
	};

	const filterByClient = (clientIds) => {
		setFilters({ ...filters, clientId: clientIds });
		setPagination(DEFAULT_PAGINATION);
	};

	const filterByIndustry = (industryIds) => {
		setFilters({ ...filters, industryId: industryIds });
		setPagination(DEFAULT_PAGINATION);
	};

	const getDefaultOrder = (name) => {
		const sorting = getSorting();

		if (!sorting && name === 'updatedAt') {
			return 'descend';
		}

		try {
			const values = sorting.sort.split(':');

			if (values[0] === name) {
				return values[1] === 'desc' ? 'descend' : 'ascend';
			}
		} catch (e) {
			setError(e);
		}

		return undefined;
	};

	const columns = [
		{
			title: 'Project',
			dataIndex: 'project',
			render: (_, project) => (
				<Link to={`/projects/${project.key}`} disabled={project.status === ProjectStatus.DISABLED}>{project.name}</Link>
			),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
			defaultSortOrder: getDefaultOrder('project'),
		},
		{
			title: 'Client',
			dataIndex: 'client',
			render: (_, project) => <span>{project.client.name}</span>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
			defaultSortOrder: getDefaultOrder('client'),
		},
		{
			title: 'Industry',
			dataIndex: 'industry',
			render: (_, project) => <span>{project.client.industry.name}</span>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
			defaultSortOrder: getDefaultOrder('industry'),
		},
		{
			title: 'Last edit',
			dataIndex: 'updatedAt',
			render: (value) => <span>{moment(value).format(dateFormat)}</span>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
			defaultSortOrder: getDefaultOrder('updatedAt'),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: '250px',
			render: (_, project) => (
				project.transcriptsCount ? (
					<ProgressCustom
						total={project.transcriptsCount}
						value={project.completedTranscriptsCount}
						status={'active'}
					/>
				) : (
					'No transcripts found'
				)
			),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
			defaultSortOrder: getDefaultOrder('status'),
		},
	];

	if (user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN) {
		columns.push({
			title: 'Actions',
			align: 'right',
			dataIndex: '',
			key: 'x',
			render: (element) => actions(element),
		});
	}

	const toggleShowModal = (disabled, item) => {
		setShowModal(!showModal);
		setButtonModalType(disabled ? BUTTON_TYPES.primary : BUTTON_TYPES.danger);

		if (item && item.key) {
			setSelected(item);
		}
	};

	const getOkTitle = () => {
		return selected && selected.status === ProjectStatus.ENABLED ? 'Yes, disable' : 'Yes, enable';
	};

	const getModalDescription = () => {
		return [
			<Text key={2}>
				{' '}
				Please confirm you want to proceed.
			</Text>,
		];
	};

	const toggleDisableProject = () => {
		setLoading(true);

		projectsService
			.update(selected.key, { status: selected.status === ProjectStatus.DISABLED ? ProjectStatus.ENABLED : ProjectStatus.DISABLED })
			.then(() => {
				getProjects();
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
				toggleShowModal();
			});
	};

	const editItem = (item) => {
		navigate(ROUTES.PROJECT_DETAILS_EDIT(item.key));
	};

	const actions = (item) => {
		return (
			<div className="flex">
				<EditOutlined
					style={{ color: '#1991EB' }}
					onClick={() => editItem(item)}
				/>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				<Tooltip placement="top" title="Disable project">
					<Switch
						size="small"
						checked={item.status === ProjectStatus.DISABLED}
						onChange={checked => toggleShowModal(checked, item)}
					/>
				</Tooltip>
			</div>
		);
	};

	const resetSelected = () => {
		setSelected(null);
	};

	return (
		<div className="Projects">
			<Row justify="space-between">
				<Col>
					<CustomBreadcrumb routes={routes} />
				</Col>
				<Col>
					<Row gutter={22}>
						{
							(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
								<Col>
									<Button type="primary" onClick={() => navigate(ROUTES.NEW_PROJECT)} icon={<PlusOutlined />}>
										Create Project
									</Button>
								</Col>
							)
						}
					</Row>
				</Col>
			</Row>
			<div className="filters-container">
				<Form layout="vertical">
					<Row gutter={20}>
						<Col lg={5}>
							<Form.Item label="Project" name="project">
								<Input
									placeholder="All"
									onKeyDown={filterByProject}
									suffix={<SearchOutlined />}
								/>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Client" name="client">
								<Select
									className="client-select"
									allowClear
									style={{ width: '100%' }}
									placeholder="All"
									onChange={(value) => filterByClient(value)}
									onClear={() => filterByClient(undefined)}
									mode="multiple"
									optionFilterProp="label"
								>
									{clients.map((client, index) => (
										<Option key={index} value={client.key} label={client.name}>
											{client.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Industry" name="industry">
								<Select
									className="industry-select"
									allowClear
									style={{ width: '100%' }}
									placeholder="All"
									onChange={(value) => filterByIndustry(value)}
									onClear={() => filterByIndustry(undefined)}
									mode="multiple"
									optionFilterProp="label"
								>
									{industries.map((industry, index) => (
										<Option key={index} value={industry.key} label={industry.name}>
											{industry.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						{/* <Col lg={5}>
							<Form.Item
								label="Status"
								name="status"
							>
								<Select
									placeholder="Active"
								>
									<Option value="option1">Option 1</Option>
									<Option value="option2">Option 2</Option>
								</Select>
							</Form.Item>
						</Col> */}
					</Row>
				</Form>
			</div>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={projects}
					loading={loading}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle={getOkTitle()}
				handleOk={toggleDisableProject}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
				okType={buttonModalType}
			/>
		</div>
	);
};

export default Projects;
