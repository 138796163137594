import { Button, Result } from 'antd';
import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

const Error = () => {
	const navigate = useNavigate();
	const back = () => {
		navigate(ROUTES.DASHBOARD);
	};

	React.useEffect(() => {
		Mixpanel.track('error', {
			type: 404,
		});
	}, []);

	return (
		<Result
			status="404"
			title="404"
			subTitle="Oops, there is something wrong here"
			extra={<Button onClick={() => back()} type="primary">Back</Button>}
		/>
	);
};

export default Error;
