import { Form, Input, Layout, Spin } from 'antd';
import React, { useContext } from 'react';

import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import IndustriesService from '../../services/IndustriesService';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

const NewIndustry = () => {
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	const { setError } = useContext(ErrorContext);
	const industriesService = new IndustriesService();
	const { TextArea } = Input;
	const { Content } = Layout;
	const routes = [
		{ path: '/', breadcrumbName: 'Admin' },
		{ path: '/industries', breadcrumbName: 'Industries' },
		{ path: '/new-industry', breadcrumbName: 'Add new industry' },
	];

	const backToIndustries = () => navigate(ROUTES.INDUSTRIES, {replace: true});

	const addIndustry = async values => {
		setLoading(true);

		try {
			await industriesService.create(values);
			setLoading(false);
			backToIndustries();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Add a new industry to Metaphoria"
					primaryButton="Add industry"
					onSubmit={addIndustry}
					onCancel={backToIndustries}
				>
					<Form.Item
						className="file-name"
						name="name"
						label="Industry name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea" rows={4} />
					</Form.Item>
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default NewIndustry;
