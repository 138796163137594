import { CheckCircleFilled } from '@ant-design/icons';
import { Progress } from 'antd';
import React from 'react';

const ProgressCustom = ({value, total, status}) => {

	const percentageShow = () => {
		if (status === 'archived') {
			return <span>Archived</span>;
		} else if (value === total) {
			return <CheckCircleFilled />;
		}

		return <span>{value}/{total}</span>;

	};

	const getPercentage = (actualValue, total) => {
		return (actualValue/total) * 100;
	};

	return (
		<Progress className={status === 'archived' ? 'archived' : ''} percent={getPercentage(value, total)} format={() => percentageShow()} />
	);
};

export default ProgressCustom;
