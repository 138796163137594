import { Client } from './Client';

export enum ProjectStatus {
	DISABLED = 0,
	ENABLED = 1,
}

export interface Project {
	key: number;
	name: string;
	description: string;
	client: Client;
	updatedAt: Date;
	status?: ProjectStatus;
}

export interface ApiProjectBase {
	id: number;
	name: string;
	description: string;
	updatedAt: Date;
	status?: ProjectStatus;
}

export interface ApiProject extends ApiProjectBase {
	client: Client;
}

export interface ApiProjectWithClientId extends ApiProjectBase {
	clientId: number;
}
