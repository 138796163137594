import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { Graph, IG6GraphEvent } from '@antv/g6';
import { Map as MapInterface, MapNodePosition } from '../../interfaces/Map';
import ConstructTags from '../ConstructTags/ConstructTags';
import React from 'react';
import SegmentsBreakdownModal from '../SegmentsBreakdownModal/SegmentsBreakdownModal';
import styles from './index.module.less';
import UseMapController from './useMapController';

interface MapProps {
	map: MapInterface,
	setExternalGraph: React.Dispatch<React.SetStateAction<Graph | null>>,
	allowLinkCreation?: boolean,
	allowMergeCreation?: boolean,
	useForceLayout?: boolean
	addLink?: (sourceId : string, targetId: string) => void,
	addNodePosition?: (position: MapNodePosition | MapNodePosition[]) => void,
	addNewLink?: (sourceId: string, targetId: string) => void,
	addMerge?: (construct1Id: number, construct2Id: number, id: number, name: string, description: string, type: number) => void,
	onLoadMap?: (evt: IG6GraphEvent, graph: Graph) => void
}

const Map: React.FC<MapProps> = ({ map, setExternalGraph, allowLinkCreation = false, useForceLayout = true, addLink = undefined, allowMergeCreation = false, addMerge = undefined, addNodePosition = undefined, onLoadMap = undefined }) => {

	const {addCombo, createCombo, containerHtmlId, constructsId, constructsType, editorContainerRef, form, handleClose, isMergeButtonDisabled, isModalOpen, newConstructType, setNewConstructType} = UseMapController(allowLinkCreation, setExternalGraph, useForceLayout, addNodePosition, addLink, addMerge, map, onLoadMap);

	const { TextArea } = Input;

	return (
		<>
			<SegmentsBreakdownModal onClose={handleClose} constructsId={constructsId} />
			<div className={styles.editorContainer} ref={editorContainerRef}>
				<div className={styles.editBox} id={containerHtmlId} >
					<div style={{ position: 'absolute', right: 0, top: 0 }}>
						{allowMergeCreation && <button onClick={() => addCombo()} disabled={isMergeButtonDisabled}>Merge</button>}
					</div>
					<div style={{ position: 'absolute', right: 0, bottom: 0, marginRight: '20px', marginBottom: '20px' }}>
						<div id="minimap-container" style={{ backgroundColor: 'white', boxShadow: '0 3px 6px -4px rgba(0,0,0,0.12), 0 6px 16px 0 rgba(0,0,0,0.08), 0 9px 28px 8px rgba(0,0,0,0.05)' }}></div>
					</div>
				</div>
			</div>
			<Modal
				title={'Create new construct from merge'}
				centered
				open={isModalOpen}
				width={800}
				destroyOnClose
				onCancel={handleClose}
				footer={[
					<Button key="back" onClick={handleClose}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						htmlType="submit"
						onClick={form.submit}
					>
						Merge
					</Button>,
				]}
			>
				<Form
					form={form}
					onFinish={createCombo}
				>
					<Form.Item
						className="field-name project-construct"
						name="type"
						label="Construct type"
						required
					>
						<Row justify="space-between">
							{constructsType.map(constructType => (
								<Col key={constructType.id}>
									<ConstructTags
										label={constructType.value}
										active={newConstructType === constructType.id ? true : false}
										color={constructType.color}
										onClick={() => setNewConstructType(constructType.id)}
									/>
								</Col>
							))}
						</Row>
					</Form.Item>
					<Form.Item
						className="field-name"
						name="name"
						label="Construct name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Definition"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea project-construct" rows={3} />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default Map;
