import './ProjectDuplicate.less';
import { Checkbox, Form, Input, Layout, Select, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalystRole } from '../../interfaces/Analyst';
import { ClientStatus } from '../../interfaces/Client';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DuplicateProjectConfirmationModal } from '../../components';
import React from 'react';
import { ROUTES } from '../../constants/routes-path';
import useProjectDuplicateController from './UseProjectDuplicateController';
import { UserContext } from '../../providers/UserProvider';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const ProjectDuplicate = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const { projectName, loading, form, clients, duplicateProject, isOpenModal, closeModal, openModal, getProjectContent } = useProjectDuplicateController(projectId);
	const navigate = useNavigate();
	const { user } = React.useContext(UserContext);

	const routes = [
		{ path: ROUTES.PROJECTS, breadcrumbName: 'Projects' },
		{ path: projectId, breadcrumbName: projectName },
		{ path: '', breadcrumbName: 'Duplicate' },
	];

	return (
		<>
			{isOpenModal && (<DuplicateProjectConfirmationModal onCancel={closeModal} onConfirm={duplicateProject} open projectContent={getProjectContent()} objectsToDuplicate={form.getFieldValue('objects')}/>)}
			<Layout className='project-duplicate-layout' style={{ height: '100vh' }}>
				<Content style={{ width: '100%' }}>
					<CustomBreadcrumb routes={routes} />
					<CommonForm
						title="Duplicate project"
						primaryButton="Duplicate project"
						onSubmit={openModal}
						onCancel={()=>navigate(ROUTES.PROJECTS)}
						secondClass="update-project"
						form={form}
						disabled={loading}
					>
						<Form.Item className="field-name" name="name" label="Project Name" rules={[{ required: true }, { min: 2 }, { max: 100 }]}>
							<Input />
						</Form.Item>
						<Form.Item className="field-name" name="client" label="Client" rules={[{ required: true }]}>
							<Select
								className="client-select"
								allowClear
								style={{ width: '100%' }}
								placeholder="Select client"
							>
								{clients.map((client, index) => (
									<Option key={index} value={client.key} disabled={client.status === ClientStatus.DISABLED}>
										{client.name}
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item style={{ marginBottom: 0 }} name="description" label="Description" rules={[{ required: true }]}>
							<TextArea className="project common-textarea" rows={3} />
						</Form.Item>
						<Form.Item style={{ marginBottom: 0 }} name="objects" label="Select objects to duplicate" rules={[{ required: true }]}>
							<CheckboxGroup className='objects-checkbox-group' >
								<Checkbox value="segments">Segments</Checkbox>
								<Checkbox value="types">Types</Checkbox>
								<Checkbox value="constructs">Constructs</Checkbox>
								{user?.role === AnalystRole.ADMIN || user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN && <Checkbox value="transcripts">Transcripts and Segments</Checkbox>}
							</CheckboxGroup>
						</Form.Item>
					</CommonForm>
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
				</Content>
			</Layout>
		</>
	);
};

export default ProjectDuplicate;
