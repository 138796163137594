const PROJECT_ROUTES = {
	TRANSCRIPTS: 'TRANSCRIPTS',
	SEGMENTS: 'SEGMENTS',
	TYPES: 'TYPES',
	CONSTRUCTS: 'CONSTRUCTS',
	ADDCONSTRUCTS: 'ADD-CONSTRUCTS',
	LEXICON: 'LEXICON',
	MAP: 'MAP',
	DASHBOARD: 'DASHBOARD',
};

export default PROJECT_ROUTES;
