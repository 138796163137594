import { Construct, ConstructAddListItem, ConstructType, ConstructWithLanguage } from '../../interfaces/Construct';
import { Filters, Sorter } from '../../interfaces/Api';
import { useContext, useEffect, useState } from 'react';
import ConstructsService from '../../services/ConstructsService';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import { Language } from '../../interfaces/Language';
import LanguageService from '../../services/LanguageService';
import PROJECT_ROUTES from '../../constants/project-constants';
import ProjectConstructsService from '../../services/Project/ProjectConstructsService';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectsService from '../../services/ProjectsService';
import { ROUTES } from '../../constants/routes-path';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { useNavigate } from 'react-router-dom';
import useUnsavedChangesWarning from '../../utils/Hooks/UseUnsavedChangesWarning';

interface ProjectAddConstructsControllerReturn {
	constructs: ConstructAddListItem[];
	selectedConstructs: ConstructSidebar[];
	languages: Language[];
	loading: boolean;
	totalCount: number;
	reload: boolean;
	getConstructsData: (
		filters: Filters | null,
		sorter: Sorter | null,
		pagination: TablePaginationConfig,
	) => Promise<void>;
	toggleSelectedConstruct: (element: ConstructAddListItem) => void;
	removeSelectedConstruct: (element: ConstructSidebar) => void;
	onAddSelected: () => Promise<void>;
	getConstructsType: () => void;
	constructTypes: ConstructType[];
	getProjectData: () => void;
}
interface ConstructSidebar {
	id: number;
	text: string;
	type?: ConstructType;
}

const useProjectAddConstructsController = (projectId: string): ProjectAddConstructsControllerReturn => {
	const constructsService = new ConstructsService();
	const languageService = new LanguageService();
	const projectConstructsService = new ProjectConstructsService(+projectId);
	const projectsService = new ProjectsService();
	const navigate = useNavigate();
	const { setError } = useContext(ErrorContext);
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);

	const { onDirty, onPristine } = useUnsavedChangesWarning(
		'There is missing construct changes unsaved for this project. If you continue, you will loose the changes.'
	);
	const [isFirstFetch, setIsFirstFetch] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [reload, setReload] = useState<boolean>(false);
	const [totalCount, setTotalCount] = useState(0);
	const [isNavigate, setIsNavigate] = useState(false);
	const [constructs, setConstructs] = useState<ConstructAddListItem[]>([]);
	const [languages, setLanguages] = useState<Language[]>([]);
	const [selectedConstructs, setSelectedConstructs] = useState<ConstructSidebar[]>([]);
	const [constructTypes, setConstructTypes] = useState<ConstructType[]>([]);

	useEffect(() => {
		if (isNavigate) {
			navigate(ROUTES.PROJECT_CONSTRUCTS(projectId));
			setIsNavigate(false);
		}
	}, [isNavigate, navigate, projectId]);

	useEffect( () => {
		languageService.getLanguages().then((response) => {
			setLanguages(response);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getConstructsData = async (
		filters: Filters | null,
		sorter: Sorter | null,
		pagination: TablePaginationConfig
	) => {
		setLoading(true);

		const pageSize = pagination?.pageSize ?? DEFAULT_PAGINATION.pageSize;
		const currentPage = pagination?.current ?? DEFAULT_PAGINATION.current;

		try {
			const constructsResult = await constructsService.getAll(
				filters,
				sorter,
				pagination.pageSize,
				(currentPage - 1) * pageSize
			);

			let constructsRows: ConstructWithLanguage[] = [];

			if (constructsResult) {
				setTotalCount(constructsResult.totalCount);
				constructsRows = constructsResult.data;
			}

			const projectConstructs =
				(await projectConstructsService.getAllWithoutPagination())?.data || ([] as Construct[]);

			const constructsParsed = [] as ConstructAddListItem[];

			constructsRows.forEach(construct => {
				const constructForm: ConstructAddListItem = {
					name: construct.name,
					type: construct.type,
					description: construct.description,
					language: construct.language,
					color: construct.type?.color,
					id: construct.id,
					usage: construct.usage,
					isSelected: isFirstFetch
						? projectConstructs.some(projectConstruct => projectConstruct.id === construct.id)
						: selectedConstructs.some(selectedConstruct => selectedConstruct.id == construct.id),
					isDisabled: projectConstructs.some(
						projectConstruct => projectConstruct.id === construct.id && projectConstruct.disabled
					),
				};

				constructsParsed.push(constructForm);
			});

			setConstructs(constructsParsed);

			if (isFirstFetch) {
				setSelectedConstructs(
					projectConstructs
						.filter(projectConstruct => {
							return !projectConstruct.disabled;
						})
						.map(projectConstruct => ({
							id: projectConstruct.id,
							text: projectConstruct.name,
							type: projectConstruct.type,
						}))
				);
				setIsFirstFetch(false);
			}
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	const toggleSelectedConstruct = (element: ConstructAddListItem) => {
		if (selectedConstructs.some(c => c.id === element.id || element.isDisabled)) {
			element.isSelected = false;
			selectedConstructs.splice(
				selectedConstructs.findIndex(selected => selected.id === element.id),
				1
			);
			setSelectedConstructs([...selectedConstructs]);
		} else {
			element.isSelected = true;
			selectedConstructs.push({
				id: element.id,
				text: element.name,
				type: element.type,
			});
			setSelectedConstructs([...selectedConstructs]);
		}

		onDirty();
	};

	const removeSelectedConstruct = (element: ConstructSidebar) => {
		selectedConstructs.splice(
			selectedConstructs.findIndex(selected => selected.id === element.id),
			1
		);
		setSelectedConstructs([...selectedConstructs]);

		setReload(prev => !prev);
		onDirty();
	};

	const onAddSelected = async () => {
		try {
			setLoading(true);

			const selectedConstructsParsed: Construct[] = selectedConstructs.map(element => ({
				id: element.id,
				name: element.text,
				type: element.type,
			}));

			await projectConstructsService.update(selectedConstructsParsed);
			setLoading(false);
			onPristine();
			setIsNavigate(true);
		} catch (err) {
			setLoading(false);
			setError(err as string);
		}
	};

	const getConstructsType = () => {
		const constructTypes = constructsService.getAllConstructsType();

		setConstructTypes(constructTypes);
	};

	const getProjectData = () => {
		projectsService.get(+projectId).then(data => {
			getConstructsType();
			const actualRoutes = [
				{
					path: 'projects',
					breadcrumbName: 'Projects',
				},
				{
					path: projectId,
					breadcrumbName: data.name,
				},
				{
					path: 'add-constructs',
					breadcrumbName: 'Add constructs',
				},
			];

			setRoutes(actualRoutes);
			setActive(PROJECT_ROUTES.CONSTRUCTS);
		});
	};

	return {
		constructs,
		selectedConstructs,
		languages,
		loading,
		totalCount,
		reload,
		constructTypes,
		getConstructsData,
		toggleSelectedConstruct,
		removeSelectedConstruct,
		getConstructsType,
		getProjectData,
		onAddSelected,
	};
};

export default useProjectAddConstructsController;
