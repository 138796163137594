import './ProjectTypes.less';
import { Button, Divider, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import PROJECT_ROUTES from '../../constants/project-constants';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectsService from '../../services/ProjectsService';
import ProjectTypesService from '../../services/Project/ProjectTypesService';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';

const ProjectTypes = () => {
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [types, setTypes] = useState([]);
	const { setError } = useContext(ErrorContext);
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);
	const navigate = useNavigate();
	const { Text } = Typography;
	const { projectId } = useParams();
	const typesService = new ProjectTypesService(projectId, setError);
	const [sorter, setSorter] = useState(undefined);
	const projectsService = new ProjectsService();
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const { user } = useContext(UserContext);

	const getSelectedName = () => {
		return selected && selected.name ? `type ${selected.name}?` : 'type?';
	};

	const resetSelected = () => {
		setSelected(null);
	};

	const displayOptionsRender = options =>
		options.map((element, index) => {
			return index + 1 < options.length
				? [element.name, <Divider key={index} style={{ backgroundColor: '#424B5A' }} type="vertical" />]
				: element.name;
		});

	const displayModalDescriptionRender = () => {
		return [
			<Text key={'types1'}> Are you sure you want to delete the </Text>,
			<Text key={'types2'} strong>
				{getSelectedName()}
			</Text>,
		];
	};

	const editType = item => navigate(ROUTES.PROJECT_TYPE_DETAILS(projectId, item.key), {replace: true});

	const toggleShowModal = item => {
		setShowModal(!showModal);

		if (item && item.key) {
			setSelected(item);
		}
	};

	const actions = item => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Edit type">
					<EditOutlined style={{ color: '#1991EB' }} onClick={() => editType(item)} />
				</Tooltip>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				<Tooltip placement="top" title="Delete type">
					<DeleteOutlined style={{ color: '#1991EB' }} onClick={() => toggleShowModal(item)} />
				</Tooltip>
			</div>
		);
	};

	const columns = [
		{
			title: 'Types',
			dataIndex: 'name',
			render: (_, type) => <Link to={ROUTES.PROJECT_TYPE_DETAILS(projectId, type.key)}>{type.name}</Link>,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Options',
			dataIndex: 'options',
			render: options => displayOptionsRender(options),
			sortDirections: ['descend', 'ascend'],
		},
	];

	if (user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) {
		columns.push({
			title: 'Actions',
			dataIndex: '',
			align: 'right',
			key: 'x',
			render: element => actions(element),
		});
	}

	const getTypes = () => {
		setLoading(true);

		typesService
			.getAll(undefined, sorter, pagination.pageSize, (pagination.current - 1) * pagination.pageSize)
			.then(result => {
				if (result !== undefined) {
					setTypes(result.data);
					setTotalCount(result.totalCount);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const deleteType = async () => {
		setLoading(true);
		toggleShowModal();

		try {
			await typesService.delete(selected.key);
			getTypes();
		} catch (err) {
			setError(err.toString());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		projectsService.get(projectId).then(data => {
			getTypes();
			const actualRoutes = [
				{
					path: 'projects',
					breadcrumbName: 'Projects',
				},
				{
					path: projectId,
					breadcrumbName: data.name,
				},
				{
					path: 'types',
					breadcrumbName: 'Types',
				},
			];

			setRoutes(actualRoutes);
			setActive(PROJECT_ROUTES.TYPES);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sorter, pagination]);

	const onClick = () => {
		navigate(ROUTES.PROJECT_NEW_TYPE(projectId));
	};

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				name: 'name',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(undefined);
			} else {
				setSorter({ sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}` });
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	return (
		<div className="ProjectTypes">
			<div className="filters-container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
				{
					(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
						<Button type="primary" onClick={onClick} icon={<PlusOutlined />}>
							Create new type
						</Button>
					)
				}
			</div>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={types}
					loading={loading}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle="Yes, delete type"
				handleOk={deleteType}
				handleCancel={toggleShowModal}
				description={displayModalDescriptionRender()}
			/>
		</div>
	);
};

export default ProjectTypes;
