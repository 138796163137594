import './ConstructDetails.less';

import { Badge, Col, Form, Input, Layout, List, Row, Select, Space, Spin, Typography } from 'antd';
import CommonForm from '../../components/CommonForm/CommonForm';
import ConstructTags from '../../components/ConstructTags/ConstructTags';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import React from 'react';
import useConstructDetailsController from './useConstructDetailsController';

const ConstructDetails = () => {
	const { loading, form, constructsType, languages, constructName, relatedIndustries, relatedProjects, updateConstruct, backToConstructs, activeValue, setActiveValue } = useConstructDetailsController();
	const { TextArea } = Input;
	const { Content } = Layout;
	const { Text, Link } = Typography;
	const { Option } = Select;

	const routes = [
		{ path: '/constructs', breadcrumbName: 'Constructs' },
		{ path: '', breadcrumbName: constructName },
	];

	return (
		<Layout className='ConstructDetails'>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Construct details"
					primaryButton="Save construct"
					onSubmit={updateConstruct}
					onCancel={backToConstructs}
					secondClass="project-construct-details"
					form={form}
					disabled={loading}
				>
					<Form.Item
						className="field-name"
						name="type"
						label="Construct type"
						required
					>
						<Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
							{constructsType.map((constructType) => (
								<Col key={constructType.id} style={{ margin: 5 }}>
									<ConstructTags
										label={constructType.value}
										active={activeValue === constructType.id ? true : false}
										color={constructType.color}
										onClick={() => setActiveValue(constructType.id)}
									/>
								</Col>
							))}
						</Row>
					</Form.Item>
					<Form.Item
						className="field-name"
						name="name"
						label="Construct name"
						rules={[{ required: true }, { min: 2 }, { max: 255 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Definition"
						rules={[{ required: true }, { min: 2 }, { max: 5000 }]}
					>
						<TextArea className="common-textarea project-construct" rows={3} />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="language"
						label="Construct Language"
						rules={[{ required: true }]}
					>
						<Select
							allowClear
							placeholder="Select language"
						>
							{languages.map((language) => (
								<Option key={language.code} value={language.code}>
									{language.name}
								</Option>
							))}
						</Select>
					</Form.Item>

					<Space style={{ width: '100%' }} direction="vertical" size={9}>
						<Text strong className="project-construct-text">
							Used in these projects
						</Text>
						<div className="list-container construct-details">
							<List
								style={{ width: '489px' }}
								dataSource={relatedProjects}
								renderItem={(item) => (
									<List.Item style={{ paddingTop: '5px', paddingBottom: '5px' }} >
										<Space direction='vertical' style={{ width: '100%' }}>
											<Row justify="space-between" style={{ width: '100%' }}>
												<Col style={{ lineHeight: '18px' }}>
													<Link style={{ lineHeight: '22px' }} href={`/projects/${item.project.key}`}> {item.project.name}  </Link>
													<Text style={{ lineHeight: '18px' }} type="secondary" >({item.project.client.industry?.name})</Text>
												</Col>
												<Col>
													<Badge
														count={item.count}
														style={{ backgroundColor: '#fff', borderColor: '#D46B08', color: '#5F5D70' }}
													/>
												</Col>
											</Row>
											<Row style={{ width: '100%' }}>
												<Col style={{ lineHeight: '18px' }} >
													{item.project.description}
												</Col>
											</Row>
										</Space>
									</List.Item>
								)}
							/>
						</div>
					</Space>

					<Space style={{ width: '100%' }} direction="vertical" size={9}>
						<Text strong className="project-construct-text">
							Used in these industries
						</Text>
						<div className="list-container construct-details">
							<List
								style={{ width: '489px' }}
								dataSource={relatedIndustries}
								renderItem={(item) => (
									<List.Item style={{ paddingTop: '5px', paddingBottom: '5px' }} >
										<Row justify="space-between" style={{ width: '100%' }}>
											<Col >
												<Text >{item.industry.name}</Text>
											</Col>
											<Col>
												<Badge
													count={item.count}
													style={{ backgroundColor: '#fff', borderColor: '#D46B08', color: '#5F5D70' }}
												/>
											</Col>
										</Row>
									</List.Item>
								)}
							/>
						</div>
					</Space>
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default ConstructDetails;
