export const COLOR_MAGENTA_HEX = '#CC339C';
export const COLOR_TEAL_HEX = '#00826A';
export const COLOR_BLUE_HEX = '#2C73D1';
export const COLOR_ORANGE_HEX = '#CC4D08';
export const COLOR_PURPLE_HEX = '#8753DB';
export const COLOR_SLATE_BLUE_HEX = '#465472';
export const COLOR_GOLD_HEX = '#9E6700';
export const COLOR_BUBBLES_HEX = '#e6f7ff';
export const COLOR_BRIGHT_GRAY_HEX = '#ececeb';
export const COLOR_CULTURED_HEX = '#f7f7f7';
export const COLOR_WITHE_HEX = '#fff';
export const COLOR_LIGHT_GREY_HEX = '#d3d3d3';
