import './SegmentsBreakdownModal.less';
import { Button, Tree } from 'antd';
import useSegmentsBreakdownModalController, { ConstructsId } from './useSegmentsBreakdownModalController';
import { ApiConstruct } from '../../interfaces/Construct';
import ConstructsService from '../../services/ConstructsService';
import Loading from '../Loading/Loading';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { SwapOutlined } from '@ant-design/icons';

interface SegmentsBreakdownModalProps {
	constructsId?: ConstructsId;
	onClose: () => void;
}

interface TitleComponentProps {
	constructLeft: ApiConstruct;
	ConstructRight: ApiConstruct;
}

const TitleComponent: React.FC<TitleComponentProps> = ({ constructLeft, ConstructRight }) => {
	return (
		<div className="title">
			<span style={{ color: ConstructsService.getConstructTypeById(constructLeft.type)?.color }}>{constructLeft.name}</span>
			<SwapOutlined className="swap-icon" />
			<span style={{ color: ConstructsService.getConstructTypeById(ConstructRight.type)?.color }}>{ConstructRight.name}</span>
		</div>
	);
};

const SegmentsBreakdownModal: React.FC<SegmentsBreakdownModalProps> = ({ constructsId, onClose }) => {
	const { loading, constructLeft, constructRight, handleClose, totalCount, tree } = useSegmentsBreakdownModalController(onClose, constructsId);

	return (
		<Modal
			okType="primary"
			className="segments-breakdown-modal"
			title={
				!loading && constructLeft && constructRight && <TitleComponent constructLeft={constructLeft} ConstructRight={constructRight} />
			}
			onCancel={handleClose}
			onOk={handleClose}
			width="600px"
			centered
			open={!!constructsId}
			wrapClassName="modal-content"
			footer={
				<Button type='primary' onClick={onClose}>OK</Button>
			}
		>
			{loading ? <div className='loading-container'><Loading /></div> : (
				<>
					<div className="total-count-container">
				Count<span className="count-number">{totalCount}</span>
					</div>
					<div className='segments-breakdown-tree-container'>
						<Tree className="segments-breakdown-tree" treeData={tree} />
					</div>
				</>)}
		</Modal>
	);
};

export default SegmentsBreakdownModal;
