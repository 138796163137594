import './CollapsableProject.less';
import { Col, Row, Space, Tag, Typography } from 'antd';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import ClientsService from '../../services/ClientsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import MapColors from '../../utils/MapColorsForTag';
import moment from 'moment';
import ProgressCustom from '../ProgressCustom/ProgressCustom';
import ProjectsService from '../../services/ProjectsService';
import TextArea from 'antd/lib/input/TextArea';
import { useParams } from 'react-router-dom';

const dateFormat = 'MM/DD/YYYY';
const { Text, Title } = Typography;

const CollapsableProject = () => {
	const [analysts, setAnalysts] = useState([]);
	const [client, setClient] = useState({
		id: 0,
		industry: {
			name: 'UNKNOWN',
		},
		name: 'UNKNOWN',
	});
	const [lastEditedProject, setLastEditedProject] = useState({
		updateDate: '',
		updateBy: '',
	});
	const [mostUsedConstructs, setMostUsedConstructs] = useState([]);
	const [transcriptsCount, setTranscriptsCount] = useState({
		total: 0,
		complete: 0,
	});
	const [loading, setLoading] = useState(false);
	const [projectDescription, setProjectDescription] = useState('');
	const [showBody, setShowBody] = useState(false);
	const { setError } = useContext(ErrorContext);
	const { projectId } = useParams();
	const projectsService = new ProjectsService();
	const clientsService = new ClientsService();

	const getAnalysts = (id) => {
		setLoading(true);
		clientsService
			.getAnalystsByClientId(id)
			.then((result) => {
				if (result !== undefined) {
					setAnalysts(result);
				}
			})
			.catch((err) => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getMostUsedConstructs = (id) => {
		setLoading(true);
		projectsService
			.getMostUsedConstructs(id)
			.then((result) => {
				if (result !== undefined) {
					setMostUsedConstructs(result);
				}
			})
			.catch((err) => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getProject = (id) => {
		setLoading(true);

		projectsService
			.get(id)
			.then(async (result) => {
				if (result !== undefined) {
					setProjectDescription(result.description);
					setClient({
						...client,
						...result.client,
					});
					getAnalysts(result.client.id);
					getMostUsedConstructs(id);
					setLastEditedProject({
						...lastEditedProject,
						updateDate: result.updatedAt,
						updateBy: result.updatedBy
							? (
								result.updatedBy.firstName +
									' ' +
									result.updatedBy.lastName
							).trim()
							: 'UNKNOWN',
					});
					setTranscriptsCount({
						...transcriptsCount,
						total: result.transcriptsCount,
						complete: result.completedTranscriptsCount,
					});
				}
			})
			.catch((err) => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const toggleShowBody = () => {
		setShowBody(!showBody);
	};

	useEffect(() => {
		getProject(projectId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		!loading && (
			<div className="CollapsableProject">
				<div className="collapsable-header">
					<div className="arrow-toggle" onClick={toggleShowBody}>
						{!showBody ? (
							<DownCircleOutlined />
						) : (
							<UpCircleOutlined />
						)}
					</div>
					<Row>
						<Col span={4} order={1}>
							<Title className="small-title" level={5}>
								CLIENT
							</Title>
							<Text strong>{client?.name}</Text>
						</Col>
						<Col span={4} order={2}>
							<Title className="small-title" level={5}>
								INDUSTRY
							</Title>
							<Text strong>{client?.industry?.name}</Text>
						</Col>
						<Col span={4} order={3}>
							<Title className="small-title" level={5}>
								TRANSCRIPTS
							</Title>
							<Text strong>{transcriptsCount.total}</Text>
						</Col>
						<Col span={4} order={4}>
							<Title className="small-title" level={5}>
								LAST EDIT
							</Title>
							<Text strong>
								{moment(lastEditedProject.updateDate).format(
									dateFormat
								)}
							</Text>{' '}
							<Text>by</Text>{' '}
							<Text strong>{lastEditedProject.updateBy}</Text>
						</Col>
						<Col span={4} order={5}>
							<Title className="small-title" level={5}>
								STATUS
							</Title>
							{
								transcriptsCount.total ? (
									<ProgressCustom
										value={transcriptsCount.complete}
										total={transcriptsCount.total}
										status="ongoing"
									/>
								) : (
									'No transcripts found'
								)
							}
						</Col>
					</Row>
				</div>
				<div
					className={
						showBody
							? 'collapsable-body active'
							: 'collapsable-body hidden'
					}
				>
					<div className="collapsable-body-container">
						<Row gutter={41}>
							<Col lg={12}>
								<Title className="small-title" level={5}>
									DESCRIPTION
								</Title>
								<TextArea
									readOnly
									rows="3"
									value={projectDescription}
								/>
							</Col>
							<Col lg={12}>
								<Title className="small-title" level={5}>
									Analysts
								</Title>
								<Space size={10} direction="horizontal">
									{analysts.map((analyst, index) => (
										<a key={index}>
											{analyst.firstName +
												' ' +
												analyst.lastName}
										</a>
									))}
								</Space>
								<Title
									style={{ marginTop: '6px' }}
									className="small-title"
									level={5}
								>
									Most used constructs
								</Title>
								<Space size={10} direction="horizontal">
									{mostUsedConstructs.map((mostUsedConstruct, index) => (
										<Tag key={index} color={MapColors.get(mostUsedConstruct.type.color)}>
											{mostUsedConstruct.name}
										</Tag>
									))}
								</Space>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		)
	);
};

export default CollapsableProject;
