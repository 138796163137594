import { Button, Result } from 'antd';
import React, { useContext, useEffect } from 'react';
import { ErrorContext } from '../../providers/ErrorProvider';
import Loading from '../../components/Loading/Loading';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../providers/UserProvider';
import UsersService from '../../services/UsersService';

const LoadUserInformationLayout = () => {
	const { setError } = useContext(ErrorContext);
	const { setUser } = React.useContext(UserContext);
	const { error: errorAuth0, isLoading, getAccessTokenSilently, isAuthenticated, logout } = useAuth0();
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		(async () => {
			if (isAuthenticated) {
				const accessToken = await getAccessTokenSilently({
					authorizationParams: {
						audience: process.env.REACT_APP_AUTH0_AUDIENCE,
						scope: 'openid email profile',
					}});

				localStorage.setItem('token', accessToken);

				const usersService = new UsersService();

				try {
					setLoading(true);
					const result = await usersService.get();

					if (result !== undefined) {
						setUser(result);
					}
				} catch (err) {
					setError(err as string);
				} finally {
					setLoading(false);
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	if (errorAuth0) {
		return (
			<Result
				status="403"
				title="Unauthorized"
				subTitle={`Oops... ${errorAuth0.message === 'user_disabled' ? 'Your account is disabled. Check with your admin.' : errorAuth0.message}`}
				extra={<Button onClick={() => logout(
					{
						logoutParams: {
							returnTo: window.location.origin,
						},
					})
				} type="primary" key="back">Back</Button>}
			/>
		);
	}

	if (isLoading || loading) {
		return <Loading />;
	}

	return <Outlet />;
};

export default LoadUserInformationLayout;
