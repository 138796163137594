import {
	Form,
	Input,
	Layout,
	Select,
	Spin
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalystRole } from '../../interfaces/Analyst';
import ClientsService from '../../services/ClientsService';
import { ClientStatus } from '../../interfaces/Client';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import IndustriesService from '../../services/IndustriesService';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';

const ClientDetails = () => {
	const [clientName, setClientName] = useState('');
	const [clientStatus, setClientStatus] = useState(ClientStatus.DISABLED);
	const [industries, setIndustries] = useState([]);
	const [loading, setLoading] = React.useState(false);
	const { setError } = React.useContext(ErrorContext);
	const { user } = React.useContext(UserContext);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { id } = useParams();
	const industriesService = new IndustriesService();
	const clientsService = new ClientsService();
	const { Content } = Layout;
	const { Option } = Select;
	const { TextArea } = Input;
	const routes = [
		{ path: '/', breadcrumbName: 'Admin' },
		{ path: '/clients', breadcrumbName: 'Clients' },
		{ path: '', breadcrumbName: clientName },
	];

	const backToClients = () => navigate(ROUTES.CLIENTS, { replace: true });

	const updateClient = async (values) => {
		setLoading(true);

		try {
			await clientsService.update(id, {
				...values,
				industry: {
					id: values.industry,
				},
			});
			setLoading(false);
			backToClients();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const getClient = id => {
		setLoading(true);

		clientsService
			.get(id)
			.then(data => {
				setClientName(data.name);
				setClientStatus(data.status);

				form.setFieldsValue({
					name: data.name,
					description: data.description,
					industry: data.industry.id,
				});
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getIndustries = () => {
		setLoading(true);

		industriesService
			.getAllWithoutPagination()
			.then(result => {
				if (result !== undefined) {
					setIndustries(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getClient(id);
		getIndustries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Client details"
					primaryButton="Save client"
					onCancel={backToClients}
					onSubmit={updateClient}
					form={form}
					disabled={(loading || clientStatus === ClientStatus.DISABLED) && user?.role !== AnalystRole.GLOBAL_PARTNER_ADMIN}
				>
					<Form.Item
						className="field-name"
						name="name"
						label="Client Name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input disabled={user?.role !== AnalystRole.GLOBAL_PARTNER_ADMIN} />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea" rows={4} disabled={user?.role !== AnalystRole.GLOBAL_PARTNER_ADMIN} />
					</Form.Item>
					<Form.Item
						className="edit-client-industries"
						name="industry"
						label="Industry"
						rules={[{ required: true }]}
					>
						<Select
							className="industry-select"
							allowClear
							style={{ width: '100%' }}
							placeholder="Select industry"
							disabled={user?.role !== AnalystRole.GLOBAL_PARTNER_ADMIN}
							defaultValue={form.industry}
						>
							{industries.map((industry, index) => (
								<Option key={index} value={industry.key} >
									{industry.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					{loading && (
						<Spin style={{ display: 'flex', justifyContent: 'center' }} />
					)}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default ClientDetails;
