import axios from 'axios';

import Configuration from './ServiceConfig';
import ConstructsService from './ConstructsService';

const periods = [
	{ name: 'All time', value: 1 },
	{ name: 'One month', value: 2 },
	{ name: 'Six months', value: 3 },
];

class DashboardService {
	constructService = new ConstructsService();

	config: Configuration;
	path: string;
	setError: (value: string) => void;
	token: string | null;

	constructor(setError: (value: string) => void) {
		this.config = new Configuration();
		this.path = `${this.config.baseurl}${'dashboard/'}`;
		this.setError = setError;
		this.token = localStorage.getItem('token');
	}

	async getLatestConstructs(industryId?: number): Promise<any> {
		let params = {};

		if (industryId) {
			params = { ...params, industryId };
		}

		return axios
			.get(`${this.path}latest-constructs`, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return this.constructService.getAllConstructsType().map(constructType => {
					return {
						color: constructType.color,
						constructs: response.data.filter((construct: any) => construct.type == constructType.id),
						name: constructType.value,
					};
				});
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getMostUsedConstructs(industryId?: number, period?: number): Promise<any> {
		let params = {};

		if (industryId) {
			params = { ...params, industryId };
		}

		if (period) {
			params = { ...params, period };
		}

		return axios
			.get(`${this.path}most-used-constructs`, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return this.constructService.getAllConstructsType().map(constructType => {
					return {
						color: constructType.color,
						constructs: response.data.filter((construct: any) => construct.type == constructType.id),
						name: constructType.value,
					};
				});
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getPeriods(): Promise<any[]> {
		return await periods;
	}

	async getRecentProjects(): Promise<any> {
		return axios
			.get(`${this.path}recent-projects`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return {
					data: response.data.rows.map((row: any) => {
						return {
							...row as any,
							updatedBy: row.updatedBy ? (row.updatedBy.firstName + ' ' + row.updatedBy.lastName).trim() : undefined,
						};
					}),
					totalCount: response.data.count,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	handleResponseError(response: { status: string }): void {
		this.setError(response.status);
	}

	handleError(error: { message: string }): void {
		this.setError(error.message);
	}
}

export default DashboardService;
