import './Partners.less';
import {
	Button,
	Col,
	Divider,
	Input,
	Row,
	Switch,
	Table,
	Tooltip,
	Typography
} from 'antd';
import {
	EditOutlined,
	PlusOutlined,
	SearchOutlined
} from '@ant-design/icons';
import { BUTTON_TYPES } from '../../constants/button-types';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import PartnersService from '../../services/PartnersService';
import { PartnerStatus } from '../../interfaces/Partner';
import React from 'react';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

const Partners = () => {
	const [filters, setFilters] = React.useState({ name: '' });
	const [pagination, setPagination] = React.useState(DEFAULT_PAGINATION);
	const [sorter, setSorter] = React.useState(undefined);
	const [loading, setLoading] = React.useState(false);
	const [partners, setPartners] = React.useState([]);
	const [selected, setSelected] = React.useState(null);
	const [showModal, setShowModal] = React.useState(false);
	const [totalCount, setTotalCount] = React.useState(0);
	const navigate = useNavigate();
	const { setError } = React.useContext(ErrorContext);
	const partnersService = new PartnersService();
	const [buttonModalType, setButtonModalType] = React.useState(BUTTON_TYPES.danger);
	const { Text } = Typography;
	const routes = [
		{
			path: '/',
			breadcrumbName: 'Admin',
		},
		{
			path: '/partners',
			breadcrumbName: 'Partners',
		},
	];

	const goToAddNewPartner = () => {
		navigate(ROUTES.NEW_PARTNERS);
	};

	const toggleShowModal = (disabled, item) => {
		setShowModal(!showModal);
		setButtonModalType(disabled ? BUTTON_TYPES.primary : BUTTON_TYPES.danger);

		if (item && item.key) {
			setSelected(item);
		}
	};

	const editPartner = id => navigate(ROUTES.PARTNER_DETAILS(id));

	const actions = item => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Edit partner">
					<EditOutlined
						style={{ color: '#1991EB' }}
						onClick={() => editPartner(item.key)}
					/>
				</Tooltip>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				<Tooltip placement="top" title="Disable partner">
					<Switch
						size="small"
						checked={item.status === PartnerStatus.DISABLED}
						onChange={checked => toggleShowModal(checked, item)}
					/>
				</Tooltip>
			</div>
		);
	};

	const renderLinkedText = (item) => <a onClick={() => editPartner(item.key)}>{item.name}</a>;

	const columns = [
		{
			title: 'Partners',
			dataIndex: 'name',
			render: (_, item) => renderLinkedText(item),
			sorter: true,
			sortDirections: ['ascend', 'descend'],
		},
		{
			title: 'Actions',
			align: 'right',
			dataIndex: '',
			key: 'x',
			render: item => actions(item),
		},
	];

	const getPartners = () => {
		setLoading(true);

		partnersService
			.getAll(filters, sorter, pagination.pageSize, (pagination.current - 1) * pagination.pageSize)
			.then(result => {
				if (result !== undefined) {
					setPartners(result.data);
					setTotalCount(result.totalCount);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const resetSelected = () => setSelected(null);

	const getSelectedName = () =>
		selected && selected.name ? `${selected.name}` : 'unknown';

	const getOkTitle = () => {
		return selected && selected.status === PartnerStatus.ENABLED ? 'Yes, disable' : 'Yes, enable';
	};

	const getModalDescription = () => {
		return [
			<Text key={1} strong>
				The partner {getSelectedName()} has projects.
			</Text>,
			<Text key={2}>
				{' '}
				Please confirm you want to proceed.
			</Text>,
		];
	};

	const toggleDisablePartner = () => {
		setLoading(true);

		partnersService
			.update(selected.key, { status: selected.status === PartnerStatus.DISABLED ? PartnerStatus.ENABLED : PartnerStatus.DISABLED })
			.then(() => {
				getPartners();
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
				toggleShowModal();
			});
	};

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				name: 'name',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(undefined);
			} else {
				setSorter({ sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}` });
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	React.useEffect(() => {
		getPartners();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination]);

	const filterByName = event => {
		if (event.keyCode === 13) {
			setFilters({ ...filters, name: event.target.value });
			setPagination(DEFAULT_PAGINATION);
		}
	};

	return (
		<div className="Partners">
			<Row justify="space-between">
				<Col>
					<CustomBreadcrumb routes={routes} />
				</Col>
				<Col>
					<Row gutter={22} style={{ marginTop: '6px' }}>
						<Col style={{ width: '354px' }}>
							<Input
								width="354"
								placeholder="Search"
								onKeyDown={filterByName}
								suffix={<SearchOutlined />}
							/>
						</Col>
						<Col>
							<Button
								type="primary"
								onClick={goToAddNewPartner}
								icon={<PlusOutlined />}
							>
								Add new partner
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={partners}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
					loading={loading}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle={getOkTitle()}
				handleOk={toggleDisablePartner}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
				okType={buttonModalType}
			/>
		</div>
	);
};

export default Partners;
