import './DashboardHeader.less';
import React, { useCallback, useContext, useEffect } from 'react';
import {ReconciliationOutlined, TeamOutlined} from '@ant-design/icons';
import {Space, Typography} from 'antd';
import ClientsService from '../../services/ClientsService';
import ConstructsService from '../../services/ConstructsService';
import { ErrorContext } from '../../providers/ErrorProvider';

const DashboardHeader = () => {
	const [ totalCountConstructs, setTotalCountConstructs ] = React.useState(0);
	const [ totalCountClients, setTotalCountClients ] = React.useState(0);
	const { setError } = useContext(ErrorContext);
	const clientsService = new ClientsService();
	const constructsService = new ConstructsService(setError);
	const {Text} = Typography;

	const getTotalConstructs = useCallback(async () => {
		const constructs = await constructsService.getAll();

		setTotalCountConstructs(constructs?.totalCount ?? 0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTotalClients = useCallback(async () => {
		const clients = await clientsService.getAll();

		setTotalCountClients(clients?.totalCount ?? 0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect( () => {
		getTotalConstructs();
		getTotalClients();
	}, [getTotalClients, getTotalConstructs]);

	return (
		<div className="header-container">
			<Text style={{fontSize:'30px', color:'#424B5A'}}> Dashboard </Text>
			<div className="header-info">
				<Space direction="vertical" className="display-space">
					<Text style={{fontSize:'11px', color:'#858B94'}}> TOTAL CLIENTS </Text>
					<div className="info">
						<TeamOutlined style={{ color:'#2696FE', fontSize:'19px' }} />
						<Text style={{fontSize:'30px', fontWeight: '300', color:'#424B5A'}}> {totalCountClients} </Text>
					</div>
				</Space>
				<Space direction="vertical" className="display-space">
					<Text style={{fontSize:'11px', color:'#858B94'}}> CONSTRUCTS </Text>
					<div className="info">
						<ReconciliationOutlined style={{ color:'#2696FE', fontSize:'19px' }} />
						<Text style={{fontSize:'30px', fontWeight: '300', color:'#424B5A'}}> {totalCountConstructs} </Text>
					</div>
				</Space>
			</div>
		</div>
	);
};

export default DashboardHeader;
