import { ApiExcerpt, Excerpt } from '../../interfaces/Excerpt';
import { Filters, Sorter } from '../../interfaces/Api';
import axios from 'axios';
import BaseService from '../BaseService';
import Configuration from '../ServiceConfig';
import ConstructsService from '../ConstructsService';
import { Segment } from '../../interfaces/Segment';
import { Transcript } from '../../interfaces/Transcript';

interface Transcripts {
	data: Transcript[];
	totalCount: number;
}

class ProjectTranscriptsService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;
	status = [
		{ key: 1, name: 'Complete' },
		{ key: 2, name: 'Active' },
	];
	type = [
		{
			id: 0,
			name: '',
			options: [''],
			project: 250,
		},
	];

	constructor(projectId: number) {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}projects/${projectId}/transcripts/`;
		this.token = localStorage.getItem('token');
	}

	async getAllWithoutPagination(): Promise<Transcripts | void> {
		return axios
			.get(this.path, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getAll(
		filters: Filters,
		sorter: Sorter,
		limit: number = 10,
		offset: number = 0
	): Promise<Transcripts | void> {
		let params = {};

		if (filters) {
			Object.entries(filters).forEach(([key, value]) => {
				if (value != null && value != '') {
					params = { ...params, [key]: value };
				}
			});
		}

		if (sorter && sorter.sort) {
			params = { ...params, ...sorter };
		}

		params = { ...params, limit, offset };

		return axios
			.get(this.path, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
					completedTranscripts: response.data.completedTranscripts,
				};
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	getAllStatusOptions(): any[] {
		return this.status;
	}

	async get(id: number): Promise<any[]> {

		return axios
			.get(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				if (response.data) {
					response.data.paragraphs.forEach((paragraph: any) => {
						paragraph.excerpts = paragraph.excerpts.map((excerpt: ApiExcerpt): Excerpt => {
							const type = ConstructsService.getConstructTypeById(excerpt.construct.type);

							return ({
								...excerpt,
								construct: {
									...excerpt.construct,
									type,
								},
							});
						});
					});
				}

				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	parseDataTable(results: any[]): Transcript[] {
		return results.map((object) => {
			return {
				key: object.id,
				name: object.name,
				segment: object.segment,
				lastEdit: object['updatedAt'],
				status: object.completed ? 'complete' : 'active',
			};
		});
	}

	async create(
		file: File,
		segment: Segment,
		types: {
			id: number;
			option: {
				id: number;
			};
		}[]
	) {
		const formData = new FormData();

		formData.set(
			'segment',
			JSON.stringify({
				id: segment.key,
			})
		);
		formData.set('transcript', file);
		formData.set('types', JSON.stringify(types));

		return axios
			.post(this.path, formData, {
				headers: {
					Authorization: `Bearer ${this.token}`,
					'Content-type': 'multipart/form-data',
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async update(
		id: number,
		name: string,
		segment: Segment,
		types: {
			id: number;
			option: {
				id: number;
			};
		}[],
		completed:number
	) {
		return axios
			.patch(`${this.path}${id}`, {
				id,
				name,
				segment,
				types,
				completed,
			}, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async delete(id: number) {
		return axios
			.delete(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default ProjectTranscriptsService;
