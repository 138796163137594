import './Construct.less';
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Table,
	TablePaginationConfig,
	Tag,
	Tooltip,
	Typography
} from 'antd';
import { ColumnType, SorterResult } from 'antd/lib/table/interface';
import { Construct, ConstructWithLanguage } from '../../interfaces/Construct';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';

import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';

import setTagColor from '../../utils/setTagColor';
import { Sorter } from '../../interfaces/Api';
import useConstructController from './useConstructController';

const Constructs: React.FC = () => {
	const { constructs, constructsType, languages, totalCount, loading, getConstructs, editConstruct, deleteConstruct, goToAddNewConstruct, reload } =
		useConstructController();

	const [filters, setFilters] = useState({ languageCode: [] as string[], name: '', type: [] as string[], description: '' });
	const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION);
	const [sorter, setSorter] = useState<Sorter | null>(null);

	const [selected, setSelected] = useState<ConstructWithLanguage | null>(null);
	const { Text } = Typography;
	const { Option } = Select;

	const [showModal, setShowModal] = useState(false);

	const routes = [
		{
			path: '',
			breadcrumbName: 'Constructs',
		},
	];

	const toggleShowModal = (item: ConstructWithLanguage | null) => {
		setShowModal(!showModal);

		if (item && item.id) {
			setSelected(item);
		}
	};

	const actions = (item: ConstructWithLanguage) => {
		return (
			<div>
				<Tooltip placement="top" title="Edit construct">
					<EditOutlined style={{ color: '#1991EB' }} onClick={() => editConstruct(item.id)} />
				</Tooltip>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				<Tooltip placement="top" title="Delete construct">
					<DeleteOutlined style={{ color: '#1991EB' }} onClick={() => toggleShowModal(item)} />
				</Tooltip>
			</div>
		);
	};

	const displayRender = (item: ConstructWithLanguage) => <a onClick={() => editConstruct(item.id)}>{item.name}</a>;

	const displayLanguage = (item: ConstructWithLanguage) => <p>{item.language.name}</p>;

	const displayRenderColor = (item: ConstructWithLanguage) => <Tag color={setTagColor(item.type?.id)}>{item.type?.value}</Tag>;

	const columns: ColumnType<ConstructWithLanguage>[] = [
		{
			title: 'Construct',
			dataIndex: 'name',
			render: (_, item: ConstructWithLanguage) => displayRender(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Construct type',
			dataIndex: 'type',
			render: (_, item: ConstructWithLanguage) => displayRenderColor(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Definition',
			dataIndex: 'description',
			className: 'description-column',
		},
		{
			title: 'Language',
			dataIndex: 'language',
			render: (_, item: ConstructWithLanguage) => displayLanguage(item),
			sorter: true,
		},
		{
			title: 'Actions',
			dataIndex: '',
			key: 'x',
			render: (element: ConstructWithLanguage) => actions(element),
		},
	];

	const resetSelected = () => {
		setSelected(null);
	};

	const filterByName = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setFilters({ ...filters, name: event.currentTarget.value, description: event.currentTarget.value });
		}
	};

	const getSelectedName = () => {
		return selected && selected.name ? `${selected.name}?` : '?';
	};

	const getModalDescription = () => {
		return (
			<>
				<Text key="construct1">Are you sure you want to delete the construct </Text>
				<Text key="construct2" strong>
					{getSelectedName()}
				</Text>
			</>
		);
	};

	const handleTableChange = (tablePagination: TablePaginationConfig, tableSorter: SorterResult<Construct>) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField: { [key: string]: string } = {
				name: 'name',
				type: 'type',
				language: 'languageCode',
			};

			const mapSorterOrder: { [key: string]: string } = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(null);
			} else {
				setSorter({
					sort: `${mapSorterField[tableSorter.field.toString()]}:${mapSorterOrder[tableSorter.order]}`,
				});
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	useEffect(() => {
		getConstructs(filters, sorter, pagination);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination, reload]);

	return (
		<div className="Construct">
			<Row justify="space-between">
				<Col>
					<CustomBreadcrumb routes={routes} />
				</Col>
				<Col>
					<Button type="primary" onClick={goToAddNewConstruct} icon={<PlusOutlined />}>
						Create Construct
					</Button>
				</Col>
			</Row>
			<div className="filters-container">
				<Form layout="vertical">
					<Row gutter={20}>
						<Col lg={5}>
							<Form.Item label="Construct" name="construct">
								<Input
									width="354"
									placeholder="Search"
									onKeyDown={filterByName}
									suffix={<SearchOutlined />}
								/>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Construct Type" name="constructType">
								<Select
									placeholder="All"
									onChange={value => {
										setFilters({ ...filters, type: value as string[] });
									}}
									loading={loading}
									mode="multiple"
								>
									{constructsType.map(construct => (
										<Option value={construct.id} key={construct.id}>
											{construct.value}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Filter by Language" name="constructLanguage">
								<Select
									placeholder="All"
									onChange={value => {
										setFilters({ ...filters, languageCode: value as string[] });
									}}
									loading={loading}
									mode="multiple"
								>
									{languages.map(language => (
										<Option value={language.code} key={language.code}>
											{language.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={constructs}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={(pagination, _, sorter) =>
						handleTableChange(pagination, sorter as SorterResult<Construct>)
					}
					loading={loading}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle="Yes, delete construct"
				handleOk={() => {
					deleteConstruct(selected);
					toggleShowModal(null);
				}}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
			/>
		</div>
	);
};

export default Constructs;
