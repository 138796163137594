import { AnalystRole, AnalystStatus } from '../../interfaces/Analyst';
import { Checkbox, Col, Form, Input, Layout, Row, Select, Space, Switch, Typography } from 'antd';
import React, { useContext, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AnalystsService from '../../services/AnalystsService';
import ClientsService from '../../services/ClientsService';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import PartnersService from '../../services/PartnersService';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';
import { UserOutlined } from '@ant-design/icons';

const userGray = '#BFBFBF';

const AnalystDetails = () => {
	const [clients, setClients] = useState([]);
	const [partners, setPartners] = useState([]);
	const [analystName, setAnalystName] = useState('');
	const [loading, setLoading] = useState(false);
	const [isAdministrator, setIsAdministrator] = useState(false);
	const [showSuspended, setShowSuspended] = useState(false);
	const { user } = React.useContext(UserContext);
	const {Content} = Layout;
	const {Option}= Select;
	const { Text } = Typography;
	const [form] = Form.useForm();
	const { id } = useParams();
	const { setError } = useContext(ErrorContext);
	const navigate = useNavigate();
	const analystsService = new AnalystsService();
	const clientsService = new ClientsService();
	const partnersService = new PartnersService();
	const routes = [
		{path: '/', breadcrumbName: 'Admin' },
		{path: '/analysts', breadcrumbName: 'Analysts'},
		{path: '', breadcrumbName: analystName},
	];

	const getPartners = () => {
		setLoading(true);

		partnersService
			.getAll()
			.then(result => {
				if (result !== undefined) {
					setPartners(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getClientsByPartner = partnerId => {
		setLoading(true);

		clientsService
			.getByPartnerId(partnerId)
			.then(result => {
				if (result !== undefined) {
					setClients(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getAnalyst = id => {
		setLoading(true);

		analystsService
			.get(id)
			.then(result => {
				if (result !== undefined) {
					user?.role === AnalystRole.ADMIN && getPartners();
					setAnalystName(`${result.firstName} ${result.lastName}`);
					setShowSuspended(result.status !== AnalystStatus.INVITED);
					setIsAdministrator(result.role === AnalystRole.GLOBAL_PARTNER_ADMIN);
					form.setFieldsValue({
						name: result.firstName,
						surname: result.lastName,
						email: result.email,
						partner: result.globalPartner?.id,
						clients: result.role === AnalystRole.ANALYST ? result.clients.map(client => client.name) : [],
						isAdministrator: result.role === AnalystRole.GLOBAL_PARTNER_ADMIN,
						suspended: result.status === AnalystStatus.SUSPENDED,
					});

					if (result.globalPartner) {
						getClientsByPartner(result.globalPartner.id);
					}
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const backToAnalysts = () => navigate(ROUTES.ANALYSTS);

	const updateAnalyst = async values => {
		setLoading(true);

		try {
			const analyst = {};

			analyst.firstName = values.name;
			analyst.lastName = values.surname;
			analyst.globalPartner = values.partner && { id: values.partner };
			analyst.role = values.isAdministrator ? AnalystRole.GLOBAL_PARTNER_ADMIN : AnalystRole.ANALYST;
			analyst.email = values.email;
			analyst.status = showSuspended ? (values.suspended ? AnalystStatus.SUSPENDED : AnalystStatus.ACTIVE) : undefined;
			analyst.clients = values.clients && values.clients.map(clientName => ({ id: clients.find(client => client.name === clientName)?.key || -1 }));

			await analystsService.update(id, analyst);
			setLoading(false);
			backToAnalysts();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	useEffect(() => {
		getAnalyst(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{width:'100%'}}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Analyst details"
					primaryButton="Save analyst"
					secondClass="edit-analyst"
					onSubmit={updateAnalyst}
					onCancel={backToAnalysts}
					form={form}
				>
					<Row>
						<Col style={{ width: '280px', marginRight: '20px' }}>
							<Form.Item
								className="new-analyst-data"
								label="Email"
								name='email'
								rules={[{ required: true, message: 'This field is required.'}]}
							>
								<Input prefix={<UserOutlined style={{ color: userGray }} />} />
							</Form.Item>
						</Col>
						<Col style={{ width: '150px', marginRight: '14px' }}>
							<Form.Item
								className="new-analyst-data"
								label="Name"
								name='name'
								rules={[{ required: true, message: 'This field is required.'}]}
							>
								<Input prefix={<UserOutlined style={{ color: userGray }} />} />
							</Form.Item>
						</Col>
						<Col style={{ width: '150px' }}>
							<Form.Item
								className="new-analyst-data"
								label="Surname"
								name='surname'
								rules={[{ required: true, message: 'This field is required.'}]}
							>
								<Input prefix={<UserOutlined style={{ color: userGray }} />} />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						noStyle={true}
						valuePropName="checked"
						name='isAdministrator'
					>
						<Checkbox className="new-analyst confidential-checkbox" onChange={e => setIsAdministrator(e.target.checked)}>
							{' '}
							Make this analyst an administrator{' '}
						</Checkbox>
					</Form.Item>
					{
						user?.role === AnalystRole.ADMIN && (
							<Form.Item
								className="new-analyst-partners"
								label="Partner"
								name='partner'
								rules={[{ required: true, message: 'This field is required.'}]}
							>
								<Select
									style={{ width: 195 }}
									onSelect={partnerId => {
										getClientsByPartner(partnerId);
										form.setFieldsValue({
											...form,
											clients: [],
										});
									}}
								>
									{partners.map((partner, index) => (
										<Option key={index} value={partner.key}>
											{partner.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						)
					}
					{
						!isAdministrator && (
							<Form.Item
								className="new-analyst-clients"
								label="Clients"
								name='clients'
							>
								<Select
									mode="multiple"
									allowClear
									style={{ width: '100%' }}
									placeholder="Select clients"
									disabled={loading}
									loading={loading}
									options={clients.map((client) => ({ label: client.name, value: client.name }))}
								/>
							</Form.Item>
						)
					}
					{
						showSuspended && (
							<Space
								direction="horizontal"
								size={10}
								align="baseline"
								className="edit-analyst-h-space"

							>
								<Text className="edit-analyst-text" strong >Suspend Analyst</Text>
								<Form.Item
									name="suspended"
									valuePropName="checked"
									initialValue={false}
									required
								>
									<Switch size="small" />
								</Form.Item>
							</Space>
						)
					}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default AnalystDetails;
