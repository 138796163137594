import { Form, Input, Layout, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import PartnersService from '../../services/PartnersService';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

const NewPartner = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { TextArea } = Input;
	const { Content } = Layout;
	const { setError } = useContext(ErrorContext);
	const partnersService = new PartnersService();

	const backToPartner = () => navigate(ROUTES.PARTNERS, {replace: true});

	const addPartner = async values => {
		setLoading(true);

		try {
			await partnersService.create(values);
			setLoading(false);
			backToPartner();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const routes = [
		{
			path: '/',
			breadcrumbName: 'Admin',
		},
		{
			path: '/',
			breadcrumbName: 'Partners',
		},
		{
			path: '/new-partner',
			breadcrumbName: 'Add new partner',
		},
	];

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Add a new partner to Metaphoria"
					primaryButton="Add Partner"
					onSubmit={addPartner}
					onCancel={backToPartner}
				>
					<Form.Item
						className="field-name"
						name="name"
						label="Partner Name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea" rows={4} />
					</Form.Item>
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default NewPartner;
