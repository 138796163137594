import { Edge, EdgeConfig, Graph, ICombo, IG6GraphEvent, Item } from '@antv/g6';
import { Form, FormInstance } from 'antd';
import { Map, MapNodePosition } from '../../interfaces/Map';
import React, { useRef, useState } from 'react';
import ConstructsService from './../../services/ConstructsService';
import { ConstructType } from '../../interfaces/Construct';
import useConfigMap from './useConfigMap';

interface UseMapControllerReturn {
	addCombo: () => void
	createCombo: (values: ComboValuesInterface) => void
	configMapActions: (graph: Graph) => void
	containerHtmlId: string,
	constructsId?: {
		constructLeftId: string;
		constructRightId: string;
	}
	constructsType: ConstructType[]
	editorContainerRef: React.RefObject<HTMLDivElement>
	form: FormInstance,
	handleClose: () => void
	isMergeButtonDisabled: boolean
	isModalOpen: boolean,
	newConstructType: number,
	setNewConstructType: React.Dispatch<React.SetStateAction<number>>
}

interface ComboValuesInterface {
	name: string,
	description: string
}

let mergesId = -1;

const containerHtmlId = 'container';

const UseMapController = (
	allowLinkCreation: boolean,
	setExternalGraph: React.Dispatch<React.SetStateAction<Graph | null>>,
	useForceLayout: boolean,
	addNodePosition?: (position: MapNodePosition | MapNodePosition[]) => void,
	addNewLink?: (sourceId: string, targetId: string) => void,
	addMerge?: (construct1Id: number, construct2Id: number, id: number, name: string, description: string, type: number) => void,
	mapInfo?: Map,
	onLoadMap?: (evt: IG6GraphEvent, graph: Graph) => void
): UseMapControllerReturn => {
	const [form] = Form.useForm();
	const [selectedNodes, setSelectedNodes] = useState<Item[]>([]);
	const [constructsId, setConstructsId] = useState<{
		constructLeftId: string;
		constructRightId: string;
	} | undefined>(undefined);
	const [isMergeButtonDisabled, setIsMergeButtonDisabled] = useState(true);
	const [newConstructType, setNewConstructType] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const constructsService = new ConstructsService();
	const constructsType = constructsService.getAllConstructsType();
	const editorContainerRef = useRef<HTMLDivElement>(null);

	const onClickBehavior = (evt: IG6GraphEvent, graph: Graph) => {
		const { item } = evt;

		if (item) {
			const states = item.getStates();
			const id = item.getID();

			let nodes = selectedNodes;

			if (states.includes('selected')) {
				graph.setItemState(item, 'selected', true);
				graph.setItemState(item, 'unselected', false);

				if (!nodes.find(node => node?._cfg?.id === id)) {
					nodes.push(item);
				}

				if (nodes.length > 2) {
					nodes.shift();
				}

				if (nodes.length === 2) {
					setIsMergeButtonDisabled(false);
				}
			} else {
				graph.setItemState(item, 'selected', false);
				graph.setItemState(item, 'unselected', true);
				nodes = nodes.filter(node => node?._cfg?.id !== id);
			}

			setSelectedNodes(nodes);

			nodes.forEach(selectedNode => {
				if (selectedNode?._cfg?.id) {
					const node = graph.findById(selectedNode?._cfg?.id);

					if (node) {
						graph.setItemState(node, 'selected', true);
					}
				}
			});

		}

	};

	const onClickEdge = (evt: IG6GraphEvent) => {
		const model = evt.item?.getModel() as EdgeConfig;

		if (model && model.source && model.target) {
			setConstructsId({
				constructLeftId: model.source,
				constructRightId: model.target,
			});}
	};

	const onDragEnd = (evt: IG6GraphEvent, graph: Graph) => {
		const selectedNodes = graph.findAllByState('node', 'selected');

		let positions = [{
			id: evt.item?.getID(),
			x: evt.item?.getModel().x,
			y: evt.item?.getModel().y,
		}] as MapNodePosition[];

		if (selectedNodes.length > 0) {
			positions = selectedNodes.map(node => {
				return { id: node.getID(), x: node.getModel().x, y: node.getModel().y };
			}) as MapNodePosition[];
		}

		addNodePosition && addNodePosition(positions);
	};

	const configMapActions = (graph: Graph) => {
		graph.on('afterlayout', (evt) => {
			onLoadMap && onLoadMap(evt, graph);
		});

		graph.on('combo:dragend', () => {
			graph.getCombos().forEach((combo) => {
				graph.setItemState(combo, 'dragenter', false);
			});
		});

		graph.on('combo:dragenter', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'dragenter', true);
			}

		});

		graph.on('combo:dragleave', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'dragenter', false);
			}
		});

		graph.on('combo:mouseenter', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'active', true);
			}
		});

		graph.on('combo:mouseleave', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'active', false);
			}
		});

		graph.on('combo:click', (evt) => {
			if (evt.target.get('name') === 'combo-marker-shape') {
				if (evt.item) {
					graph.collapseExpandCombo(evt.item as ICombo);
				}
			}

			onClickBehavior(evt, graph);
		});

		graph.on('edge:click', (evt) => {
			onClickEdge(evt);
		});

		graph.on('node:click', (evt) => {
			onClickBehavior(evt, graph);
		});

		// TODO: Improve this function to use typescript correctly
		graph.on('node:drag', (evt) => {
			const { item, clientX, clientY } = evt;
			const point = graph.getPointByClient(clientX, clientY);

			if (item) {

				const model = item.getModel();

				item.toFront();
				item.updatePosition(point);

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				let source = item.getNeighbors('source');

				source = source[0];

				if (source) {
					const targetEdges = source.getEdges();
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					let targetEdge = targetEdges.filter(i => {
						if (i.getModel()) {
							const m = i.getModel();

							if (m.target === model.id) {
								return i;
							}
						}
					});

					targetEdge = targetEdge[0];
					const tM = targetEdge.getModel();
					const tEndPoint = tM.endPoint;
					const sNode = graph.findById(tM.source);
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const sLinkPoint = sNode.getLinkPoint(tEndPoint);
					const sAnchorIndex = sLinkPoint.anchorIndex;

					graph.update(targetEdge, {
						sourceAnchor: sAnchorIndex,
					}, true);
				}

				graph.update(item, model);
				graph.paint();
			}

		});

		graph.on('node:dragend', (evt) => {
			graph.getCombos().forEach((combo) => {
				graph.setItemState(combo, 'dragenter', false);
			});
			onDragEnd(evt, graph);
		});

		graph.on('node:mouseenter', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'active', true);
			}

		});

		graph.on('node:mouseleave', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'active', false);
			}
		});

		graph.on('edge:mouseenter', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'active', true);
			}
		});

		graph.on('edge:mouseleave', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'active', false);
			}
		});

		graph.on('aftercreateedge', (evt) => {
			const edge = evt.edge as Edge;

			if (edge) {
				addNewLink && addNewLink(edge.getSource().getID(), edge.getTarget().getID());
			}
		});

		graph.on('edge:click', (evt) => {
			if (evt.item) {
				graph.setItemState(evt.item, 'selected', true);
			}
		});

	};

	const { currentGraph } = useConfigMap(allowLinkCreation, configMapActions, containerHtmlId, setExternalGraph, useForceLayout, editorContainerRef, mapInfo);

	const handleClose = () => {
		setIsModalOpen(false);
		form.resetFields();
		setNewConstructType(1);
		setConstructsId(undefined);
	};

	const createCombo = (values: ComboValuesInterface) => {
		if (values.name && values.description && newConstructType) {
			const newConstructId = mergesId;
			const newConstructName = values.name;
			const newConstructDescription = values.description;

			addMerge && selectedNodes && selectedNodes.length > 0 && addMerge(
				+selectedNodes[0].getID(),
				+selectedNodes[1].getID(),
				newConstructId,
				newConstructName,
				newConstructDescription,
				newConstructType
			);

			const nodeIds = selectedNodes.map(node => node.getID());

			currentGraph.createCombo(
				{
					id: newConstructId.toString(),
					label: newConstructName,
					collapsed: false,
				},
				[...nodeIds]
			);

			mergesId = mergesId - 1;
			setIsMergeButtonDisabled(true);
		}

		handleClose();
	};

	const addCombo = () => {
		if (selectedNodes.length === 2) {
			setIsModalOpen(true);
		}
	};

	return {
		addCombo,
		createCombo,
		configMapActions,
		containerHtmlId,
		constructsId,
		constructsType,
		editorContainerRef,
		form,
		handleClose,
		isMergeButtonDisabled,
		isModalOpen,
		newConstructType,
		setNewConstructType,
	};
};

export default UseMapController;
