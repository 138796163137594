import { Form, Input, Layout, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import ClientsService from '../../services/ClientsService';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import IndustriesService from '../../services/IndustriesService';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

const NewClient = () => {
	const [industries, setIndustries] = useState([]);
	const [loading, setLoading] = useState(false);
	const { setError } = useContext(ErrorContext);
	const navigate = useNavigate();
	const industriesService = new IndustriesService();
	const service = new ClientsService();
	const { Content } = Layout;
	const { Option } = Select;
	const { TextArea } = Input;
	const routes = [
		{ path: '/', breadcrumbName: 'Admin' },
		{ path: '/clients', breadcrumbName: 'Clients' },
		{ path: '/new-partner', breadcrumbName: 'Add new client' },
	];

	const backToClients = () => navigate(ROUTES.CLIENTS, {replace: true});

	const addClient = async values => {
		setLoading(true);

		try {
			await service.create({
				...values,
				industry: {
					id: values.industry,
				},
			});
			setLoading(false);
			backToClients();
		} catch (err) {
			setLoading(false);
		}
	};

	const getIndustries = () => {
		setLoading(true);

		industriesService
			.getAllWithoutPagination()
			.then(result => {
				if (result !== undefined) {
					setIndustries(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getIndustries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Add a new client to Metaphoria"
					primaryButton="Add Client"
					onCancel={backToClients}
					onSubmit={addClient}
				>
					<Form.Item
						className="field-name"
						name="name"
						label="Client Name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea" rows={4} />
					</Form.Item>
					<Form.Item
						className="edit-client-industries"
						name="industry"
						label="Industry"
						rules={[{ required: true }]}
					>
						<Select
							className="industry-select"
							allowClear
							style={{ width: '100%' }}
							placeholder="Select industry"
						>
							{industries.map((industry, index) => (
								<Option key={index} value={industry.key}>
									{industry.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</CommonForm>
				{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
			</Content>
		</Layout>
	);
};

export default NewClient;
