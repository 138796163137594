import { Auth0ProviderWithNavigate } from '../../providers/Auth0ProviderWithNavigate';
import { ErrorProvider } from '../../providers/ErrorProvider';
import { Outlet } from 'react-router-dom';
import React from 'react';
import UserProvider from '../../providers/UserProvider';

const ProvidersLayout: React.FC = () => {
	return (
		<Auth0ProviderWithNavigate>
			<ErrorProvider>
				<UserProvider>
					<Outlet />
				</UserProvider>
			</ErrorProvider>
		</Auth0ProviderWithNavigate>
	);
};

export default ProvidersLayout;
