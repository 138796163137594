import { Button, Form, Layout, Spin } from 'antd';
import React, { Fragment, useContext, useState } from 'react';

import AnalystForm from '../../components/AnalystForm/AnalystForm';
import { AnalystRole } from '../../interfaces/Analyst';
import AnalystsService from '../../services/AnalystsService';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import { PlusSquareOutlined } from '@ant-design/icons';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

const NewAnalyst = () => {
	const [loading, setLoading] = useState(false);
	const [counter, setCounter] = useState(1);
	const [fields, setFields] = useState([
		{
			name: 0,
			key: 0,
			isListField: 0,
			fieldKey: 0,
		},
	]);
	const navigate = useNavigate();
	const { Content } = Layout;
	const { setError } = useContext(ErrorContext);
	const analystsService = new AnalystsService();
	const routes = [
		{
			path: '/',
			breadcrumbName: 'Admin',
		},
		{
			path: '/analysts',
			breadcrumbName: 'Analysts',
		},
		{
			path: '/',
			breadcrumbName: 'Add new analyst',
		},
	];

	const backToAnalysts = () => {
		navigate(ROUTES.ANALYSTS);
	};

	const addAnalyst = async value => {
		setLoading(true);

		try {
			await Promise.all(value.analysts.map(analyst => {
				const newAnalyst = {};

				newAnalyst.firstName = analyst.name;
				newAnalyst.lastName = analyst.surname;
				newAnalyst.globalPartner = analyst.partner && { id: analyst.partner };
				newAnalyst.role = analyst.administrator ? AnalystRole.GLOBAL_PARTNER_ADMIN : AnalystRole.ANALYST;
				newAnalyst.email = analyst.email;
				newAnalyst.clients = analyst.clients && analyst.clients.map(clientId => ({ id: clientId }));

				return analystsService.createNewAnalyst(newAnalyst);
			}));
			setLoading(false);
			backToAnalysts();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const addField = () => {
		setCounter(counter + 1);
		setFields([
			...fields,
			{
				name: counter,
				key: counter,
				isListField: counter,
				fieldKey: counter,
			},
		]);
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Invite a new analyst to Metaphoria"
					primaryButton="Send invitations"
					secondClass="new-analyst"
					onCancel={backToAnalysts}
					onSubmit={addAnalyst}
				>
					<Form.List name="analysts">
						{() => {
							return (
								<Fragment>
									{fields.map(field => (
										<AnalystForm key={field} field={field} addField={addField} />
									))}
								</Fragment>
							);
						}}
					</Form.List>
					<Button
						onClick={() => addField()}
						type="link"
						icon={<PlusSquareOutlined />}
						className="another-button"
					>
						Add another analyst
					</Button>
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default NewAnalyst;
