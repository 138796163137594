import { Filters, Sorter } from '../../interfaces/Api';
import axios from 'axios';
import BaseService from '../BaseService';
import Configuration from '../ServiceConfig';
import { Type } from '../../interfaces/Type';

interface Types {
	data: Type[];
	totalCount: number;
}

class ProjectTypesService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	constructor(projectId: number) {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}projects/${projectId}/types/`;
		this.token = localStorage.getItem('token');
	}

	private parseDataTable(results: any[]): Type[] {
		return results.map(object => {
			return {
				key: object.id,
				name: object.name,
				options: object.options,
			};
		});
	}

	async getAllWithoutPagination(): Promise<Types | void> {
		return axios
			.get(this.path, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getAll(filters: Filters, sorter: Sorter, limit: number = 10, offset: number = 0): Promise<Types | void> {
		let params = {};

		if (filters && filters.name) {
			params = { ...params, ...filters };
		}

		if (sorter && sorter.sort) {
			params = { ...params, ...sorter };
		}

		params = { ...params, limit, offset };

		return axios
			.get(this.path, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async get(id: number) {
		return axios
			.get(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async create(newType: Type) {
		return axios
			.post(this.path, newType, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async delete(id: number) {
		return axios
			.delete(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async update(id: number, item: Type) {
		return axios
			.patch(`${this.path}${id}`, item, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default ProjectTypesService;
