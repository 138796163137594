import './AnalystList.less';
import {
	Alert,
	Button,
	Col,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Space,
	Switch,
	Table,
	Tag,
	Tooltip,
	Typography
} from 'antd';
import { AnalystRole, AnalystStatus } from '../../interfaces/Analyst';
import { EditOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import AnalystsService from '../../services/AnalystsService';
import { BUTTON_TYPES } from '../../constants/button-types';
import ClientsService from '../../services/ClientsService';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { DEFAULT_PAGINATION } from '../../constants/table-constants';
import { ErrorContext } from '../../providers/ErrorProvider';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

const AnalystList = () => {
	const [filters, setFilters] = useState({ name: '' });
	const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
	const [sorter, setSorter] = useState(undefined);
	const [totalCount, setTotalCount] = useState(0);
	const { setError } = useContext(ErrorContext);
	const analystsService = new AnalystsService();
	const clientsService = new ClientsService();
	const [analysts, setAnalysts] = useState([]);
	const [buttonModalType, setButtonModalType] = useState(BUTTON_TYPES.danger);
	const [clients, setClients] = useState([]);
	const [profiles] = useState(analystsService.getAllProfileOptions());
	const [selected, setSelected] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [successResend, setSuccessResend] = useState('');
	const [status] = useState(analystsService.getAllStatusOptions());
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { user } = useContext(UserContext);
	const { Text } = Typography;
	const { Option } = Select;
	const routes = [
		{
			path: '/',
			breadcrumbName: 'Admin',
		},
		{
			path: '/analysts',
			breadcrumbName: 'Analysts',
		},
	];

	const goToAddAnalyst = () => {
		navigate(ROUTES.NEW_ANALYST);
	};

	const onErrorClose = () => {
		setSuccessResend('');
	};

	const editAnalyst = id => navigate(ROUTES.ANALYST_DETAILS(id));

	const resendInvitation = id => {
		setLoading(true);

		analystsService
			.resendInvitation(id)
			.then(() => {
				setSuccessResend('Invitation re-sent successfully');
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const toggleShowModal = (active, item) => {
		setShowModal(!showModal);
		setButtonModalType(active ? BUTTON_TYPES.danger : BUTTON_TYPES.primary);

		if (item && item.key) {
			setSelected(item);
		}
	};

	const actions = item => {
		return (
			<div className="flex">
				<Tooltip placement="top" title="Edit analyst">
					<EditOutlined style={{ color: '#1991EB' }} onClick={() => editAnalyst(item.key)} />
				</Tooltip>
				<Divider type="vertical" style={{ margin: '0 10px' }} />
				{item.status !== AnalystStatus.INVITED ? (
					<Tooltip placement="top" title="Suspend analyst">
						<Switch
							size="small"
							checked={item.status === AnalystStatus.SUSPENDED}
							onChange={checked => toggleShowModal(checked, item)}
						/>
					</Tooltip>
				) : (
					<Tooltip placement="top" title="Resend invitation">
						<ReloadOutlined style={{ color: '#1991EB' }} onClick={() => resendInvitation(item.key)} />
					</Tooltip>
				)}
			</div>
		);
	};

	const renderNameAndEmail = item => (
		<Space direction="horizontal" size={8}>
			<a onClick={() => editAnalyst(item.key)}>{`${item.analyst.name} ${item.analyst.surname}`}</a> <Text>{item.analyst.email}</Text>
		</Space>
	);

	const renderProfile = profileId => (
		<Space direction="horizontal" size={8}>
			<Text>{profiles.find(profile => profile.value == profileId)?.label}</Text>
		</Space>
	);

	const renderTag = tags => (
		<span>
			{tags && tags.length ? (
				tags.map((tag, index) => {
					return <Tag key={index} style={{marginBottom: 6}}>{tag.name.toUpperCase()}</Tag>;
				})
			) : (
				<Text>No clients associated</Text>
			)}
		</span>
	);

	const renderText = (text) => (
		<span>
			{text ? text : 'Unknown partner'}
		</span>
	);

	const columns = [
		{
			title: 'Analysts',
			dataIndex: 'analyst',
			fixed: 'left',
			width: 300,
			render: (_, item) => renderNameAndEmail(item),
			sorter: true,
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'Clients',
			dataIndex: 'clients',
			fixed: 'left',
			key: 'clients',
			render: tags => renderTag(tags),
		},
		{
			title: 'Profile',
			dataIndex: 'profile',
			fixed: 'right',
			width: 120,
			sorter: true,
			sortDirections: ['descend', 'ascend'],
			render: profileId => renderProfile(profileId),
		},
		{
			title: 'Actions',
			dataIndex: '',
			fixed: 'right',
			width: 120,
			key: 'x',
			render: element => actions(element),
		},
	];

	if (user?.role === AnalystRole.ADMIN) {
		columns.splice(1, 0, {
			title: 'Partners',
			dataIndex: 'globalPartner',
			fixed: 'left',
			key: 'globalPartner',
			render: text => renderText(text),
		});
	}

	const resetSelected = () => {
		setSelected(null);
	};

	const toggleActiveUser = () => {
		setLoading(true);

		analystsService
			.update(selected.key, { status: selected.status === AnalystStatus.ACTIVE ? AnalystStatus.SUSPENDED : AnalystStatus.ACTIVE })
			.then(() => {
				getAnalysts();
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
				toggleShowModal();
			});
	};

	const getSelectedName = () => {
		return selected && selected.analyst ? `${selected.analyst.name}?` : '?';
	};

	const getModalDescription = () => {
		const text =
			selected && selected.status === AnalystStatus.ACTIVE
				? 'Are you sure you want to suspend the analyst '
				: 'Are you sure you want to reactivate the analyst ';

		return [
			<Text key={1}> {text} </Text>,
			<Text strong key={2}>
				{getSelectedName()}
			</Text>,
		];
	};

	const getOkTitle = () => {
		return selected && selected.status === AnalystStatus.ACTIVE ? 'Yes, suspend' : 'Yes, reactivate';
	};

	const getClients = () => {
		setLoading(true);

		clientsService
			.getAllWithoutPagination()
			.then(result => {
				if (result !== undefined) {
					setClients(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getAnalysts = async () => {
		setLoading(true);

		analystsService
			.getAll(filters, sorter, pagination.pageSize, (pagination.current - 1) * pagination.pageSize)
			.then(result => {
				if (result !== undefined) {
					setAnalysts(result.data);
					setTotalCount(result.totalCount);
				}
			}).catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const filterByName = event => {
		if (event.keyCode === 13) {
			setFilters({ ...filters, name: event.target.value });
			setPagination(DEFAULT_PAGINATION);
		}
	};

	const filterByProfile = profileId => {
		setFilters({ ...filters, role: profileId });
		setPagination(DEFAULT_PAGINATION);
	};

	const filterByClient = clientId => {
		setFilters({ ...filters, clientId: clientId });
		setPagination(DEFAULT_PAGINATION);
	};

	const filterByStatus = statusId => {
		setFilters({ ...filters, status: statusId });
		setPagination(DEFAULT_PAGINATION);
	};

	const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
		if (tableSorter && tableSorter.field) {
			const mapSorterField = {
				analyst: 'name',
				profile: 'role',
			};
			const mapSorterOrder = {
				ascend: 'asc',
				descend: 'desc',
			};

			if (!tableSorter.order) {
				setSorter(undefined);
			} else {
				setSorter({ sort: `${mapSorterField[tableSorter.field]}:${mapSorterOrder[tableSorter.order]}` });
			}
		}

		if (tablePagination) {
			setPagination(tablePagination);
		}
	};

	useEffect(() => {
		getClients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getAnalysts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sorter, pagination]);

	return (
		<div className="AnalystList">
			{successResend && <div style={{ marginBottom: 20 }}><Alert message={successResend} type="success" showIcon closable afterClose={onErrorClose} /></div>}
			<Row justify="space-between">
				<Col>
					<CustomBreadcrumb routes={routes} />
				</Col>
				<Col>
					<Button type="primary" onClick={goToAddAnalyst} icon={<PlusOutlined />}>
						Add new analyst
					</Button>
				</Col>
			</Row>
			<div className="filters-container">
				<Form layout="vertical">
					<Row gutter={20}>
						<Col lg={5}>
							<Form.Item label="Analyst" name="analyst">
								<Input placeholder="All" suffix={<SearchOutlined />} onKeyDown={filterByName} />
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Client" name="client">
								<Select
									className="client-select"
									allowClear
									style={{ width: '100%' }}
									placeholder="All"
									onSelect={value => filterByClient(value)}
									onClear={() => filterByClient(undefined)}
								>
									{clients.map((client, index) => (
										<Option key={index} value={client.key}>
											{client.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Status" name="status">
								<Select
									className="status-select"
									allowClear
									style={{ width: '100%' }}
									placeholder="All"
									onSelect={value => filterByStatus(value)}
									onClear={() => filterByStatus(undefined)}
								>
									{status.map((status, index) => (
										<Option key={index} value={status.value}>
											{status.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col lg={5}>
							<Form.Item label="Profile" name="profile">
								<Select
									className="profile-select"
									allowClear
									style={{ width: '100%' }}
									placeholder="All"
									onSelect={value => filterByProfile(value)}
									onClear={() => filterByProfile(undefined)}
								>
									{profiles.map((profile, index) => (
										<Option key={index} value={profile.value}>
											{profile.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="table-container">
				<Table
					columns={columns}
					dataSource={analysts}
					loading={loading}
					pagination={{
						total: totalCount,
						showQuickJumper: true,
						showSizeChanger: true,
						...pagination,
					}}
					onChange={handleTableChange}
				/>
			</div>
			<ConfirmModal
				afterClose={resetSelected}
				visible={showModal}
				cancelTitle="No, cancel"
				okTitle={getOkTitle()}
				handleOk={toggleActiveUser}
				handleCancel={toggleShowModal}
				description={getModalDescription()}
				okType={buttonModalType}
			/>
		</div>
	);
};

export default AnalystList;
