import { Button, Divider, Form, Input, Layout, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalystRole } from '../../interfaces/Analyst';
import CommonForm from '../../components/CommonForm/CommonForm';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { DeleteOutlined } from '@ant-design/icons';
import { ErrorContext } from '../../providers/ErrorProvider';
import ProjectSegmentsService from '../../services/Project/ProjectSegmentsService';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';

const SegmentDetails = () => {
	const [loading, setLoading] = React.useState(false);
	const [showModal, setShowModal] = useState(false);
	const [selected, setSelected] = useState(null);
	const [form] = Form.useForm();
	const { Content } = Layout;
	const { setError } = React.useContext(ErrorContext);
	const { id, projectId } = useParams();
	const { TextArea } = Input;
	const { Text } = Typography;
	const navigate = useNavigate();
	const segmentsService = new ProjectSegmentsService(projectId);
	const { user } = React.useContext(UserContext);

	useEffect(() => {
		setLoading(true);

		segmentsService
			.get(id)
			.then(data => {
				setSelected(data);
				form.setFieldsValue({
					name: data.name,
					description: data.description,
				});
			})
			.catch(err => setError(err.toString()))
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const backToSegments = () => navigate(ROUTES.PROJECT_SEGMENTS(projectId), {replace: true});

	const updateSegment = async values => {
		setLoading(true);

		try {
			await segmentsService.update(id, values);
			setLoading(false);
			backToSegments();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const getModalDescription = () => [
		<Text key={1}>Are you sure you want to delete the </Text>,
		<Text strong key={2}>
			{selected ? selected.name : ''}
		</Text>,
	];

	const toggleShowModal = () => setShowModal(!showModal);

	const deleteSegment = async () => {
		setLoading(true);
		toggleShowModal();

		try {
			await segmentsService.delete(id);
			setLoading(false);
			backToSegments();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CommonForm
					title="Segment details"
					primaryButton="Save segment"
					onSubmit={updateSegment}
					onCancel={backToSegments}
					secondClass="edit-segment"
					titleClass="new-segment"
					form={form}
					disabled={loading || !(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST)}
				>
					<Form.Item
						className="field-name"
						name="name"
						label="Segment name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input disabled={loading} />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Description"
						rules={[{ required: true }, { min: 2 }, { max: 100 }]}
					>
						<TextArea className="common-textarea" rows={4} disabled={loading} />
					</Form.Item>
					<Divider className="divider" />
					{
						(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
							<Button
								className="delete-button"
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => toggleShowModal()}
								disabled={loading}
							>
								Delete segment
							</Button>
						)
					}
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
					<ConfirmModal
						afterClose={() => setSelected(null)}
						visible={showModal}
						cancelTitle="No, cancel"
						okTitle="Yes, delete segment"
						handleOk={deleteSegment}
						handleCancel={toggleShowModal}
						description={getModalDescription()}
					/>
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default SegmentDetails;
