import './ConfirmModal.less';
import { Button, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

const ConfirmModal = ({ title = '', visible = false, handleOk, handleCancel, okTitle = 'Ok', cancelTitle = 'Cancel', description = <p>Are you sure?</p>, okType='danger', showCancelButton = true, afterClose, sideSeparation = '60px' }) => {
	const { Title } = Typography;

	return (
		<Modal
			className="ConfirmModal"
			title={title}
			onCancel={handleCancel}
			width="395px"
			afterClose={afterClose}
			open={visible}
			footer={[
				showCancelButton ? <Button key="back" type="link" onClick={handleCancel}>
					{cancelTitle}
				</Button> : null,
				<Button key="submit" type={okType} onClick={handleOk}>
					{okTitle}
				</Button>,
			]}
		>
			<div className="body" style={{ paddingLeft: sideSeparation, paddingRight: sideSeparation }}>
				<Title level={5} style={{ fontWeight: 'normal' }}>{description}</Title>
			</div>
		</Modal>
	);
};

export default ConfirmModal;
