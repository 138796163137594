import '../NewTypes/NewTypes.less';
import { Button, Divider, Form, Input, Layout, Spin, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalystRole } from '../../interfaces/Analyst';
import CommonForm from '../../components/CommonForm/CommonForm';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { ErrorContext } from '../../providers/ErrorProvider';
import ProjectTypesService from '../../services/Project/ProjectTypesService';
import { ROUTES } from '../../constants/routes-path';
import { UserContext } from '../../providers/UserProvider';

const TypeDetails = () => {
	const [fields, setFields] = useState([]);
	const [loading, setLoading] = React.useState(false);
	const [selected, setSelected] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [typeOptions, setTypeOptions] = useState([]);
	const [form] = Form.useForm();
	const { setError } = React.useContext(ErrorContext);
	const { id, projectId } = useParams();
	const navigate = useNavigate();
	const { Content } = Layout;
	const { Text } = Typography;
	const typesService = new ProjectTypesService(projectId);
	const { user } = React.useContext(UserContext);

	const addField = () => {
		const tempInfo = typeOptions;

		tempInfo.push('');
		setTypeOptions([...tempInfo]);
	};

	const backToTypes = () => navigate(ROUTES.PROJECT_TYPES(projectId), {replace: true});

	const updateType = async values => {
		setLoading(true);

		const inputs = Object.values(values);
		let name = '';

		inputs.forEach(input => {
			if (typeof input !== 'object') {
				name = input;
			}
		});

		const type = {
			name,
			options: typeOptions,
			project: projectId,
		};

		try {
			await typesService.update(id, type);
			setLoading(false);
			backToTypes();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const getSelectedName = () => {
		return selected && selected.name ? `type ${selected.name}?` : 'type?';
	};

	const getModalDescription = () => [
		<Text key={1}>Are you sure you want to delete the </Text>,
		<Text key={2} strong>
			{getSelectedName()}
		</Text>,
	];

	const toggleShowModal = () => setShowModal(!showModal);

	const deleteType = async () => {
		setLoading(true);
		toggleShowModal();

		try {
			await typesService.delete(id);
			setLoading(false);
			backToTypes();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const removeField = index => {
		const toBeRemoved = fields;
		const remainingFields = toBeRemoved.filter(field => field.id !== index);

		setFields(remainingFields);
	};

	const handleTypeOptions = (event, index) => {
		const typeOptionEdited = typeOptions;

		typeOptionEdited[index] = event.target.value;
		setTypeOptions(typeOptionEdited);
	};

	useEffect(() => {
		setLoading(true);
		typesService
			.get(id)
			.then(data => {
				setSelected(data);
				form.setFieldsValue({
					type: data.name,
					typeOption: data.options,
				});
				setTypeOptions(data.options);
			})
			.catch(err => setError(err))
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CommonForm
					title="Type details"
					primaryButton="Update type"
					secondClass="project-types"
					titleClass="types"
					onCancel={backToTypes}
					onSubmit={updateType}
					form={form}
					disabled={loading || !(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST)}
				>
					<Form.Item className="edit-type-name" name="type" label="Type name" rules={[{ required: true, message: 'Name is required' }, { min: 2 }, { max: 50 }]}>
						<Input />
					</Form.Item>
					{typeOptions.length !== 0 ? <Text className="type-options">Type options</Text> : null}
					{typeOptions.map((typeOption, index) => (
						<div className="option-input" key={`typeOption${index}`}>
							<Form.Item
								className="type-option-item"
								name={[index, 'typeOption']}
								rules={[
									{ required: true, message: 'Option is required' },
									{ min: 2 },
									{ max: 50 },
								]}
							>
								<Input
									defaultValue={typeOption.name}
									placeholder="option"
									onChange={value => handleTypeOptions(value, index)}
								/>
							</Form.Item>
							{
								(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
									<DeleteOutlined
										style={{ color: '#1991EB' }}
										onClick={() => {
											removeField(index);
										}}
									/>
								)
							}
						</div>
					))}
					{
						(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
							<Button
								onClick={() => addField()}
								type="link"
								icon={<PlusOutlined />}
								className="another-button edit-types"
							>
								{typeOptions.length !== 0 ? (
									<span>Add another option</span>
								) : (
									<span>Add a type option</span>
								)}
							</Button>
						)
					}
					<Divider className="divider" />
					{
						(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
							<Button
								className="delete-button edit-type"
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => toggleShowModal()}
							>
								Delete type
							</Button>
						)
					}
					{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
					<ConfirmModal
						afterClose={() => setSelected(null)}
						visible={showModal}
						cancelTitle="No, cancel"
						okTitle="Yes, delete type"
						handleOk={deleteType}
						handleCancel={toggleShowModal}
						description={getModalDescription()}
					/>
				</CommonForm>
			</Content>
		</Layout>
	);
};

export default TypeDetails;
