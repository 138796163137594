import { Alert } from 'antd';
import { Excerpt } from '../../interfaces/Excerpt';
import MapColors from '../../utils/MapColorsForAnnotator';
import React from 'react';
import { TextAnnotator } from 'react-text-annotate';
import useAnnotator from './useAnnotator';

interface AnnotatorProps {
	setAnnotatorCallback: (annotator: { call: () => void }) => void;
	text: string;
	excerpts: Excerpt[];
	annotateSelected: Excerpt | null;
	setAnnotateSelected: (annotate: Excerpt | null) => void;
	isAnnotateRemoved: boolean;
	setIsAnnotateRemoved: (flag: boolean) => void;
	setParagraphSelected: (paragraphId: number | null) => void;
	paragraphId: null | number;
}

const Annotator: React.FC<AnnotatorProps> = ({
	setAnnotatorCallback,
	text,
	excerpts,
	annotateSelected,
	setAnnotateSelected,
	isAnnotateRemoved,
	setIsAnnotateRemoved,
	setParagraphSelected,
	paragraphId,
}) => {
	const [, setReRender] = React.useState(false); // Used to force re-render after adding construct or removing annotate

	const reRenderComponent = () => {
		setReRender((prevState) => !prevState);
	};

	const { onErrorClose, values, overlapError, updateValues } = useAnnotator({
		excerpts,
		reRenderComponent,
		isAnnotateRemoved,
		annotateSelected,
		setAnnotateSelected,
		setParagraphSelected,
		setIsAnnotateRemoved,
		text,
		paragraphId,
		setAnnotatorCallback,
	});

	return (
		<div>
			<TextAnnotator
				style={{
					lineHeight: 1.5,
				}}
				content={text}
				value={values.map((value) => ({
					start: value.start,
					end: value.end,
					tag: value.construct?.type?.value?.toUpperCase(),
					color: MapColors.get(value.construct?.type?.color),
					text: value.text,
				}))}
				onChange={(value: unknown) => updateValues(value as Excerpt[])}
				getSpan={(span) => ({ ...span })}
			/>
			{overlapError && (
				<Alert
					message={
						'You cannot overlap excerpts. Remove the previous excerpt before selecting part of it.'
					}
					type="error"
					showIcon
					closable
					afterClose={onErrorClose}
				/>
			)}
		</div>
	);
};

export default Annotator;
