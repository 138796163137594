import { ApiProjectWithClientId, Project } from '../interfaces/Project';
import { Construct, ConstructType, ConstructTypeColor } from '../interfaces/Construct';
import { Filters, Sorter } from '../interfaces/Api';
import axios from 'axios';
import BaseService from './BaseService';
import Configuration from './ServiceConfig';
import ConstructsService from './ConstructsService';

interface Response {
	data: Project[];
	totalCount: number;
}

class ProjectsService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	constructor() {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}${'projects/'}`;
		this.token = localStorage.getItem('token');
	}

	private parseToApiProjectWithClientId(project: Project): ApiProjectWithClientId{
		return {
			id: project.key,
			name: project.name,
			description: project.description,
			updatedAt: project.updatedAt,
			status: project.status,
			clientId: project.client.key,
		};
	}

	private parseDataTable(results: any[]): Project[] {
		return results.map(object => {
			return {
				key: object.id,
				name: object.name,
				description: object.description,
				client: {
					key: object.client.id,
					name: object.client.name,
					industry: object.client.industry,
				},
				completedTranscriptsCount: object.completedTranscriptsCount,
				transcriptsCount: object.transcriptsCount,
				updatedAt: new Date(object.updatedAt),
				status: object.status,
			};
		});
	}

	async getAll(filters: Filters, sorter: Sorter, limit: number = 10, offset: number = 0): Promise<Response | void> {
		let params = {};

		if (filters) {
			Object.entries(filters).forEach(([key, value]) => {
				if (value) {
					params = { ...params, [key]: value };
				}
			});
		}

		if (sorter && sorter.sort) {
			params = { ...params, ...sorter };
		}

		params = { ...params, limit, offset };

		return axios
			.get(this.path, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async get(id: number) {
		return axios
			.get(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getLinks(): Promise<Construct[] | void> {
		return axios
			.get(`${this.path}links`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getMostUsedConstructs(id: number): Promise<Construct[]> {
		try {
			const mostUsedConstructs = await axios
				.get(`${this.path}${id}/most-used-constructs`, {
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
				});

			return mostUsedConstructs.data.map((construct: any) => {
				return {
					...construct,
					type: ConstructsService.getConstructTypeById(construct.type),
				};
			});
		} catch (err: any) {
			throw this.getErrorMessage(err);
		}
	}

	async create(item: Project) {
		return axios
			.post(this.path, item, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async delete(id: number) {
		return axios
			.delete(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async duplicateProject(projectId:number, item: Project, includeObjects: string[] ) {
		return axios
			.post(`${this.path}${projectId}/duplicate`, {...this.parseToApiProjectWithClientId(item), includeObjects}, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async update(id: number, project: Project) {
		return axios
			.patch(`${this.path}${id}`, project, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getConstructTypeColors(id: number): Promise<ConstructTypeColor[]> {
		return axios
			.get(`${this.path}${id}/construct-type-colors`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data.map((constructTypeColor: any) => {
					return {
						...constructTypeColor,
						color: '#' + constructTypeColor.color,
					};
				});
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async changeConstructTypeColors(id: number, constructTypes: ConstructType[]) {
		return axios
			.put(`${this.path}${id}/construct-type-colors`, constructTypes.map(constructType => ({
				color: constructType.color.replace('#', ''),
				type: constructType.id,
			})), {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default ProjectsService;
