import { Form, FormInstance } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Client } from '../../interfaces/Client';
import ClientsService from '../../services/ClientsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import { Project } from '../../interfaces/Project';
import ProjectsService from '../../services/ProjectsService';
import { ROUTES } from '../../constants/routes-path';
import { useNavigate } from 'react-router-dom';

interface ProjectDuplicateControllerReturn {
	projectName: string
	form: FormInstance
	loading: boolean
	clients: Client[]
	duplicateProject: () => void
	isOpenModal: boolean
	openModal: () => void
	closeModal: () => void
	getProjectContent: () => Project
	getObjectsToDuplicate: () => string[]
}

const useProjectDuplicateController = (projectId?: string): ProjectDuplicateControllerReturn => {
	const projectsService = new ProjectsService();
	const clientsService = new ClientsService();
	const { setError } = useContext(ErrorContext);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [clients, setClients] = useState<Client[]>([]);
	const [projectName, setProjectName] = useState('');
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const openModal = () => {
		setIsOpenModal(true);
	};

	const closeModal = () => {
		setIsOpenModal(false);
	};

	const getClients = () => {
		setLoading(true);

		clientsService
			.getAllWithoutPagination()
			.then(result => {
				if (result !== undefined) {
					setClients(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getProjectContent = (): Project => {
		return {
			...form.getFieldsValue(),
			client: clients.find((element) => element.key === form.getFieldValue('client')),
		};
	};

	const getObjectsToDuplicate = (): string[] => {
		return form.getFieldValue('objects');
	};

	const duplicateProject = async () => {
		if (projectId) {
			try {
				const id = await projectsService.duplicateProject(+projectId, getProjectContent(), getObjectsToDuplicate() );

				navigate(ROUTES.PROJECT_DETAILS(id));
			} catch (err) {
				setError(String(err));
				closeModal();
			}
		}
	};

	useEffect(() => {

		if (projectId) {
			setLoading(true);
			projectsService.get(+projectId).then(data => {
				setProjectName(data.name);

				form.setFieldsValue({
					client: data.client.id,
					description: data.description,
					name: data.name,
					objects: ['segments'],
				});
			}).finally(()=> setLoading(false));
		}

		getClients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		projectName,
		form,
		loading,
		clients,
		duplicateProject,
		isOpenModal,
		openModal,
		closeModal,
		getProjectContent,
		getObjectsToDuplicate,
	};
};

export default useProjectDuplicateController;
