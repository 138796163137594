export enum PartnerStatus {
	DISABLED = 0,
	ENABLED = 1,
}

export interface Partner {
	key: number;
	name: string;
	description?: string;
	status?: PartnerStatus;
}
