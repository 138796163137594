import { Form, Input, Layout, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClientsService from '../../services/ClientsService';
import { ClientStatus } from '../../interfaces/Client';
import CommonForm from '../../components/CommonForm/CommonForm';
import CustomBreadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ErrorContext } from '../../providers/ErrorProvider';
import ProjectsService from '../../services/ProjectsService';
import { ProjectStatus } from '../../interfaces/Project';
import { ROUTES } from '../../constants/routes-path';

const ProjectDetails = () => {
	const [clients, setClients] = useState([]);
	const [projectName, setProjectName] = useState('');
	const [projectStatus, setProjectStatus] = useState(ProjectStatus.DISABLED);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { setError } = useContext(ErrorContext);
	const clientsService = new ClientsService();
	const projectsService = new ProjectsService();
	const [form] = Form.useForm();
	const { projectId } = useParams();
	const { TextArea } = Input;
	const { Content } = Layout;
	const { Option } = Select;

	const routes = [
		{ path: '/projects', breadcrumbName: 'Projects' },
		{ path: '', breadcrumbName: projectName },
	];

	const backToProjects = () => {
		navigate(ROUTES.PROJECTS);
	};

	const updateProject = async values => {
		setLoading(true);

		try {
			await projectsService.update(projectId, {
				name: values.name,
				client: { id: clients.find(client => values.client === client.name)?.key },
				description: values.description,
			});
			setLoading(false);
			backToProjects();
		} catch (err) {
			setError(err.toString());
			setLoading(false);
		}
	};

	const getClients = () => {
		setLoading(true);

		clientsService
			.getAllWithoutPagination()
			.then(result => {
				if (result !== undefined) {
					setClients(result.data);
				}
			})
			.catch(err => {
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		projectsService.get(projectId).then(data => {
			getClients();
			setProjectName(data.name);
			setProjectStatus(data.status);
			form.setFieldsValue({
				name: data.name,
				description: data.description,
				client: data.client?.name,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Content style={{ width: '100%' }}>
				<CustomBreadcrumb routes={routes} />
				<CommonForm
					title="Edit project"
					primaryButton="Update Project"
					onSubmit={updateProject}
					onCancel={backToProjects}
					secondClass="update-project"
					form={form}
					disabled={loading || projectStatus === ProjectStatus.DISABLED}
				>
					<Form.Item className="field-name" name="name" label="Project Name" rules={[{ required: true }, { min: 2 }, { max: 100 }]}>
						<Input />
					</Form.Item>
					<Form.Item className="field-name" name="client" label="Client" rules={[{ required: true }]}>
						<Select
							className="client-select"
							allowClear
							style={{ width: '100%' }}
							placeholder="Select client"
						>
							{clients.map((client, index) => (
								<Option key={index} value={client.name} disabled={client.status === ClientStatus.DISABLED}>
									{client.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item style={{ marginBottom: 0 }} name="description" label="Description" rules={[{ required: true }]}>
						<TextArea className="project common-textarea" rows={3} />
					</Form.Item>
				</CommonForm>
				{loading && <Spin style={{ display: 'flex', justifyContent: 'center' }} />}
			</Content>
		</Layout>
	);
};

export default ProjectDetails;
