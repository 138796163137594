import './ConstructTags.less';
import { COLOR_LIGHT_GREY_HEX, COLOR_WITHE_HEX } from '../../utils/ColorsHex';
import React from 'react';
import { Tooltip } from 'antd';

const ConstructTags = (props) => {
	const { label, active, onClick, color, isDisabled } = props;

	if (label !== undefined) {
		if (label != '') {
			const defaultStyleBackgroundColor = isDisabled
				? COLOR_LIGHT_GREY_HEX
				: COLOR_WITHE_HEX;
			const defautStyle = {
				borderColor: COLOR_WITHE_HEX,
				color: color,
				backgroundColor: defaultStyleBackgroundColor,
				border: `1px solid ${color}`,
			};

			const activeStyle = {
				borderColor: color,
				color: COLOR_WITHE_HEX,
				backgroundColor: color,
			};

			return (
				<div>
					{isDisabled ? (
						<div
							onClick={onClick}
							className={'construct-tags'}
							style={active ? activeStyle : defautStyle}
						>
							<Tooltip title="This construct is part of a merged construct and cannot be used for this project anymore.">
								{label}
							</Tooltip>
						</div>
					) : (
						<div
							onClick={onClick}
							className={'construct-tags'}
							style={active ? activeStyle : defautStyle}
						>
							{label}
						</div>
					)}
				</div>
			);
		}

		return null;
	}
};

export default ConstructTags;
