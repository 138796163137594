import './SidebarConstructs.less';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { ROUTES } from '../../constants/routes-path';
import SidebarOptionsConstructs from '../SidebarOptionsConstructs/SidebarOptionsConstructs';

const SidebarConstructs = ({ constructTypes, constructList, removeSelectedConstruct, onAddSelected }) => {
	const [constructTypesList, setConstructTypesList] = useState(constructTypes);
	const [selectedConstructs, setSelectedConstructs] = useState();
	const navigate = useNavigate();
	const { projectId } = useParams();

	useEffect(() => {
		setConstructTypesList(constructTypes);
		setSelectedConstructs(constructList);
	}, [constructTypes, constructList]);

	return (
		<div className="SidebarConstructs">
			<div className="options">
				{constructTypesList.map((constructType, i) => (
					<SidebarOptionsConstructs key={i} constructType={constructType} constructList={selectedConstructs} removeSelectedConstruct={removeSelectedConstruct}/>
				))}
			</div>
			<div className="add-construct">
				<Button type="link" onClick={() => navigate(ROUTES.NEW_CONSTRUCT)}>+ Create a new construct</Button>
			</div>
			<div className="add-selected-constructs">
				<Button type="link" onClick={() => navigate(ROUTES.PROJECT_ADD_CONSTRUCTS(projectId))}>Cancel</Button>
				<Button type="primary" onClick={onAddSelected}>Save changes</Button>
			</div>
		</div>
	);
};

export default SidebarConstructs;
