import { Excerpt } from '../../interfaces/Excerpt';
import FindSentence from '../../utils/FindSentence';
import React from 'react';

interface UseAnnotatorStore {
	onErrorClose: () => void;
	values: Excerpt[];
	overlapError: boolean;
	updateValues: (newValues: Excerpt[]) => void;
}

interface UseAnnotatorProps {
	excerpts: Excerpt[];
	reRenderComponent: () => void;
	isAnnotateRemoved: boolean;
	annotateSelected: Excerpt | null;
	setAnnotateSelected: (annotate: Excerpt | null) => void;
	setParagraphSelected: (paragraphId: number | null) => void;
	setIsAnnotateRemoved: (flag: boolean) => void;
	text: string;
	paragraphId: null | number;
	setAnnotatorCallback: (annotator: { call: () => void }) => void;
}

const useAnnotator = ({ excerpts, reRenderComponent, isAnnotateRemoved, annotateSelected, setAnnotateSelected, setParagraphSelected, setIsAnnotateRemoved, text, paragraphId, setAnnotatorCallback }: UseAnnotatorProps): UseAnnotatorStore => {
	const [values, setValues] = React.useState(excerpts);
	const [overlapError, setOverlapError] = React.useState(false);

	React.useEffect(() => {
		if (isAnnotateRemoved) {
			const updatedValues = values.filter(
				(annotate) => annotate !== annotateSelected
			);

			if (values.length !== updatedValues.length) {
				setValues(updatedValues);
				setAnnotateSelected(null);
				setParagraphSelected(null);
				setIsAnnotateRemoved(false);
				reRenderComponent();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAnnotateRemoved]);

	const getExcerptWithoutOverlap = (newExcerpt: Excerpt, excerpts: Excerpt[]) => {
		const excerptsWithoutOverlaps = [];
		let isNewExcerptOverlapping = false;

		excerpts.forEach((excerpt) => {
			if (
				(excerpt.start <= newExcerpt.start &&
					excerpt.end - 1 >= newExcerpt.start) ||
				(excerpt.start <= newExcerpt.end - 1 &&
					excerpt.end - 1 >= newExcerpt.end - 1) ||
				(excerpt.start <= newExcerpt.start &&
					excerpt.end - 1 >= newExcerpt.end - 1) ||
				(excerpt.start >= newExcerpt.start &&
					excerpt.end - 1 <= newExcerpt.end - 1)
			) {
				setOverlapError(true);
				isNewExcerptOverlapping = true;
			}

			excerptsWithoutOverlaps.push(excerpt);
		});

		if (!isNewExcerptOverlapping) {
			excerptsWithoutOverlaps.push(newExcerpt);
		}

		return excerptsWithoutOverlaps;
	};

	const clearUnusedExcerpts = (excerpts: Excerpt[]) => {
		const newExcerpts = excerpts.filter((excerpt) => excerpt.construct);

		return newExcerpts;
	};

	const updateValues = (newValues: Excerpt[]) => {
		// Receive array with new value and previous values when non-annotate excerpt
		if (newValues.length >= values.length) {
			const newAnnotateSelected = newValues.find(
				({ text: val1 }) =>
					!values.some(({ text: val2 }) => val2 === val1)
			);

			// Checks for several cases like:
			// 1. newAnnotateSelected = undefined when all values are the same than new values
			// 2. newAnnotateSelected.end is undefined or NaN for some weird cases like double clicking on text or selecting all paragraph
			// 3. newAnnotateSelected.start is undefined or NaN for some weird cases like double clicking on text or selecting all paragraph
			// 4. newAnnotateSelected.start is greater than newAnnotateSelected.end for when the selection goes to a new paragraph that is shorter than the one before
			if (
				newAnnotateSelected &&
				newAnnotateSelected.end !== undefined &&
				!Number.isNaN(newAnnotateSelected.end) &&
				newAnnotateSelected.start !== undefined &&
				!Number.isNaN(newAnnotateSelected.start) &&
				newAnnotateSelected.start < newAnnotateSelected.end
			) {
				const valuesWithoutUnusedExcerpts = clearUnusedExcerpts(values);
				const excerptsToStore = getExcerptWithoutOverlap(
					newAnnotateSelected,
					valuesWithoutUnusedExcerpts
				);

				// If excerptsToStore length is greater than previous valuesWithoutUnusedExcerpts length,
				// it means it was not overlaping, so we can set new values and the new annotate
				if (
					excerptsToStore.length > valuesWithoutUnusedExcerpts.length
				) {
					setValues(excerptsToStore);
					newAnnotateSelected.sentence = FindSentence(
						text,
						newAnnotateSelected.start,
						newAnnotateSelected.end
					);
					setAnnotateSelected(newAnnotateSelected);
					setParagraphSelected(paragraphId);
				}
			}
		} else {
			// Receive empty array when clicking on annotate
			const newAnnotateSelected = values.filter(
				({ text: val1 }) =>
					!newValues.some(({ text: val2 }) => val2 === val1)
			)[0];

			setAnnotateSelected(newAnnotateSelected);
			setParagraphSelected(paragraphId);
		}

		setAnnotatorCallback({ call: reRenderComponent });
	};

	const onErrorClose = () => {
		setOverlapError(false);
	};

	return ({
		onErrorClose,
		values,
		overlapError,
		updateValues,
	});
};

export default useAnnotator;
