
import { Navigate, Outlet } from 'react-router-dom';
import { AnalystRole } from '../../interfaces/Analyst';
import Loading from '../../components/Loading/Loading';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../providers/UserProvider';

interface ProtectLayoutProps {
	permissions: AnalystRole[];
}

const ProtectedLayout: React.FC<ProtectLayoutProps> = ({ permissions }) => {
	const { user } = React.useContext(UserContext);
	const { isLoading } = useAuth0();

	// TODO: Improve the user data fetch so as not to have to put this loader in the protected path
	if (isLoading || !user) {
		return <Loading />;
	}

	return permissions.includes(user?.role) ? <Outlet /> : <Navigate to={'/'} /> ;
};

export default ProtectedLayout;
