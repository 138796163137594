import 'antd/dist/antd.less';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';

WebFont.load({
	google: {
		families: ['Roboto Condensed', 'Source Sans Pro:300,400,600,700', 'sans-serif'],
	},
});

ReactDOM
	.createRoot(document.getElementById('root'))
	.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
