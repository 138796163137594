import { Button, Col, Form, Row, Select } from 'antd';
import { ExportOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import ConstructService from '../../services/ConstructsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import Map from '../../components/Map/Map';
import ProjectMapService from '../../services/Project/ProjectMapService';
import ProjectTranscriptService from '../../services/Project/ProjectTranscriptService';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

const TranscriptMap = ({ transcriptLoading }) => {
	const { setError } = useContext(ErrorContext);
	const constructsService = new ConstructService();
	const [constructsType] = useState(constructsService.getAllConstructsType());
	const [filters, setFilters] = useState({ constructType: [] });
	const [loading, setLoading] = useState(false);
	const [map, setMap] = useState(null);
	const { projectId, id: transcriptId } = useParams();
	const mapService = new ProjectMapService(projectId);
	const projectTranscriptService = new ProjectTranscriptService(+projectId, transcriptId);
	const [graph, setGraph] = useState(null);
	const [createdLinks, setCreatedLinks] = useState([]);
	const { user } = useContext(UserContext);

	const getMap = () => {
		setLoading(true);

		mapService
			.get({ transcriptId, ...filters })
			.then(result => {
				const newNodes = result.nodes.filter(node => result.links.some(link => link.source === node.id || link.target === node.id));

				setMap({ nodes: newNodes, links: result.links });
			})
			.catch(err => {
				setMap(null);
				setError(err.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const saveLinks = async () => {
		try {
			await projectTranscriptService.addLinks(createdLinks);
			setCreatedLinks([]);
		} catch (err) {
			setError(err.toString());
		}
	};

	const addLink = (sourceId, targetId) => {
		setCreatedLinks(prevState => ([ ...prevState, {
			source: { id: +sourceId },
			target: { id: +targetId },
		}]));
	};

	useEffect(() => {
		if (!transcriptLoading) {
			getMap();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, transcriptLoading]);

	return (
		<div className="TranscriptMap" style={{ height: '100%', overflowY: 'auto' }}>
			<div className="filters-container">
				<Form layout="vertical">
					<Row gutter={16} align="bottom">
						<Col span={18}>
							<Form.Item label="Construct Types" name="selectedConstructTypes">
								<Select
									mode="multiple"
									allowClear
									placeholder="All"
									onChange={values => setFilters({ ...filters, constructType: values })}
									loading={loading}
									disabled={loading}
									options={constructsType.map((construct) => ({ label: construct.value, value: construct.id }))}
								/>
							</Form.Item>
						</Col>
						{/* <Col span={6}>
							<Form.Item>
								<Button type="link" block>Map colors</Button>
							</Form.Item>
						</Col> */}
						<Col span={6}>
							<Form.Item>
								<Button type="primary" block onClick={() => { graph && graph.downloadImage(); }}>
									<ExportOutlined /> Export this view
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			{
				(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
					<div className="filters-container" style={{ backgroundColor: 'white' }}>
						<Form layout="vertical">
							<Row gutter={16} align="bottom">
								<Col span={18}>
								</Col>
								<Col span={3}>
									<Form.Item>
										<Button type="link" block onClick={() => { setCreatedLinks([]); getMap(); }} disabled={!createdLinks.length}>
											Cancel
										</Button>
									</Form.Item>
								</Col>
								<Col span={3}>
									<Form.Item>
										<Button type="primary" block onClick={saveLinks} disabled={!createdLinks.length}>
											<SaveOutlined /> Save map
										</Button>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</div>
				)
			}
			{!loading && map && <Map
				map={map}
				setExternalGraph={setGraph}
				allowLinkCreation={(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST)}
				addLink={addLink}
			/>}
		</div>
	);
};

export default TranscriptMap;
