export const ROUTES = {
	DASHBOARD: '/',
	PARTNERS: '/partners',
	NEW_PARTNERS: '/partners/new',
	PARTNER_DETAILS: (id = ':id') => `/partners/${id}`,
	ANALYSTS: '/analysts',
	NEW_ANALYST: '/analysts/new',
	ANALYST_DETAILS: (id = ':id') => `/analysts/${id}`,
	CLIENTS: '/clients',
	NEW_CLIENT: '/clients/new',
	CLIENT_DETAILS: (id = ':id') => `/clients/${id}`,
	NEW_PROJECT: '/projects/new',
	CONSTRUCTS: '/constructs',
	NEW_CONSTRUCT: '/constructs/new',
	CONSTRUCT_DETAILS: (id = ':id') => `/constructs/${id}`,
	INDUSTRIES: '/industries',
	NEW_INDUSTRY: '/industries/new',
	INDUSTRY_DETAILS: (id = ':id') => `/industries/${id}`,
	PROJECTS: '/projects',
	PROJECT_DETAILS: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId' : `/projects/${projectId}`,
	PROJECT_DETAILS_EDIT:(projectId = ':projectId') =>
		projectId === ':projectId/edit' ? ':projectId' : `/projects/${projectId}/edit`,
	PROJECT_TRANSCRIPTS: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/transcripts' : `/projects/${projectId}/transcripts`,
	PROJECT_TRANSCRIPT_SELECTED: (projectId = ':projectId', transcriptId =':id') =>
		projectId === ':projectId' ? ':projectId/transcripts/:id' : `/projects/${projectId}/transcripts/${transcriptId}`,
	PROJECT_TRANSCRIPT_DETAILS_EDIT: (projectId = ':projectId', transcriptId =':id') =>
		projectId === ':projectId' ? ':projectId/transcripts/:id/edit' : `/projects/${projectId}/transcripts/${transcriptId}/edit`,
	PROJECT_CONSTRUCTS: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/constructs' : `/projects/${projectId}/constructs`,
	PROJECT_ADD_CONSTRUCTS: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/add-constructs' : `/projects/${projectId}/add-constructs`,
	PROJECT_LEXICON: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/lexicon' : `/projects/${projectId}/lexicon`,
	PROJECT_SEGMENTS: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/segments' : `/projects/${projectId}/segments`,
	PROJECT_SEGMENTS_NEW_SEGMENTS: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/segments/new-segment' : `/projects/${projectId}/segments/new-segment`,
	PROJECT_SEGMENTS_DETAILS: (projectId = ':projectId', segmentId=':id') =>
		projectId === ':projectId' ? ':projectId/segments/:id' : `/projects/${projectId}/segments/${segmentId}`,
	PROJECT_MAP: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/map' : `/projects/${projectId}/map`,
	PROJECT_TYPES: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/types' : `/projects/${projectId}/types`,
	PROJECT_NEW_TYPE: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/types/new-type' : `/projects/${projectId}/types/new-type`,
	PROJECT_TYPE_DETAILS: (projectId = ':projectId', typeId=':id') =>
		projectId === ':projectId' ? ':projectId/types/:id' : `/projects/${projectId}/types/${typeId}`,
	PROJECT_DUPLICATE: (projectId = ':projectId') =>
		projectId === ':projectId' ? ':projectId/duplicate' : `/projects/${projectId}/duplicate`,
};
